import { cn } from "~/common/shadcn-utils"

export const LogoText = ({ admin }: { admin?: boolean }) => (
  <div>
    <div
      className={cn(
        "text-xl text-gray-333 font-semibold",
        admin && "text-white"
      )}
    >
      Standard Aptitude
    </div>
    <div className={cn("text-lg text-gray-999", admin && "text-white")}>
      Measurement Co
    </div>
  </div>
)
