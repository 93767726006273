import { useState } from "react"
import { useTestFlow } from "./TestFlowProvider"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { ChevronLeft } from "lucide-react"
import { Divider } from "~/ui/Divider"
import testDetailsImage from "~/images/new-test.svg"
import invariant from "tiny-invariant"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { candidateTestsPath } from "~/common/paths"
import { LinkButton, Button } from "~/shadcn/ui/button"
import { Checkbox } from "~/shadcn/ui/checkbox"
import toast from "react-hot-toast"
import { Markdown } from "~/ui/Markdown"
import { TermsOfServiceText } from "~/screens/Landing/TermsOfServiceScreen"

export const TestDisclaimers = () => {
  const { candidateTest, acceptTerms } = useTestFlow()
  invariant(candidateTest, "candidateTest expected")
  const { currentUser } = useCurrentUserMaybe()
  const { testId } = useParams()
  invariant(testId)

  const disclaimerIds = [
    "default-disclaimer-1",
    "default-disclaimer-2",
    ...candidateTest.test.disclaimers.map((d) => d.id),
  ]
  const [disclaimersChecked] = useState(new Set<string>())
  const [allChecked, setAllChecked] = useState(
    disclaimerIds.every((id) => disclaimersChecked.has(id))
  )

  const test = candidateTest.test

  const toggleChecked = (isChecked: boolean | string, disclaimerId: string) => {
    if (isChecked) {
      disclaimersChecked.add(disclaimerId)
    } else {
      disclaimersChecked.delete(disclaimerId)
    }
    setAllChecked(disclaimerIds.every((id) => disclaimersChecked.has(id)))
  }

  const continueLinkClick = async () => {
    if (allChecked) {
      acceptTerms()
    } else {
      toast.error("Please agree to the disclaimers to continue")
    }
  }

  return (
    <div className="container mx-auto pb-20">
      {currentUser && (
        <>
          <div className="py-3 text-gray-999 flex justify-start">
            <Link to={candidateTestsPath.pattern} className="flex items-center">
              <ChevronLeft className="mr-2 mt-0.5" size={16} />
              Tests
            </Link>
          </div>
          <Divider />
        </>
      )}

      <div className="max-w-screen-sm mx-auto flex flex-col mt-16 gap-10">
        <div className="flex items-center">
          <img
            src={testDetailsImage}
            alt="new test"
            className="w-[128px] mr-10"
          />
          <div>
            <div className="text-2xl text-gray-333 font-semibold">
              {test.name}
            </div>
            <div className="">
              {test.organization && (
                <div className="text-sm text-gray-999">
                  {test.organization.name}
                </div>
              )}
            </div>
          </div>
        </div>
        {test.welcomeNote && (
          <div className="flex flex-col gap-2">
            <div className="text-sm text-gray-999">Introduction</div>
            <div className="text-gray-333">
              <Markdown>{test.welcomeNote}</Markdown>
            </div>
          </div>
        )}
        {test.description && (
          <div className="flex flex-col gap-2">
            <div className="text-sm text-gray-999">Description</div>
            <div className="text-gray-333">
              <Markdown>{test.description}</Markdown>
            </div>
          </div>
        )}

        <div className="flex flex-col divide-y divide-gray-E6E6E3 rounded-lg border border-gray-E6E6E3">
          <div className="p-6 bg-gray-F7F7F5 max-h-56 overflow-auto">
            <div className="text-gray-333">
              <TermsOfServiceText />
            </div>
          </div>
          <DisclaimerCheckbox
            disclaimerId="default-disclaimer-1"
            toggleChecked={toggleChecked}
          >
            I have read this legal disclaimer and understand the terms of
            service.
          </DisclaimerCheckbox>
          <DisclaimerCheckbox
            disclaimerId="default-disclaimer-2"
            toggleChecked={toggleChecked}
          >
            I agree to be bound by the terms of service and would like to take
            this test.
          </DisclaimerCheckbox>
          {test.disclaimers.map((disclaimer) => (
            <DisclaimerCheckbox
              key={disclaimer.id}
              disclaimerId={disclaimer.id}
              toggleChecked={toggleChecked}
            >
              {disclaimer.disclaimerText}
            </DisclaimerCheckbox>
          ))}
        </div>

        <div className="flex flex-row gap-2 justify-between">
          <Button
            className="px-20"
            onClick={continueLinkClick}
            disabled={!allChecked}
          >
            Agree & Continue
          </Button>
          <LinkButton to={candidateTestsPath.pattern} variant="ghost">
            Back to Tests
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

const DisclaimerCheckbox = ({
  disclaimerId,
  toggleChecked,
  children,
}: {
  disclaimerId: string
  toggleChecked: (isChecked: boolean | string, disclaimerId: string) => void
  children: React.ReactNode
}) => {
  return (
    <div
      key={disclaimerId}
      className="p-6 bg-white flex flex-row gap-4 justify-start items-start"
    >
      <Checkbox
        id={`ck-${disclaimerId}`}
        onCheckedChange={(isChecked) => toggleChecked(isChecked, disclaimerId)}
      />
      <label htmlFor={`ck-${disclaimerId}`} className="text-gray-333 uppercase">
        {children}
      </label>
    </div>
  )
}
