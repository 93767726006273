import { ReactNode } from "react"
import { cn } from "~/common/shadcn-utils"

export const FormPanel = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => (
  <div
    className={cn(
      "bg-gray-F7F7F5 rounded p-6 border border-gray-E6E6E3 flex flex-col",
      className
    )}
  >
    {children}
  </div>
)
