import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { LinkButton } from "~/shadcn/ui/button"
import { Test_PromotionalTestTableFragment } from "~/__generated__/graphql"
import { Link } from "react-router-dom"
import linkImage from "~/images/icons/link_grey.svg"
import { candidateTestPath } from "~/common/paths"
import { humanizeSecondsToMinutes } from "~/common/dates"

export const PromotionalTestTable = ({
  header = true,
  tests,
}: {
  header: boolean
  tests: Test_PromotionalTestTableFragment[]
}) => {
  return (
    <>
      <div className="md:hidden flex flex-col gap-2">
        {tests.map((test) => (
          <div
            key={test.id}
            className="px-4 py-6 border rounded-lg hover:bg-white focus:bg-white border-gray-E6E6E3"
          >
            <div className="flex flex-col">
              <div className="flex items-center justify-between">
                <TestNameWithLink name={test.name || ""} testId={test.id} />
              </div>
              <div className="mt-2 text-gray-333 text-sm">
                {test.expectedTimeSeconds &&
                  humanizeSecondsToMinutes(test.expectedTimeSeconds)}
              </div>
              <div className="mt-2">
                <LinkButton
                  to={candidateTestPath({ testId: test.id })}
                  size="sm"
                >
                  Take Test
                </LinkButton>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Table containerClassName="hidden md:block">
        {header && (
          <TableHeader>
            <TableRow>
              <TableHead className="w-full">Test Name</TableHead>
              <TableHead className="whitespace-nowrap">Expected Time</TableHead>
              <TableHead className="whitespace-nowrap">Action</TableHead>
            </TableRow>
          </TableHeader>
        )}
        <TableBody>
          {tests.map((test) => (
            <TableRow
              key={test.id}
              navigationPath={candidateTestPath({ testId: test.id })}
            >
              <TableCell className="w-full">
                <TestNameWithLink name={test.name || ""} testId={test.id} />
                <div className="text-sm text-gray-999">
                  {test.organization.name}
                </div>
              </TableCell>

              <TableCell className="whitespace-nowrap">
                {test.expectedTimeSeconds &&
                  humanizeSecondsToMinutes(test.expectedTimeSeconds)}
              </TableCell>

              <TableCell>
                <LinkButton to={candidateTestPath({ testId: test.id })}>
                  Take Test
                </LinkButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const TestNameWithLink = ({
  name,
  testId,
}: {
  name: string
  testId: string
}) => (
  <div className="text-base relative group">
    <Link
      to={candidateTestPath({ testId })}
      className="inline-block text-lg font-semibold group-hover:text-blue-500 text-gray-333 hover:text-primary"
    >
      {name}
    </Link>
    <img
      src={linkImage}
      alt="Link"
      className="inline-block ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-75"
    />
  </div>
)
