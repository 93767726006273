import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { PageHeader } from "~/ui/PageHeader"
import { formatDateAndTime, humanizeSeconds } from "~/common/dates"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/shadcn/ui/tabs"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/tables/Table"
import { QuestionGroup_AdminTestQuestionsTableFragment } from "~/__generated__/graphql"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"
import { Card, CardSegment } from "~/ui/Card"
import {
  frequencyLabel,
  QUESTION_DIFFICULTY_NAMES,
  QUESTION_TYPE_NAMES,
  TEST_ACCESS_LEVELS,
} from "~/questions/QuestionGroupForm/utils"
import { CandidateTests } from "~/tests/CandidateTests"
import { Markdown } from "~/ui/Markdown"
import { adminCandidateTestPath } from "~/common/paths"

const PER_PAGE = 20

export const AdminTestScreen = () => {
  const { testId } = useParams()
  invariant(testId)

  const questionGroupsPagination = usePagination({
    perPage: PER_PAGE,
    paramKey: "questions_page",
  })

  const { loading, data, error } = useQuery(TEST_QUERY_DOCUMENT, {
    variables: {
      testId,
      questionGroupsFirst: PER_PAGE,
      questionGroupsAfter: questionGroupsPagination.after,
    },
  })

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading company." />

  const test = data.adminTest

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title={`Test: ${test.name}`}
        subhead={test.organization.name}
      />
      <Card>
        <CardSegment label="Test Created Date">
          {formatDateAndTime(test.createdAt)}
        </CardSegment>
        <CardSegment label="Access">
          {TEST_ACCESS_LEVELS[test.access]}
        </CardSegment>
        <CardSegment label="Require Email Verification">
          {test.requireEmailVerification ? "Yes" : "No"}
        </CardSegment>
        <CardSegment label="Require Phone Verification">
          {test.requirePhoneVerification ? "Yes" : "No"}
        </CardSegment>
        <CardSegment label="Require Audio">
          {test.requireAudio ? "Yes" : "No"}
        </CardSegment>
        <CardSegment label="Require Video">
          {test.requireVideo ? "Yes" : "No"}
        </CardSegment>
        <CardSegment label="Expected Time">
          {test.expectedTimeSeconds
            ? humanizeSeconds(test.expectedTimeSeconds)
            : "N/A"}
        </CardSegment>
        <CardSegment label="Description">
          <Markdown>{test.description}</Markdown>
        </CardSegment>
        <CardSegment label="Welcome Note">
          <Markdown>{test.welcomeNote}</Markdown>
        </CardSegment>
        <CardSegment label="Closing Notes">
          <Markdown>{test.closingNotes}</Markdown>
        </CardSegment>
      </Card>
      <Tabs defaultValue="questionGroups">
        <TabsList>
          <TabsTrigger value="questionGroups">Questions</TabsTrigger>
          <TabsTrigger value="candidateTests">Responses</TabsTrigger>
        </TabsList>
        <TabsContent value="questionGroups">
          {data.questionGroups && data.questionGroups.edges.length > 0 ? (
            <>
              <QuestionGroupsTable
                data={data.questionGroups.edges.map((e: any) => e.node)}
              />
              {data.questionGroups.pageCount > 1 && (
                <Pagination
                  page={questionGroupsPagination.page}
                  pageCount={data.questionGroups.pageCount}
                  paginate={questionGroupsPagination.paginate}
                />
              )}
            </>
          ) : (
            <EmptyData>No questions exist for this test</EmptyData>
          )}
        </TabsContent>
        <TabsContent value="candidateTests">
          <CandidateTests
            testId={testId}
            columns={[
              "candidateName",
              "email",
              "phone",
              "timeTakenSeconds",
              "verified",
              "aiScore",
              "manualScore",
              "actions",
            ]}
            viewPath={(candidateTest) =>
              adminCandidateTestPath({ candidateTestId: candidateTest.id })
            }
          />
        </TabsContent>
      </Tabs>
    </div>
  )
}

const QuestionGroupsTable = ({
  data,
}: {
  data: QuestionGroup_AdminTestQuestionsTableFragment[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Question Type</TableHead>
          <TableHead>Question Variations</TableHead>
          <TableHead>Frequency</TableHead>
          <TableHead>Difficulty</TableHead>
          <TableHead>Expected Time</TableHead>
          <TableHead>Time Limit</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((questionGroup) => (
          <TableRow key={questionGroup.id}>
            <TableCell>
              {QUESTION_TYPE_NAMES[questionGroup.questionType]}
            </TableCell>
            <TableCell>{questionGroup.questionsCount}</TableCell>
            <TableCell>
              {frequencyLabel(questionGroup.frequencyPercentage)}
            </TableCell>
            <TableCell>
              {questionGroup.difficulty &&
                QUESTION_DIFFICULTY_NAMES[questionGroup.difficulty]}
            </TableCell>
            <TableCell>
              {questionGroup.expectedTimeSeconds &&
                humanizeSeconds(questionGroup.expectedTimeSeconds)}
            </TableCell>
            <TableCell>
              {questionGroup.timeLimitSeconds &&
                humanizeSeconds(questionGroup.timeLimitSeconds)}
            </TableCell>
            <TableCell className="flex flex-row items-center gap-2 justify-end"></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

gql(`
  fragment Test_AdminDetail on Test {
    id
    name
    createdAt
    access
    requireEmailVerification
    requirePhoneVerification
    requireAudio
    requireVideo
    expectedTimeSeconds
    questionGroupsCount
    description
    welcomeNote
    closingNotes

    organization {
      name
    }
  }

  fragment QuestionGroup_AdminTestQuestionsTable on QuestionGroup {
    id
    questionType
    questionsCount
    frequencyPercentage
    difficulty
    expectedTimeSeconds
    timeLimitSeconds
  }
`)

const TEST_QUERY_DOCUMENT = gql(`
  query AdminTest($testId: ID!, $questionGroupsFirst: Int!, $questionGroupsAfter: String) {
    adminTest(testId: $testId) {
      ...Test_AdminDetail
    }

    questionGroups(testId: $testId, first: $questionGroupsFirst, after: $questionGroupsAfter) {
      totalCount
      pageCount(first: $questionGroupsFirst)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...QuestionGroup_AdminTestQuestionsTable
        }
      }
    }
  }
`)
