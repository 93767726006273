import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { TextareaHTMLAttributes } from "react"
import { cn } from "~/common/shadcn-utils"
import { HelpTooltip } from "~/ui/HelpTooltip"

export const TextareaField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  inputClassName,
  containerClassName,
  helpText,
  required,
  ...inputProps
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  containerClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
  helpText?: string
  required?: boolean
} & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={cn("flex gap-2 items-center", labelClassName)}>
            {label}
            {helpText && (
              <HelpTooltip className="max-w-64 text-gray-333">
                {helpText}
              </HelpTooltip>
            )}
            {required && <span className="text-red-500 pl-1">*</span>}
          </FormLabel>
          <FormControl>
            <textarea
              className={cn(
                "w-full border-1 border-gray-E3E2E0 rounded",
                inputClassName
              )}
              {...field}
              {...inputProps}
            ></textarea>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
