import { useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { POST_LOGIN_REDIRECT_KEY } from "./paths"

export const useRedirectAfterLogin = () => {
  const [searchParams] = useSearchParams()
  const hasSetRedirectRef = useRef(false)

  useEffect(() => {
    if (hasSetRedirectRef.current) return

    const redirectTo = searchParams.get("redirectTo")
    if (redirectTo) {
      console.log("setting redirect path", redirectTo)
      localStorage.setItem(POST_LOGIN_REDIRECT_KEY, redirectTo)
      hasSetRedirectRef.current = true
    }
  }, [searchParams])
}
