import { gql, useQuery } from "@apollo/client"
import { usePagination } from "~/common/usePagination"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { PageHeader } from "~/ui/PageHeader"
import { Error } from "~/ui/Error"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/tables/Table"
import { Button } from "~/shadcn/ui/button"
import { Pagination } from "~/ui/Pagination"
import { User } from "~/__generated__/graphql"
import { ChevronRight } from "lucide-react"
import { ConfirmDialog } from "~/ui/Confirm"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  DialogTitle,
} from "~/shadcn/ui/dialog"
import { DialogProps } from "@radix-ui/react-dialog"
import { Form } from "~/shadcn/ui/form"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useSafeMutation } from "~/common/useSafeMutation"
import { TextField } from "~/ui/forms/TextField"
import toast from "react-hot-toast"

const PER_PAGE = 20

const AdminsTable = ({
  admins,
  setAdmins,
}: {
  admins: Pick<User, "id" | "email">[]
  setAdmins: Dispatch<SetStateAction<Pick<User, "id" | "email">[]>>
}) => {
  const [runRemoveAdmin] = useSafeMutation(REMOVE_ADMIN_MUTATION)
  const removeAdmin = async (adminId: User["id"]) => {
    const { data, errors } = await runRemoveAdmin({
      variables: {
        input: {
          userId: adminId,
        },
      },
    })

    if (errors) {
      toast.error("Error removing admin")
    } else if (data?.adminRemove) {
      toast.success("Admin removed")
      setAdmins((admins) => admins.filter((a) => a.id !== adminId))
    }
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Email</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {admins.map((admin) => (
          <TableRow key={admin.id}>
            <TableCell>{admin.email}</TableCell>
            <TableCell className="text-right">
              <ConfirmDialog
                text={`Are you sure you want to remove ${admin.email} as an admin?`}
                onSuccess={() => removeAdmin(admin.id)}
              >
                <Button type="button" variant="outline">
                  Remove
                </Button>
              </ConfirmDialog>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const formSchema = z.object({
  email: z.string().min(1, {
    message: "Required",
  }),
})

type FormValues = z.infer<typeof formSchema>

interface AddAdminModalProps extends DialogProps {
  onAdminCreated: (admin: Pick<User, "id" | "email">) => void
}

const AddAdminModal = ({ onAdminCreated, ...props }: AddAdminModalProps) => {
  const [runCreateAdmin, { loading }] = useSafeMutation(ADD_ADMIN_MUTATION)

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  })

  useEffect(() => {
    if (!props.open) {
      form.reset()
    }
  }, [props.open, form])

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const { data, errors } = await runCreateAdmin({
        variables: {
          input: {
            email: values.email,
          },
        },
      })

      if (errors) {
        toast.error("Error creating admin")
      } else if (data?.adminCreate) {
        toast.success("Admin created")
        onAdminCreated(data.adminCreate.user)
      }
    },
    [runCreateAdmin, onAdminCreated]
  )

  return (
    <Dialog {...props}>
      <DialogPortal>
        <DialogContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <DialogHeader>
                <DialogTitle>Add Administrator</DialogTitle>
              </DialogHeader>

              <TextField
                label="Email"
                control={form.control}
                name="email"
                disabled={loading}
              />

              <DialogFooter className="sm:justify-between">
                <Button type="submit" disabled={loading}>
                  Add Administrator
                </Button>
                <DialogClose asChild>
                  <Button type="button" variant="ghost">
                    Close & Cancel
                  </Button>
                </DialogClose>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export const AdminAdminsScreen = () => {
  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { data, error, loading } = useQuery(ADMINS_QUERY_DOCUMENT, {
    variables: { first: PER_PAGE, after },
  })
  const [admins, setAdmins] = useState<Pick<User, "id" | "email">[]>([])
  const [addAdminModalOpen, setAddAdminModalOpen] = useState(false)

  const onAdminCreated = useCallback((admin: Pick<User, "id" | "email">) => {
    setAdmins((admins) => [admin, ...admins])
    setAddAdminModalOpen(false)
  }, [])

  useEffect(() => {
    if (data) {
      setAdmins(data.adminAdmins.edges.map((e: any) => e.node))
    }
  }, [data])

  const addAdmin = () => {
    setAddAdminModalOpen(true)
  }

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading admins." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <AddAdminModal
        open={addAdminModalOpen}
        onAdminCreated={onAdminCreated}
        onOpenChange={setAddAdminModalOpen}
      />

      <PageHeader
        title="Admins"
        subhead="Add or manage administrators within your company."
      >
        <Button onClick={addAdmin} type="button">
          Add Admin <ChevronRight className="w-5 h-5" />
        </Button>
      </PageHeader>

      {admins.length > 0 ? (
        <AdminsTable admins={admins} setAdmins={setAdmins} />
      ) : (
        <div>No admins yet.</div>
      )}

      {data.adminAdmins.pageCount > 1 && (
        <Pagination
          page={page}
          pageCount={data.adminAdmins.pageCount}
          paginate={paginate}
        />
      )}
    </div>
  )
}

const ADMINS_QUERY_DOCUMENT = gql(`
  query Admins($first: Int!, $after: String) {
    adminAdmins(first: $first, after: $after) {
      totalCount
      pageCount(first: $first)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          email
        }
      }
    }
  }
`)

const ADD_ADMIN_MUTATION = gql(`
  mutation AddAdmin($input: AdminCreateInput!) {
    adminCreate(input: $input) {
      user {
        id
        email
      }
    }
  }
`)

const REMOVE_ADMIN_MUTATION = gql(`
  mutation RemoveAdmin($input: AdminRemoveInput!) {
    adminRemove(input: $input) {
      user {
        id
        email
      }
    }
  }
`)
