import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import {
  organizationQuestionResponsePath,
  organizationTestResponsePath,
} from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { gql } from "~/__generated__"
import { QuestionResponseDetails } from "~/questions/QuestionResponseDetails"
import { Navigation } from "~/organizations/Navigation"
import { QuestionNavigator } from "~/questions/QuestionNavigator"

export const OrganizationTestQuestionResponseScreen = () => {
  const { test } = useOrganizationTest()
  const { questionResponseId } = useParams()
  invariant(questionResponseId)

  const { loading, data, error } = useQuery(QUESTION_RESPONSE_QUERY_DOCUMENT, {
    variables: { questionResponseId },
  })

  const organizationId = useOrganizationId()
  const testId = test?.id || ""

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data)
    return <Error message="Unable to load question details." />

  return (
    <CandidateTestLayout
      test={test}
      candidateTest={data?.questionResponse.candidateTest}
      questionResponse={data.questionResponse}
      backLabel="Responses"
      backPath={organizationTestResponsePath({
        organizationId,
        testId,
        candidateTestId: data?.questionResponse.candidateTest.id || "",
      })}
      rightModule={
        <QuestionNavigator
          questionResponse={data.questionResponse}
          viewPath={(questionResponseId) =>
            organizationQuestionResponsePath({
              organizationId,
              testId,
              questionResponseId,
            })
          }
        />
      }
      navigation={
        <Navigation organizationId={organizationId} testId={testId} />
      }
    >
      <QuestionResponseDetails questionResponse={data.questionResponse} />
      <QuestionNavigator
        questionResponse={data.questionResponse}
        viewPath={(questionResponseId) =>
          organizationQuestionResponsePath({
            organizationId,
            testId,
            questionResponseId,
          })
        }
      />
    </CandidateTestLayout>
  )
}

gql(`
  fragment QuestionResponse_Details on QuestionResponse {
    ...QuestionResponse_CandidateTestResponses

    nextQuestionResponseId
    previousQuestionResponseId
    questionNumber
    manualScoreNote
    candidateTest {
      id
      questionCount
      ...CandidateTest_CandidateTestResponses
    }
  }
`)

const QUESTION_RESPONSE_QUERY_DOCUMENT = gql(`
  query QuestionResponseDetails($questionResponseId: ID!) {
    questionResponse(questionResponseId: $questionResponseId) {
      ...QuestionResponse_Details
    }
  }
`)
