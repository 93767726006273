import { PageHeader } from "~/ui/PageHeader"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { Organization_AdminOrganizationsTableFragment } from "~/__generated__/graphql"

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { LinkButton } from "~/shadcn/ui/button"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"
import { adminOrganizationPath } from "~/common/paths"

const PER_PAGE = 20

const OrganizationsTable = ({
  organizations,
}: {
  organizations: Organization_AdminOrganizationsTableFragment[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead className="text-right">Company Admins</TableHead>
          <TableHead className="text-right">Tests Created</TableHead>
          <TableHead className="text-right">Responses Collected</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {organizations.map((organization) => (
          <TableRow key={organization.id}>
            <TableCell>{organization.name}</TableCell>
            <TableCell className="text-right">
              {organization.adminsCount}
            </TableCell>
            <TableCell className="text-right">
              {organization.testsCount}
            </TableCell>
            <TableCell className="text-right">
              {organization.completedCandidateTestsCount}
            </TableCell>
            <TableCell className="text-right">
              <LinkButton
                to={adminOrganizationPath({ organizationId: organization.id })}
              >
                View
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const AdminOrganizationsScreen = () => {
  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { data, error, loading } = useQuery(ORGANIZATIONS_QUERY_DOCUMENT, {
    variables: { first: PER_PAGE, after },
  })
  const organizations = data?.adminOrganizations?.edges.map((e) => e.node) || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading companies." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader title="Companies" />
      {organizations.length > 0 ? (
        <OrganizationsTable organizations={organizations} />
      ) : (
        <div>No organizations yet.</div>
      )}

      {data.adminOrganizations.pageCount > 1 && (
        <Pagination
          page={page}
          pageCount={data.adminOrganizations.pageCount}
          paginate={paginate}
        />
      )}
    </div>
  )
}

gql(`
  fragment Organization_AdminOrganizationsTable on Organization {
    id
    name
    adminsCount
    testsCount
    completedCandidateTestsCount
  }
`)

const ORGANIZATIONS_QUERY_DOCUMENT = gql(`
  query AdminOrganizations($first: Int!, $after: String) {
    adminOrganizations(first: $first, after: $after) {
      totalCount
      pageCount(first: $first)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...Organization_AdminOrganizationsTable
        }
      }
    }
  }
`)
