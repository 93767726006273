import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { PageHeader } from "~/ui/PageHeader"
import { formatDate, formatDateAndTime, formatTime } from "~/common/dates"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/shadcn/ui/tabs"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/tables/Table"
import {
  OrganizationUser_AdminOrganizationUsersTableFragment,
  Test_AdminOrganizationTestsTableFragment,
} from "~/__generated__/graphql"
import { usePagination } from "~/common/usePagination"
import { Button, LinkButton } from "~/shadcn/ui/button"
import { adminTestPath } from "~/common/paths"
import { Pagination } from "~/ui/Pagination"
import { Card, CardSegment } from "~/ui/Card"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import {
  TEST_ACCESS_LEVELS,
  TEST_PUBLISH_STATUSES,
} from "~/questions/QuestionGroupForm/utils"

const PER_PAGE = 20

export const AdminOrganizationScreen = () => {
  const { organizationId } = useParams()
  invariant(organizationId)

  const organizationUsersPagination = usePagination({
    perPage: PER_PAGE,
    paramKey: "users_page",
  })
  const organizationTestsPagination = usePagination({
    perPage: PER_PAGE,
    paramKey: "tests_page",
  })

  const { loading, data, error } = useQuery(
    TEST_ORGANIZATION_TESTS_QUERY_DOCUMENT,
    {
      variables: {
        organizationId,
        organizationUsersFirst: PER_PAGE,
        organizationUsersAfter: organizationUsersPagination.after,
        organizationTestsFirst: PER_PAGE,
        organizationTestsAfter: organizationTestsPagination.after,
      },
    }
  )

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading company." />

  const organization = data.adminOrganization

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader title={`Company: ${organization.name}`} />
      <Card>
        <CardSegment label="Created Date">
          {formatDateAndTime(organization.createdAt)}
        </CardSegment>
      </Card>
      <Tabs defaultValue="tests">
        <TabsList>
          <TabsTrigger value="tests">Tests</TabsTrigger>
          <TabsTrigger value="users">Users</TabsTrigger>
        </TabsList>
        <TabsContent value="tests">
          {data.tests && data.tests.edges.length > 0 ? (
            <>
              <OrganizationTestsTable
                data={data.tests.edges.map((e: any) => e.node)}
              />
              {data.tests.pageCount > 1 && (
                <Pagination
                  page={organizationTestsPagination.page}
                  pageCount={data.tests.pageCount}
                  paginate={organizationTestsPagination.paginate}
                />
              )}
            </>
          ) : (
            <EmptyData>No tests exist for this organization</EmptyData>
          )}
        </TabsContent>
        <TabsContent value="users">
          {data.organizationUsers.edges.length > 0 ? (
            <>
              <OrganizationUsersTable
                data={data.organizationUsers.edges.map((e: any) => e.node)}
              />
              {data.organizationUsers.pageCount > 1 && (
                <Pagination
                  page={organizationUsersPagination.page}
                  pageCount={data.organizationUsers.pageCount}
                  paginate={organizationUsersPagination.paginate}
                />
              )}
            </>
          ) : (
            <EmptyData>No users exist for this organization</EmptyData>
          )}
        </TabsContent>
      </Tabs>
    </div>
  )
}

const OrganizationUsersTable = ({
  data,
}: {
  data: OrganizationUser_AdminOrganizationUsersTableFragment[]
}) => {
  const [runImpersonateUser] = useSafeMutation(IMPERSONATE_USER_MUTATION)
  const impersonateUser = async (userId: string) => {
    const { data, errors } = await runImpersonateUser({
      variables: { input: { userId } },
    })

    if (errors || !data?.userImpersonate?.user) {
      toast.error("Could not impersonate user")
      return
    }

    toast.success(
      `Impersonating ${data.userImpersonate.user.name}. Redirecting...`
    )
    setTimeout(() => {
      window.location.href = "/"
    }, 1000)
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Email</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((orgUser) => (
          <TableRow key={orgUser.id}>
            <TableCell>{orgUser.user.name}</TableCell>
            <TableCell>{orgUser.user.email}</TableCell>
            <TableCell className="flex flex-row items-center gap-2 justify-end">
              <Button
                onClick={() => impersonateUser(orgUser.user.id)}
                variant="outline"
              >
                Impersonate
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const OrganizationTestsTable = ({
  data,
}: {
  data: Test_AdminOrganizationTestsTableFragment[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead className="w-32">Created Date</TableHead>
          <TableHead className="w-32">Test Status</TableHead>
          <TableHead className="w-32">Access</TableHead>
          <TableHead className="w-32">Questions</TableHead>
          <TableHead className="w-32">Responses</TableHead>
          <TableHead className="w-32 text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((test) => (
          <TableRow key={test.id}>
            <TableCell className="truncate">{test.name}</TableCell>
            <TableCell className="text-right">
              <p>{formatDate(test.createdAt)}</p>
              <p>{formatTime(test.createdAt)}</p>
            </TableCell>
            <TableCell>{TEST_PUBLISH_STATUSES[test.publishStatus]}</TableCell>
            <TableCell>{TEST_ACCESS_LEVELS[test.access]}</TableCell>
            <TableCell>{test.questionGroupsCount}</TableCell>
            <TableCell>{test.completedCandidateTestsCount}</TableCell>
            <TableCell className="flex flex-row items-center gap-2 justify-end">
              <LinkButton to={adminTestPath({ testId: test.id })}>
                View
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

gql(`
  fragment Organization_AdminDetail on Organization {
    id
    name
    createdAt
  }

  fragment OrganizationUser_AdminOrganizationUsersTable on OrganizationUser {
    id
    user {
      id
      name
      email
    }
  }

  fragment Test_AdminOrganizationTestsTable on Test {
    id
    name
    createdAt
    publishStatus
    access
    questionGroupsCount
    completedCandidateTestsCount
  }
`)

const TEST_ORGANIZATION_TESTS_QUERY_DOCUMENT = gql(`
  query AdminOrganization($organizationId: ID!, $organizationUsersFirst: Int!, $organizationUsersAfter: String, $organizationTestsFirst: Int!, $organizationTestsAfter: String) {
    adminOrganization(organizationId: $organizationId) {
      ...Organization_AdminDetail
    }

    organizationUsers(organizationId: $organizationId, first: $organizationUsersFirst, after: $organizationUsersAfter) {
      totalCount
      pageCount(first: $organizationUsersFirst)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...OrganizationUser_AdminOrganizationUsersTable
        }
      }
    }

    tests(organizationId: $organizationId, first: $organizationTestsFirst, after: $organizationTestsAfter) {
      totalCount
      pageCount(first: $organizationTestsFirst)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...Test_AdminOrganizationTestsTable
        }
      }
    }
  }
`)

const IMPERSONATE_USER_MUTATION = gql(`
  mutation UserImpersonate($input: UserImpersonateInput!) {
    userImpersonate(input: $input) {
      user {
        id
        name
      }
    }
  }
`)
