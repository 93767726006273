// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path

import { path } from "static-path"
import invariant from "tiny-invariant"
import { CurrentUserProviderQuery } from "~/__generated__/graphql"

export const loadingPath = path("/")
export const marketingPath = path("/landing")
export const termsOfServicePath = path("/terms-of-service")
export const privacyPolicyPath = path("/privacy-policy")
export const cookiePolicyPath = path("/cookie-policy")
export const promotionalTestsPath = path("/community-tests")
export const signInPath = path("/sign-in")
export const signInTokenPath = path("/users/sign_in/:sessionId")
export const createAccountPath = path("/create-account")
export const createOrganizationPath = path("/create-organization")

export const organizationTestsPath = path("/:organizationId/tests")

export const organizationCreateTestPath = path(
  "/:organizationId/tests/create-test"
)
export const organizationTestOverviewPath = path(
  "/:organizationId/tests/:testId/overview"
)
export const organizationTestQuestionsPath = path(
  "/:organizationId/tests/:testId/questions"
)
export const organizationTestQuestionImportPath = path(
  "/:organizationId/tests/:testId/questions/import"
)
export const organizationTestPreviewPath = path(
  "/:organizationId/tests/:testId/questions/preview"
)
export const organizationTestAccessPath = path(
  "/:organizationId/tests/:testId/access"
)
export const organizationTestAIWizardPath = path(
  "/:organizationId/tests/:testId/ai-wizard"
)
export const organizationTestResponsesPath = path(
  "/:organizationId/tests/:testId/responses"
)
export const organizationTestResponsePath = path(
  "/:organizationId/tests/:testId/responses/:candidateTestId"
)
export const organizationQuestionResponsePath = path(
  "/:organizationId/tests/:testId/responses/questions/:questionResponseId"
)
export const organizationQuestionGroupCreatePath = path(
  "/:organizationId/tests/:testId/questions/create"
)
export const organizationQuestionGroupEditPath = path(
  "/:organizationId/tests/:testId/questions/:questionGroupId/edit"
)
export const organizationUserProfilePath = path("/:organizationId/user/profile")
export const organizationAdminsPath = path("/:organizationId/admins")
export const organizationCandidatesPath = path("/:organizationId/candidates")

export const candidateTestsPath = path("/candidate/tests")
export const candidateTestPath = path("/candidate/tests/:testId")
export const candidateTestContinuePath = path(
  "/candidate/tests/:testId/continue"
)

export const userProfilePath = path("/user/profile")

export const adminAdminsPath = path("/admin/admins")
export const adminCandidatesPath = path("/admin/candidates")
export const adminCandidatePath = path("/admin/candidates/:userId")
export const adminCandidateTestPath = path(
  "/admin/candidate-tests/:candidateTestId"
)
export const adminOrganizationPath = path("/admin/companies/:organizationId")
export const adminOrganizationsPath = path("/admin/companies")
export const adminQuestionResponsePath = path(
  "/admin/question-responses/:questionResponseId"
)
export const adminTestPath = path("/admin/tests/:testId")
export const adminTestsPath = path("/admin/tests")

// server routes
export const tokenConfirmationPath = path("/users/sign_in/:id")
export const signOutPath = path("/users/sign_out")
export const goodJobPath = path("/good_job")
export const questionsExportPath = path("/tests/:testId/question_export")

export const POST_LOGIN_REDIRECT_KEY = "post-login-redirect-path"

export const userPostLoginPath = (
  currentUser: CurrentUserProviderQuery["currentUser"]
): string => {
  invariant(currentUser)
  const postLoginRedirectPath = localStorage.getItem(POST_LOGIN_REDIRECT_KEY)

  if (postLoginRedirectPath) {
    localStorage.removeItem(POST_LOGIN_REDIRECT_KEY)
    return postLoginRedirectPath
  } else if (currentUser.organizations[0]) {
    return organizationTestsPath({
      organizationId: currentUser.organizations[0].id,
    })
  } else if (currentUser.invitedToAnyTests || currentUser.startedAnyTests) {
    return candidateTestsPath.pattern
  } else {
    return createOrganizationPath.pattern
  }
}
