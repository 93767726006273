import {
  QuestionDifficultyEnum,
  QuestionTypeEnum,
  TestAccessEnum,
  TestPublishStatusEnum,
} from "~/__generated__/graphql"

export const includesChoices = (questionType: QuestionTypeEnum) => {
  return (
    questionType === QuestionTypeEnum.SingleChoice ||
    questionType === QuestionTypeEnum.MultipleChoice
  )
}

export const divideIntoEqualNumbers = (N: number) => {
  const equalNumbers = []
  const number = Math.floor(100 / N)
  let remaining = 100 % N
  for (let i = 0; i < N; i++) {
    if (remaining > 0) {
      equalNumbers.push(number + 1)
      remaining--
    } else {
      equalNumbers.push(number)
    }
  }
  return equalNumbers
}

export const QUESTION_TYPE_NAMES: Record<QuestionTypeEnum, string> = {
  [QuestionTypeEnum.TextResponse]: "Text Entry",
  [QuestionTypeEnum.VideoResponse]: "Audio & Video",
  [QuestionTypeEnum.SingleChoice]: "Single Choice",
  [QuestionTypeEnum.MultipleChoice]: "Multiple Choice",
}

export const QUESTION_DIFFICULTY_NAMES: Record<QuestionDifficultyEnum, string> =
  {
    [QuestionDifficultyEnum.Easy]: "Easy",
    [QuestionDifficultyEnum.Medium]: "Medium",
    [QuestionDifficultyEnum.Difficult]: "Difficult",
  }

export const frequencyLabel = (frequencyPercentage: number) => {
  if (frequencyPercentage === 100) return "Always"
  if (frequencyPercentage === 0) return "Never"
  return `${frequencyPercentage}%`
}

export const TEST_ACCESS_LEVELS: Record<TestAccessEnum, string> = {
  [TestAccessEnum.Public]: "Public",
  [TestAccessEnum.EmailInvitation]: "Email Invitation",
  [TestAccessEnum.PrivateUrl]: "Private URL",
}

export const TEST_PUBLISH_STATUSES: Record<TestPublishStatusEnum, string> = {
  [TestPublishStatusEnum.Archived]: "Archived",
  [TestPublishStatusEnum.Published]: "Published",
  [TestPublishStatusEnum.Unpublished]: "Unpublished",
}
