import { Link, useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  organizationTestOverviewPath,
  organizationTestsPath,
} from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form } from "~/shadcn/ui/form"
import { TextField } from "~/ui/forms/TextField"
import { displayErrors } from "~/common/validations"
import invariant from "tiny-invariant"
import { useOrganizationId } from "~/common/useCurrentOrganization"

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Required",
  }),
})

export const OrganizationCreateTestScreen = () => {
  const organizationId = useOrganizationId()
  const [testCreate, { loading }] = useSafeMutation(TEST_CREATE_MUTATION)
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await testCreate({
      variables: {
        input: {
          ...values,
          organizationId,
        },
      },
    })

    if (errors) {
      console.log(errors)
      displayErrors(errors, form.setError)
    } else {
      invariant(data?.testCreate?.test?.id)
      navigate(
        organizationTestOverviewPath({
          organizationId,
          testId: data.testCreate.test.id,
        })
      )
    }
  }

  return (
    <div className="flex-1 flex flex-col justify-center p-6">
      <div className="w-full sm:w-[360px] mx-auto">
        <div className="text-2xl text-gray-333 font-semibold">
          Create a test.
        </div>
        <div className="text-2xl text-gray-999 font-semibold mb-8">
          Please name your test
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <TextField
              control={form.control}
              name="name"
              label="Name"
              placeholder="Developer Interview"
            />

            <div className="h-[1px] my-4 bg-gray-E3E2E0" />

            <Button
              className="btn w-full mb-12"
              disabled={loading}
              type="submit"
            >
              Save & Continue
            </Button>
          </form>
        </Form>

        <div className="text-gray-999 text-center">
          <Link
            to={organizationTestsPath({ organizationId })}
            className="underline"
          >
            Close & Cancel
          </Link>
        </div>
      </div>
    </div>
  )
}

const TEST_CREATE_MUTATION = gql(`
  mutation TestCreate($input: TestCreateInput!) {
    testCreate(input: $input) {
      test {
        id
        name
      }
    }
  }
`)
