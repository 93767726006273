import ReactMarkdown, { Options } from "react-markdown"
import remarkGfm from "remark-gfm"

export const Markdown = ({ components, ...props }: Options) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({ node, children, ...other }) => (
          <a className="text-blue-500 hover:underline" {...other}>
            {children}
          </a>
        ),
        h1: ({ node, children, ...other }) => (
          <h1 className="text-3xl font-bold my-4" {...other}>
            {children}
          </h1>
        ),
        h2: ({ node, children, ...other }) => (
          <h2 className="text-2xl font-bold my-3" {...other}>
            {children}
          </h2>
        ),
        h3: ({ node, children, ...other }) => (
          <h3 className="text-xl font-bold my-2" {...other}>
            {children}
          </h3>
        ),
        h4: ({ node, children, ...other }) => (
          <h4 className="text-lg font-bold my-2" {...other}>
            {children}
          </h4>
        ),
        h5: ({ node, children, ...other }) => (
          <h5 className="text-base font-bold my-2" {...other}>
            {children}
          </h5>
        ),
        h6: ({ node, children, ...other }) => (
          <h6 className="text-sm font-bold my-2" {...other}>
            {children}
          </h6>
        ),
        li: ({ node, ...other }) => <li className="py-2" {...other} />,
        ol: ({ node, ...other }) => (
          <ol
            className="list-decimal list-inside py-2 first:pt-0 last:pb-0"
            {...other}
          />
        ),
        p: ({ node, ...other }) => (
          <p className="py-2 first:pt-0 last:pb-0" {...other} />
        ),
        ul: ({ node, ...other }) => (
          <ul
            className="list-disc list-inside py-2 first:pt-0 last:pb-0"
            {...other}
          />
        ),
        ...components,
      }}
      {...props}
    />
  )
}
