import { useCallback, useEffect, useMemo, useState } from "react"
import {
  QuestionSuggestion,
  Test_OrgTestLayoutFragment,
} from "~/__generated__/graphql"
import { AIWizardProvider } from "./AIWizard/AIWizardProvider"
import { PromptsForm, PromptsFormValues } from "./AIWizard/PromptsForm"
import { GeneratingQuestions } from "./AIWizard/GeneratingQuestions"
import { Error } from "./AIWizard/Error"
import { Suggestions } from "./AIWizard/Suggestions"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { QUESTION_GROUP_UPSERT_MUTATION } from "~/questions/QuestionGroupForm/QuestionGroupForm"
import { AddingQuestions } from "./AIWizard/AddingQuestions"
import toast from "react-hot-toast"

interface AIWizardProps {
  test: Test_OrgTestLayoutFragment
  onDone?: () => void
}

export const AIWizardStep = {
  PromptsForm: 0,
  Error: 1,
  Suggestions: 2,
  GeneratingQuestions: 3,
  AddingQuestions: 4,
}

export const AIWizard = ({ onDone, test }: AIWizardProps) => {
  const [currentStep, setCurrentStep] = useState(AIWizardStep.PromptsForm)
  const [prompts, setPrompts] = useState<PromptsFormValues>()
  const [suggestions, setSuggestions] = useState<QuestionSuggestion[]>([])

  const loading = useMemo(
    () =>
      currentStep === AIWizardStep.GeneratingQuestions ||
      currentStep === AIWizardStep.AddingQuestions,
    [currentStep]
  )

  const reset = useCallback(() => {
    setCurrentStep(AIWizardStep.PromptsForm)
    setSuggestions([])
  }, [])

  const [runGenerateSuggestions] = useSafeMutation(
    GENERATE_SUGGESTIONS_MUTATION
  )
  const [runQuestionGroupCreate] = useSafeMutation(
    QUESTION_GROUP_UPSERT_MUTATION
  )

  useEffect(() => {
    const fetchSuggestions = async (prompts: PromptsFormValues) => {
      try {
        const { data } = await runGenerateSuggestions({
          variables: {
            input: {
              testId: test.id,
              roleDescription: prompts.roleDescription,
              desiredSkills: prompts.desiredSkills,
              exampleQuestions: prompts.exampleQuestions,
              count: prompts.numberOfQuestions,
            },
          },
        })

        if (data?.testGenerateQuestionSuggestions?.questionSuggestions) {
          setSuggestions(
            data.testGenerateQuestionSuggestions.questionSuggestions
          )
          setCurrentStep(AIWizardStep.Suggestions)
        } else {
          setCurrentStep(AIWizardStep.Error)
        }
      } catch (error) {
        setCurrentStep(AIWizardStep.Error)
      }
    }

    if (prompts) {
      setCurrentStep(AIWizardStep.GeneratingQuestions)
      fetchSuggestions(prompts)
    }
  }, [prompts, runGenerateSuggestions, test.id])

  const addSelectedSuggestions = useCallback(
    async (selectedSuggestions: QuestionSuggestion[]) => {
      setCurrentStep(AIWizardStep.AddingQuestions)
      let hasError = false

      for (const suggestion of selectedSuggestions) {
        const { data, errors } = await runQuestionGroupCreate({
          variables: {
            input: {
              testId: test.id,

              input: {
                name: suggestion.prompt,
                questionType: suggestion.type,
                questions: [
                  {
                    questionCopy: suggestion.prompt,
                    frequencyPercentage: 100,
                    questionChoices:
                      suggestion.choices &&
                      suggestion.choices.map((choice) => ({
                        choiceCopy: choice,
                        isCorrect:
                          !!suggestion.preferredChoices?.includes(choice),
                      })),
                  },
                ],
                expectedTimeSeconds: suggestion.expectedTimeSeconds,
                tags: suggestion.category ? [suggestion.category] : [],
              },
            },
          },
        })

        if (errors || !data?.questionGroupCreateOrUpdate?.questionGroup) {
          hasError = true
          break
        }
      }

      if (hasError) {
        setCurrentStep(AIWizardStep.Error)
      } else {
        toast.success("Questions added successfully!")
        onDone?.()
        reset()
      }
    },
    [reset, runQuestionGroupCreate, test.id, onDone]
  )

  const generateCSV = useCallback(() => {
    const csv = [
      "Question_Type,Internal_Question_Group_Name,Frequency,Difficulty,Time_Limit_Seconds,Expected_Time_Seconds,Question_Text,Response_Choice_1,Response_Choice_2,Response_Choice_3,Response_Choice_4,Response_Choice_5",
      ...suggestions.map((suggestion) => {
        return [
          suggestion.type.toLowerCase(),
          `"${suggestion.prompt}"`, // intentionally quoted
          "100",
          "medium",
          "",
          suggestion.expectedTimeSeconds,
          `"${suggestion.prompt}"`,
          ...Array(5)
            .fill(undefined)
            .map((_, i) => `"${suggestion.choices?.[i] || ""}"`),
        ].join(",")
      }),
    ]

    const csvContent = `data:text/csv;charset=utf-8,${csv.join("\n")}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "ai-wizard-suggestions.csv")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [suggestions])

  return (
    <AIWizardProvider
      {...{
        suggestions,
        setSuggestions,
        test,
        currentStep,
        setCurrentStep,
        prompts,
        setPrompts,
        loading,
        reset,
        addSelectedSuggestions,
        generateCSV,
      }}
    >
      {currentStep === AIWizardStep.GeneratingQuestions && (
        <GeneratingQuestions />
      )}
      {currentStep === AIWizardStep.AddingQuestions && <AddingQuestions />}
      {currentStep === AIWizardStep.PromptsForm && <PromptsForm />}
      {currentStep === AIWizardStep.Error && <Error />}
      {currentStep === AIWizardStep.Suggestions && <Suggestions />}
    </AIWizardProvider>
  )
}

const GENERATE_SUGGESTIONS_MUTATION = gql(`
  mutation GenerateSuggestions($input: TestGenerateQuestionSuggestionsInput!) {
    testGenerateQuestionSuggestions(input: $input) {
      questionSuggestions {
        prompt
        category
        type
        choices
        preferredChoices
        expectedTimeSeconds
      }
    }
  }
`)
