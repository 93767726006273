import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"

export const useOrganizationTest = () => {
  const { testId } = useParams()
  invariant(testId)

  const testResult = useQuery(TEST_LAYOUT_QUERY_DOCUMENT, {
    variables: { testId },
  })
  const test = testResult.data?.test || null

  const refetchTest = () => testResult.refetch()

  return { test, testId, refetchTest: refetchTest }
}

gql(`
  fragment Test_OrgTestLayout on Test {
    id
    name
    publishStatus
    description
    welcomeNote
    closingNotes
    access
    promote
    updatedAt
    disclaimers {
      id
      disclaimerText
      createdAt
    }
    invites {
      id
      userId
      invitationStatus
      user {
        id
        name
        email
      }
    }
    requireEmailVerification
    requirePhoneVerification
    requireVideo
    requireAudio
  }
`)

const TEST_LAYOUT_QUERY_DOCUMENT = gql(`
  query OrgLayoutTest($testId: ID!) {
    test(testId: $testId) {
      ...Test_OrgTestLayout
    }
  }
`)
