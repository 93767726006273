import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { QuestionsEmpty } from "~/tests/QuestionsEmpty"
import { QuestionsList } from "~/tests/QuestionsList"
import { LinkButton } from "~/shadcn/ui/button"
import { Wand2Icon } from "lucide-react"
import { organizationTestAIWizardPath } from "~/common/paths"

export const OrganizationTestQuestionsScreen = () => {
  const { test, testId } = useOrganizationTest()
  const organizationId = useOrganizationId()

  const questionGroupsResult = useQuery(QUESTION_GROUPS_QUERY_DOCUMENT, {
    variables: { testId },
  })
  const questionGroups =
    questionGroupsResult.data?.questionGroups?.edges
      .map((e) => e.node)
      .filter((e) => e.discardedAt === null) || []

  return (
    <OrganizationTestLayout
      test={test}
      heading="Question Groups"
      subhead="Start building your test by adding question groups below."
    >
      <div className="flex flex-col gap-6">
        {test && questionGroupsResult && questionGroups.length === 0 && (
          <QuestionsEmpty organizationId={organizationId} testId={testId} />
        )}
        {test && questionGroupsResult && questionGroups.length > 0 && (
          <QuestionsList
            organizationId={organizationId}
            testId={testId}
            testName={test.name || ""}
            questionGroups={questionGroups}
            showCSVImportLink
          />
        )}

        <div className="flex flex-col gap-6 rounded-lg bg-gray-F7F7F5 p-8 items-center justify-center">
          <h2 className="text-2xl text-gray-333 font-medium text-center leading-7">
            🦄 Ask The Right Questions
            <br />
            <span className="text-gray-999">Use our AI question generator</span>
          </h2>
          <p className="text-base text-gray-333 w-3/4 text-center">
            Not sure what questions you should ask to get the right candidates? 
            Tell us a bit more about the job and qualifications and our AI tests
            builder can automatically generate great options for what to ask.
          </p>
          <div>
            <LinkButton
              to={organizationTestAIWizardPath({ organizationId, testId })}
              className="flex gap-3 items-center"
            >
              <Wand2Icon size="14" />
              Get Started with AI
            </LinkButton>
          </div>
        </div>
      </div>
    </OrganizationTestLayout>
  )
}

gql(`
  fragment TestQuestionGroup on QuestionGroup {
    id
    name
    questionType
    timeLimitSeconds
    expectedTimeSeconds
    questionsCount
    discardedAt

    tags {
      id
      name
    }

    questions {
      id
      questionCopy
    }
  }
`)

const QUESTION_GROUPS_QUERY_DOCUMENT = gql(`
  query QuestionGroups($testId: ID!) {
    questionGroups(testId: $testId) {
      edges {
        node {
          ...TestQuestionGroup
        }
      }
    }
  }
`)
