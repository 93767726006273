import { cva, VariantProps } from "class-variance-authority"
import React from "react"
import { cn } from "~/common/shadcn-utils"

const cardVariants = cva("flex flex-col border border-gray-E6E6E3 rounded-lg", {
  variants: {
    variant: {
      default: "divide-y",
      panel: "bg-gray-F7F7F5",
    },
  },
})

interface CardProps extends VariantProps<typeof cardVariants> {
  children: React.ReactNode
  className?: string
}

export const Card = ({ children, className, variant }: CardProps) => {
  return (
    <div className={cn(cardVariants({ variant, className }))}>{children}</div>
  )
}

export const CardSegment = ({
  label,
  children,
}: {
  label: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <div className="p-4 grid grid-cols-4">
      <div className="col-span-1 text-gray-999 text-sm font-medium">
        {label}
      </div>
      <div className="col-span-3 text-gray-333">{children}</div>
    </div>
  )
}

export const CardSegmentStacked = ({
  label,
  children,
}: {
  label: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <div className="p-4">
      <div className="text-gray-999 text-sm font-medium">{label}</div>
      <div className="text-gray-333">{children}</div>
    </div>
  )
}
