import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { AIWizard } from "~/tests/AIWizard"
import { useNavigate } from "react-router-dom"
import { useCallback } from "react"
import { organizationTestQuestionsPath } from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"

export const OrganizationTestAIWizardScreen = () => {
  const organizationId = useOrganizationId()
  const { test } = useOrganizationTest()
  const navigate = useNavigate()

  const onDone = useCallback(() => {
    navigate(
      organizationTestQuestionsPath({ testId: test?.id || "", organizationId })
    )
  }, [navigate, test, organizationId])

  return (
    <OrganizationTestLayout
      test={test}
      heading="AI Test Wizard"
      subhead="Answer some questions and let AI generate your test for you!"
    >
      {test && <AIWizard test={test} onDone={onDone} />}
    </OrganizationTestLayout>
  )
}
