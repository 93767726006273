import { RecordingProvider } from "~/av/RecordingProvider"
import { LoadingIndicator } from "~/components/LoadingIndicator"
import { PhoneVerification } from "~/testFlow/PhoneVerification"
import { TestAudioVideoSetup } from "~/testFlow/TestAudioVideoSetup"
import { TestComplete } from "~/testFlow/TestComplete"
import { TestDisclaimers } from "~/testFlow/TestDisclaimers"
import {
  TestFlowProvider,
  TestUiStateEnum,
  useTestFlow,
} from "~/testFlow/TestFlowProvider"
import { TestFlowQuestionForm } from "~/testFlow/TestFlowQuestionForm"

export const CandidateTestContinueScreen = () => {
  return (
    <RecordingProvider>
      <TestFlowProvider>
        <CandidateTestContinueScreenInner />
      </TestFlowProvider>
    </RecordingProvider>
  )
}

export const CandidateTestContinueScreenInner = () => {
  const { testUiState } = useTestFlow()

  if (testUiState === TestUiStateEnum.Loading) {
    return (
      <div className="mt-8 flex flex-1 items-center justify-center w-full">
        <LoadingIndicator />
      </div>
    )
  } else if (testUiState === TestUiStateEnum.Disclaimers) {
    return <TestDisclaimers />
  } else if (testUiState === TestUiStateEnum.AvSetup) {
    return <TestAudioVideoSetup />
  } else if (testUiState === TestUiStateEnum.AnsweringQuestion) {
    return <TestFlowQuestionForm />
  } else if (testUiState === TestUiStateEnum.PhoneVerification) {
    return <PhoneVerification />
  } else if (testUiState === TestUiStateEnum.TestComplete) {
    return <TestComplete />
  }
}
