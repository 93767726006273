import invariant from "tiny-invariant"

export const getMetaVar = (name: string) => {
  const metaTag = document.querySelector(`meta[name=${name}]`)
  invariant(metaTag, `couldn't find meta tag, ${name}`)
  const content = metaTag.getAttribute("content")
  invariant(content, `meta tag had no content: ${name}`)

  return content
}

export const maybeGetMetaVar = (name: string) => {
  const metaTag = document.querySelector(`meta[name=${name}]`)
  invariant(metaTag, `couldn't find meta tag, ${name}`)

  const content = metaTag.getAttribute("content")

  return content
}
