import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import {
  organizationQuestionResponsePath,
  organizationTestResponsesPath,
} from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import {
  CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT,
  TestQuestionResponsesList,
} from "~/tests/TestQuestionResponsesList"
import { Navigation } from "~/organizations/Navigation"

export const OrganizationTestResponseScreen = () => {
  const { test } = useOrganizationTest()
  const { candidateTestId } = useParams()
  invariant(candidateTestId)

  const { loading, data, error } = useQuery(
    CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT,
    {
      variables: { candidateTestId },
    }
  )

  const organizationId = useOrganizationId()
  const testId = test?.id || ""

  return (
    <CandidateTestLayout
      test={test}
      candidateTest={data?.candidateTest}
      backLabel="Responses"
      backPath={organizationTestResponsesPath({ organizationId, testId })}
      navigation={
        <Navigation organizationId={organizationId} testId={testId} />
      }
    >
      {error ? <Error message="Error loading responses." /> : null}
      {loading ? (
        <LoadingIndicatorCentered />
      ) : data && data.candidateTestQuestionResponses.nodes.length > 0 ? (
        <TestQuestionResponsesList
          data={data.candidateTestQuestionResponses.nodes}
          viewPath={(response) =>
            organizationQuestionResponsePath({
              organizationId,
              testId,
              questionResponseId: response.id,
            })
          }
        />
      ) : (
        <EmptyData>No responses yet.</EmptyData>
      )}
    </CandidateTestLayout>
  )
}
