import { cn } from "~/common/shadcn-utils"
import { SimpleTooltip } from "./SimpleTooltip"
import { formatPercentage } from "~/common/numbers"

export const Score = ({ value }: { value: number }) => {
  return (
    <SimpleTooltip content={formatPercentage(value)}>
      <div className="grid grid-cols-3 gap-1 max-w-16 py-2">
        <div
          className={cn(
            "h-1 rounded-full w-full",
            value < 50
              ? "bg-error"
              : value < 80
              ? "bg-yellow-500"
              : "bg-success"
          )}
        />
        <div
          className={cn(
            "h-1 rounded-full w-full",
            value < 50
              ? "bg-transparent"
              : value < 80
              ? "bg-yellow-500"
              : "bg-success"
          )}
        />
        <div
          className={cn(
            "h-1 rounded-full w-full",
            value < 80 ? "bg-transparent" : "bg-success"
          )}
        />
      </div>
    </SimpleTooltip>
  )
}
