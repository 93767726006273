import { PageHeader } from "~/ui/PageHeader"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { User_AdminCandidatesTableFragment } from "~/__generated__/graphql"

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { formatDateAndTime } from "~/common/dates"
import { LinkButton } from "~/shadcn/ui/button"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"
import { adminCandidatePath } from "~/common/paths"

const PER_PAGE = 20

const CandidatesTable = ({
  users,
}: {
  users: User_AdminCandidatesTableFragment[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead className="text-right">Created Date</TableHead>
          <TableHead className="text-right">Email</TableHead>
          <TableHead className="text-right">Phone</TableHead>
          <TableHead className="text-right">Tests Taken</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {users.map((user) => (
          <TableRow
            key={user.id}
            navigationPath={adminCandidatePath({ userId: user.id })}
          >
            <TableCell>{user.name}</TableCell>
            <TableCell className="text-right">
              {formatDateAndTime(user.createdAt)}
            </TableCell>
            <TableCell className="text-right">{user.email}</TableCell>
            <TableCell className="text-right">{user.phone}</TableCell>
            <TableCell className="text-right">
              {user.candidateTestsCount}
            </TableCell>
            <TableCell className="text-right">
              <LinkButton to={adminCandidatePath({ userId: user.id })}>
                View
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const AdminCandidatesScreen = () => {
  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { data, error, loading } = useQuery(CANDIDATES_QUERY_DOCUMENT, {
    variables: { first: PER_PAGE, after },
  })
  const users = data?.adminCandidates?.edges.map((e) => e.node) || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading candidates." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader title="Candidates" />
      {users.length > 0 ? (
        <CandidatesTable users={users} />
      ) : (
        <div>No candidates yet.</div>
      )}

      {data.adminCandidates.pageCount > 1 && (
        <Pagination
          page={page}
          pageCount={data.adminCandidates.pageCount}
          paginate={paginate}
        />
      )}
    </div>
  )
}

gql(`
  fragment User_AdminCandidatesTable on User {
    id
    name
    email
    phone
    createdAt
    candidateTestsCount
  }
`)

const CANDIDATES_QUERY_DOCUMENT = gql(`
  query AdminCandidates($first: Int!, $after: String) {
    adminCandidates(first: $first, after: $after) {
      totalCount
      pageCount(first: $first)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...User_AdminCandidatesTable
        }
      }
    }
  }
`)
