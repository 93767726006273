export const secondsToMMSS = (value?: number | null) => {
  if (value === null || value === undefined) return null

  const minutes = Math.floor(value / 60)
  const seconds = Math.floor(value % 60)

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`
}
