import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"
import camelCase from "lodash.camelcase"
import capitalize from "lodash.capitalize"
import { UseFormSetError } from "react-hook-form"
import { toast } from "react-hot-toast"

const attributeDisplayName = (name: string) =>
  capitalize(name.replace("_", " "))

const showFieldErrors = (
  key: string,
  errors: string[],
  setFieldError: UseFormSetError<any>
) => {
  console.log(key, errors)
  const errorMessages = errors?.map(
    (error) => `${attributeDisplayName(key)} ${error}`
  )
  if (errorMessages?.length) {
    setFieldError(camelCase(key), { message: `${errorMessages.join(", ")}` })
  }
}

const isApolloError = (
  t: readonly GraphQLError[] | ApolloError
): t is ApolloError => {
  return (t as ApolloError).graphQLErrors !== undefined
}

export const getGraphqlErrors = (
  _errors?: readonly GraphQLError[] | ApolloError
) => {
  if (!_errors) return
  let errors: GraphQLError[] = []

  if (isApolloError(_errors)) {
    errors = [..._errors.graphQLErrors]
  } else {
    errors = [..._errors]
  }

  return errors
}

export const displayErrors = (
  _errors?: readonly GraphQLError[] | ApolloError,
  setFieldError?: UseFormSetError<any>
) => {
  if (!_errors) return
  const errors = getGraphqlErrors(_errors)

  const validationErrors = errors?.find(
    (error) => error.extensions?.code === "VALIDATION_ERROR"
  )

  if (validationErrors) {
    toast.error(
      validationErrors.message || "An error has occurred, please try again"
    )

    if (setFieldError) {
      const fields = Object.keys(validationErrors.extensions).filter(
        (key) => key !== "code"
      )
      fields.forEach((key) => {
        const errors = validationErrors.extensions[key] as string[]
        showFieldErrors(key, errors, setFieldError)
      })
    }
  } else if (isApolloError(_errors)) {
    toast.error(_errors.message || "An error has occurred, please try again")
  }
}
