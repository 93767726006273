import { cn } from "~/common/shadcn-utils"
import React, { ReactNode, forwardRef } from "react"
import { useNavigate } from "react-router-dom"

const Table = ({
  className,
  containerClassName,
  children,
}: {
  className?: string
  containerClassName?: string
  children: ReactNode
}) => (
  <div
    className={cn(
      "border border-gray-E6E6E3 rounded-lg bg-white",
      containerClassName
    )}
  >
    <table className={cn("min-w-full divide-y divide-gray-E6E6E3", className)}>
      {children}
    </table>
  </div>
)

const TableHeader = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => (
  <thead className={cn("text-gray-333 text-sm md:text-base", className)}>
    {children}
  </thead>
)

const TableHead = ({
  className,
  colspan,
  children,
}: {
  className?: string
  colspan?: string
  children: ReactNode
}) => (
  <th
    className={cn(
      "text-left font-medium first:pl-8 last:pr-8 p-4 truncate",
      className
    )}
    {...(colspan ? { colspan } : {})}
  >
    {children}
  </th>
)

const TableBody = forwardRef<
  HTMLTableSectionElement,
  {
    className?: string
    children: ReactNode
  }
>(({ className, children }, ref) => (
  <tbody
    ref={ref}
    className={cn(
      "text-gray-333 text-xs md:text-sm font-medium divide-y divide-gray-E6E6E3",
      className
    )}
  >
    {children}
  </tbody>
))

const TableFooter = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => <tfoot className={cn("", className)}>{children}</tfoot>

const TableRow = forwardRef<
  HTMLTableRowElement,
  {
    className?: string
    onClick?: React.MouseEventHandler<HTMLTableRowElement>
    navigationPath?: string
    children: ReactNode
  }
>(({ className, onClick, navigationPath, children, ...props }, ref) => {
  const navigate = useNavigate()

  const onRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    let el = event.target as HTMLElement
    let handled = false
    while (el !== event.currentTarget) {
      if (el.onclick) {
        handled = true
        break
      }
      el = el.parentElement as HTMLElement
    }
    if (!handled) {
      navigate(navigationPath as string)
    }
  }

  let internalClasses = cn("hover:bg-gray-F7F7F5", className)
  if (navigationPath) {
    internalClasses = cn("cursor-pointer", internalClasses)
  }

  return (
    <tr
      className={internalClasses}
      ref={ref}
      onClick={navigationPath ? onRowClick : onClick}
      {...props}
    >
      {children}
    </tr>
  )
})

const TableCell = ({
  className,
  children,
}: {
  className?: string
  children?: ReactNode
}) => <td className={cn("first:pl-8 last:pr-8 p-4", className)}>{children}</td>

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableRow,
  TableHead,
  TableCell,
}
