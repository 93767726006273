import React from "react"
import { HelpCircle } from "lucide-react"
import { SimpleTooltip } from "./SimpleTooltip"
import { cn } from "~/common/shadcn-utils"

type HelpTooltipProps = {
  className?: string
  align?: "start" | "center" | "end" | undefined
  children: React.ReactNode | string
}

export const HelpTooltip = ({
  className,
  children,
  align,
}: HelpTooltipProps) => (
  <SimpleTooltip
    content={
      <div className={cn("max-w-64 text-gray-333", className)}>{children}</div>
    }
    align={align}
  >
    <HelpCircle size={14} />
  </SimpleTooltip>
)
