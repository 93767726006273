export const CookiePolicyScreen = () => (
  <div className="max-w-screen-md mx-auto flex flex-col gap-4 py-16">
    <h1 className="text-2xl font-bold">Cookie Policy</h1>
    <h2 className="font-bold">Introduction</h2>
    <p>
      Standard Aptitude ("Company", "we", "our", "us") uses cookies on our
      website [
      <a href="https://www.standardaptitude.com">www.standardaptitude.com</a>]
      (the "Site"). By using the Site, you consent to the use of cookies in
      accordance with this Cookie Policy.
    </p>
    <h2 className="font-bold">What Are Cookies?</h2>
    <p>
      Cookies are small pieces of text sent to your web browser by a website you
      visit. A cookie file is stored in your web browser and allows the Site or
      a third-party to recognize you and make your next visit easier and the
      Site more useful to you.
    </p>
    <h2 className="font-bold">How We Use Cookies</h2>
    <p>
      We use cookies to track the activity on our Site and hold certain
      information. The types of cookies we use may include:
    </p>
    <ol className="list-decimal list-outside ml-10">
      <li>Session Cookies: We use Session Cookies to operate our Site.</li>
      <li>
        Preference Cookies: We use Preference Cookies to remember your
        preferences and various settings.
      </li>
      <li>Security Cookies: We use Security Cookies for security purposes.</li>
      <li>
        Identity Cookies: We use Identity Cookies we to confirm your identity
        and as part of our solution for minimizing cheating on tests.
      </li>
    </ol>
    <h2 className="font-bold">Your Consent</h2>
    <p>
      By using our Site, you agree that we can place these types of cookies on
      your device. You also agree that we can use these cookies as we see fit,
      for any purpose related to the operation, functionality, and improvement
      of the Site, as well as for advertising and marketing purposes.
    </p>
    <h2 className="font-bold">Managing Cookies</h2>
    <p>
      If you do not wish to accept cookies from our Site, you can adjust your
      browser settings to refuse cookies or to alert you when a cookie is being
      placed on your device. You can also delete cookies that have already been
      set. However, if you disable or refuse cookies, please note that some
      parts of the Site may become inaccessible or not function properly.
    </p>
    <h2 className="font-bold">Changes to This Cookie Policy</h2>
    <p>
      We may update our Cookie Policy from time to time to reflect changes to
      our practices or for other operational, legal, or regulatory reasons. We
      will notify you of any changes by posting the new Cookie Policy on our
      Site. You are advised to review this Cookie Policy periodically for any
      changes.
    </p>
    <h2 className="font-bold">Contact Us</h2>
    <p>
      If you have any questions about this Cookie Policy, please contact us at:
    </p>
    <p>
      Standard Aptitude
      <br />
      1006 Kearny Street San Francisco CA 94110
      <br />
      cookies@lessintech.co
      <br />
    </p>
  </div>
)
