import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"

export const useCurrentOrganization = () => {
  const { currentUser } = useCurrentUserMaybe()
  const { organizationId } = useParams()

  if (organizationId) {
    return (
      currentUser?.organizations.find((o) => o.id === organizationId) || null
    )
  } else {
    return null
  }
}

export const useOrganizationId = () => {
  const { organizationId } = useParams()
  invariant(organizationId)
  return organizationId
}
