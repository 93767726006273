import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { OverviewForm } from "~/tests/OverviewForm"
import { useOrganizationTest } from "~/common/useOrganizationTest"

export const OrganizationTestOverviewScreen = () => {
  const { test, refetchTest } = useOrganizationTest()

  return (
    <OrganizationTestLayout
      test={test}
      heading="Overview"
      subhead="Fill out some information about your test."
    >
      {test ? <OverviewForm test={test} refetchTest={refetchTest} /> : null}
    </OrganizationTestLayout>
  )
}
