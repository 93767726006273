import { Test_OrgTestLayoutFragment } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { Checkbox } from "~/shadcn/ui/checkbox"

export const PromoteCheckbox = ({
  test,
}: {
  test: Test_OrgTestLayoutFragment
}) => {
  const [testUpdate, { loading }] = useSafeMutation(
    TEST_UPDATE_PROMOTE_MUTATION
  )

  const handleCheckedChange = async (value: boolean) => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          testId: test.id,
          name: test.name as string,
          promote: value,
        },
      },
    })

    if (errors) {
      toast.error("Error updating test.")
      console.log(errors)
    } else if (data?.testUpdate?.test) {
      toast.success("Test promotion has been updated.")
    } else {
      toast.error("Error updating test.")
    }
  }
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id="promote"
        onCheckedChange={handleCheckedChange}
        checked={test.promote}
        disabled={loading}
      ></Checkbox>
      <label
        htmlFor="promote"
        className="text-base font-medium text-gray-333 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Promote on samco.com
      </label>
    </div>
  )
}

const TEST_UPDATE_PROMOTE_MUTATION = gql(`
  mutation TestPromoteUpdate($input: TestUpdateInput!) {
    testUpdate(input: $input) {
      test {
        id
        promote
      }
    }
  }
`)
