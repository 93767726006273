import { ConfirmDialog } from "~/ui/Confirm"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import toast from "react-hot-toast"
import Trash from "~/images/icons/trash.svg?react"

export const DisclaimerDestroyButton = ({
  disclaimerId,
  onDestroy,
}: {
  disclaimerId: string
  onDestroy: (disclaimerId: string) => void
}) => {
  const [disclaimerDestroy, { loading: loadingDestroy }] = useSafeMutation(
    DISCLAIMER_DESTROY_MUTATION
  )

  const deleteDisclaimer = async (disclaimerId: string) => {
    console.log("Delete disclaimer", disclaimerId)
    const { data, errors } = await disclaimerDestroy({
      variables: {
        input: {
          disclaimerId: disclaimerId,
        },
      },
    })

    if (data?.disclaimerDestroy?.disclaimer) {
      toast.success("Test disclaimer deleted.")
      onDestroy(disclaimerId)
    } else {
      // Maybe add errors to toast?
      toast.error("Error deleting disclaimer.")
      console.log(errors)
    }
  }

  return (
    <ConfirmDialog
      text="Delete this disclaimer?"
      onSuccess={() => deleteDisclaimer(disclaimerId)}
    >
      <Button
        name="remove-disclaimer"
        className="px-0"
        type="button"
        variant="ghost"
        disabled={loadingDestroy}
      >
        <Trash className="h-6 w-6" />
      </Button>
    </ConfirmDialog>
  )
}

const DISCLAIMER_DESTROY_MUTATION = gql(`
  mutation DisclaimerDestroy($input: DisclaimerDestroyInput!) {
    disclaimerDestroy(input: $input) {
      disclaimer {
        id
      }
    }
  }
`)
