export const PrivacyPolicyScreen = () => (
  <div className="max-w-screen-md mx-auto flex flex-col gap-4 py-16">
    <h1 className="text-2xl font-bold">Privacy Policy</h1>
    <h2 className="font-bold">Introduction</h2>
    <p>
      Standard Aptitude ("Company", "we", "our", "us") is committed to
      protecting your privacy. This Privacy Policy explains how we collect, use,
      disclose, and safeguard your information when you visit our website [
      <a href="https://www.standardaptitude.com">www.standardaptitude.com</a>
      ], use our services, or engage with us in any other way. Please read this
      policy carefully. If you do not agree with the terms of this privacy
      policy, please do not access the site.
    </p>
    <h2 className="font-bold">Information We Collect</h2>
    <p>
      We may collect information about you in a variety of ways. The information
      we may collect includes:
    </p>
    <ol className="list-decimal list-outside ml-10">
      <li>
        <strong>Personal Data</strong>: Personally identifiable information,
        such as your name, shipping address, email address, and telephone
        number, and demographic information, such as your age, gender, hometown,
        and interests, that you voluntarily give to us when you register with
        the Site or our mobile application, or when you choose to participate in
        various activities related to the Site and our mobile application, such
        as online chat and message boards.
      </li>
      <li>
        <strong>Derivative Data</strong>: Information our servers automatically
        collect when you access the Site, such as your IP address, your browser
        type, your operating system, your access times, and the pages you have
        viewed directly before and after accessing the Site.
      </li>
      <li>
        <strong>Financial Data</strong>: Financial information, such as data
        related to your payment method (e.g., valid credit card number, card
        brand, expiration date) that we may collect when you purchase, order,
        return, exchange, or request information about our services from the
        Site or our mobile application.
      </li>
      <li>
        <strong>Service Data</strong>: Information collected in the context of
        using our services. This includes thing like test questions, test
        question responses, scoring information, and video, audio, and keystroke
        data associated with test question responses.
      </li>
    </ol>
    <h2 className="font-bold">Use of Your Information</h2>
    <p>
      Having accurate information about you permits us to provide you with a
      smooth, efficient, and customized experience. Specifically, we may use
      information collected about you via the Site or our mobile application to:
    </p>
    <ol className="list-decimal list-outside ml-10">
      <li>Create and manage your account.</li>
      <li>
        Create and administer tests &amp; capture your responses to questions
      </li>
      <li>Validate your identity for the purposes of test taking</li>
      <li>Email and text you about your account</li>
      <li>
        Increase the efficiency and operation of the Site and our mobile
        application.
      </li>
      <li>
        Monitor and analyze usage and trends to improve your experience with the
        Site and our mobile application.
      </li>
      <li>Notify you of updates to the Site and our mobile application.</li>
      <li>Offer new products, services, and/or recommendations to you.</li>
      <li>Perform other business activities as needed.</li>
    </ol>
    <h2 className="font-bold">Disclosure of Your Information</h2>
    <p>
      We may share information we have collected about you in certain
      situations. Your information may be disclosed as follows:
    </p>
    <ol className="list-decimal list-outside ml-10">
      <li>
        <strong>By Law or to Protect Rights</strong>: If we believe the release
        of information about you is necessary to respond to legal process, to
        investigate or remedy potential violations of our policies, or to
        protect the rights, property, and safety of others, we may share your
        information as permitted or required by any applicable law, rule, or
        regulation.
      </li>
      <li>
        <strong>Business Transfers</strong>: We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company.
      </li>
      <li>
        <strong>Third-Party Service Providers</strong>: We may share your
        information with third parties that perform services for us or on our
        behalf, including payment processing, data analysis, email delivery,
        hosting services, customer service, and marketing assistance.
      </li>
    </ol>
    <h2 className="font-bold">Security of Your Information</h2>
    <p>
      We use administrative, technical, and physical security measures to help
      protect your personal information. While we have taken reasonable steps to
      secure the personal information you provide to us, please be aware that
      despite our efforts, no security measures are perfect or impenetrable, and
      no method of data transmission can be guaranteed against any interception
      or other type of misuse.
    </p>
    <h2 className="font-bold">Policy for Children</h2>
    <p>
      We do not knowingly solicit information from or market to children under
      the age of 13. If we learn that we have collected personal information
      from a child under age 13 without verification of parental consent, we
      will delete that information as quickly as possible.
    </p>
    <h2 className="font-bold">Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time in order to reflect,
      for example, changes to our practices or for other operational, legal, or
      regulatory reasons. We will notify you of any changes by posting the new
      Privacy Policy on our Site. You are advised to review this Privacy Policy
      periodically for any changes.
    </p>
    <p>Contact Us</p>
    <p>
      If you have questions or comments about this Privacy Policy, please
      contact us at:
    </p>
    <p>
      Standard Aptitude
      <br />
      1006 Kearny Street San Francisco CA 94110
      <br />
      privacy@lessintech.co
      <br />
    </p>
  </div>
)
