import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import PhoneInput from "react-phone-number-input"

export const PhoneNumberField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  required,
  label,
  labelClassName,
  inputClassName,
  containerClassName,
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  containerClassName?: string
  description?: string
  required?: boolean
  control: Control<TFieldValues> | undefined
  name: TName
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={labelClassName}>
            {label}
            {required && <span className="text-red-500 pl-1">*</span>}
          </FormLabel>
          <FormControl>
            <PhoneInput
              placeholder="Enter phone number"
              value={field.value}
              displayInitialValueAsLocalNumber
              onChange={(v) => field.onChange(v || "")}
              defaultCountry="US"
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
