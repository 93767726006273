import { useTestFlow } from "./TestFlowProvider"
import { Divider } from "~/ui/Divider"
import invariant from "tiny-invariant"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { Button } from "~/shadcn/ui/button"
import { useCallback, useEffect, useRef, useState } from "react"
import PhoneInput from "react-phone-number-input"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { OTPInput, REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import { OTPSlot } from "~/screens/SignInTokenScreen"
import toast from "react-hot-toast"

export const PhoneVerification = () => {
  const currentUser = useCurrentUser()
  const [smsVerificationId, setSmsVerificationId] = useState<string | null>(
    null
  )
  const [phone, setPhone] = useState(currentUser.phone || "")

  return (
    <div className="container mx-auto pb-20">
      <div className="flex-1 flex flex-col justify-center p-6 mt-10">
        <div className="w-full sm:w-[360px] mx-auto">
          <div className="text-2xl text-gray-333 font-semibold">
            Phone Verification
          </div>
          <div className="text-md text-gray-999 mb-8">
            {smsVerificationId
              ? "Enter the code that you received."
              : "We'll send you a code via text message."}
          </div>

          {smsVerificationId ? (
            <ConfirmCodeForm
              smsVerificationId={smsVerificationId}
              setSmsVerificationId={setSmsVerificationId}
            />
          ) : (
            <SendCodeForm
              phone={phone}
              setPhone={setPhone}
              setSmsVerificationId={setSmsVerificationId}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const SendCodeForm = ({
  phone,
  setPhone,
  setSmsVerificationId,
}: {
  phone: string
  setPhone: (phone: string) => void
  setSmsVerificationId: (smsVerificationId: string) => void
}) => {
  const { candidateTest } = useTestFlow()

  const [smsVerificationCreate, { loading }] = useSafeMutation(
    VERIFICATION_CREATE_MUTATION
  )

  const onSubmit = async () => {
    invariant(candidateTest?.id, "missing candidate test ID")

    const { data, errors } = await smsVerificationCreate({
      variables: {
        input: {
          phone,
          candidateTestId: candidateTest.id,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      invariant(
        data?.smsVerificationCreate.smsVerificationId,
        "missing verification id"
      )
      setSmsVerificationId(data.smsVerificationCreate.smsVerificationId)
    }
  }

  return (
    <div>
      <div className={"text-gray-999 font-normal text-base mb-2"}>
        Phone number
        <span className="text-red-500 pl-1">*</span>
      </div>
      <PhoneInput
        placeholder="Enter phone number"
        value={phone}
        displayInitialValueAsLocalNumber
        onChange={(v) => setPhone(v || "")}
        defaultCountry="US"
      />
      <Divider className="my-10" />

      <Button
        className="btn w-full"
        type="submit"
        isLoading={loading}
        onClick={onSubmit}
      >
        Send Code
      </Button>
    </div>
  )
}

const ConfirmCodeForm = ({
  smsVerificationId,
  setSmsVerificationId,
}: {
  smsVerificationId: string
  setSmsVerificationId: (smsVerificationId: string | null) => void
}) => {
  const { phoneVerificationComplete } = useTestFlow()
  const [token, setToken] = useState("")
  const tokenLengthWasRef = useRef(0)

  const [smsVerificationConfirm, { loading }] = useSafeMutation(
    VERIFICATION_CONFIRM_MUTATION
  )

  const onSubmit = useCallback(async () => {
    const { data, errors } = await smsVerificationConfirm({
      variables: {
        input: {
          smsVerificationId,
          token,
        },
      },
    })

    if (errors) {
      setToken("")
      displayErrors(errors)
      console.log(errors)
    } else {
      invariant(data?.smsVerificationConfirm.candidateTest)

      toast.success("Phone verification complete.")
      phoneVerificationComplete(data?.smsVerificationConfirm.candidateTest)
    }
  }, [
    token,
    smsVerificationId,
    phoneVerificationComplete,
    smsVerificationConfirm,
  ])

  useEffect(() => {
    if (token.length === 6 && tokenLengthWasRef.current !== 6) {
      onSubmit()
    }
    tokenLengthWasRef.current = token.length
  }, [token, onSubmit])

  return (
    <div>
      <label className="block">
        <div className="mb-2 text-gray-999">Confirmation Code</div>
        <OTPInput
          value={token}
          onChange={(newValue: string) => setToken(newValue.toUpperCase())}
          maxLength={6}
          inputMode="text"
          containerClassName="group flex justify-between w-full"
          pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
          name="passwordless[token]"
          data-1p-ignore="true"
          render={({ slots }) => (
            <>
              {slots.map((slot, idx) => (
                <OTPSlot key={idx} {...slot} />
              ))}
            </>
          )}
        />
      </label>

      <Divider className="my-10" />

      <Button
        className="btn w-full"
        type="submit"
        isLoading={loading}
        onClick={onSubmit}
      >
        Submit & Continue
      </Button>

      <div className="text-gray-999 text-center mt-8">
        Didn't receive the code?{" "}
        <span
          className="underline cursor-pointer"
          onClick={() => setSmsVerificationId(null)}
        >
          Send Again
        </span>
      </div>
    </div>
  )
}

const VERIFICATION_CREATE_MUTATION = gql(`
  mutation SmsVerificationCreate($input: SmsVerificationCreateInput!) {
    smsVerificationCreate(input: $input) {
      smsVerificationId
    }
  }
`)

const VERIFICATION_CONFIRM_MUTATION = gql(`
  mutation SmsVerificationConfirm($input: SmsVerificationConfirmInput!) {
    smsVerificationConfirm(input: $input) {
      candidateTest {
        ...TestFlow_CandidateTest
      }
    }
  }
`)
