import {
  useOrganizationId,
  useCurrentOrganization,
} from "~/common/useCurrentOrganization"
import { PageHeader } from "~/ui/PageHeader"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { Org_OrganizationUsersFragment } from "~/__generated__/graphql"
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { OrganizationUserCreateButton } from "~/organizations/OrganizationUserCreateButton"
import { OrganizationUserDestroyButton } from "~/organizations/OrganizationUserDestroyButton"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"

const OrganizationUsersTable = ({
  data,
}: {
  data: Org_OrganizationUsersFragment[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Email</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((orgUser) => (
          <TableRow key={orgUser.id}>
            <TableCell>{orgUser.user.email}</TableCell>
            <TableCell className="flex flex-row items-center gap-2 justify-end">
              <OrganizationUserDestroyButton
                organizationUserId={orgUser.id}
                email={orgUser.user.email}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const PER_PAGE = 10

export const OrganizationAdminsScreen = () => {
  const organizationId = useOrganizationId()
  const organization = useCurrentOrganization()

  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { loading, data, error, refetch } = useQuery(
    ORGANIZATION_USERS_QUERY_DOCUMENT,
    {
      variables: { organizationId: organizationId, first: PER_PAGE, after },
    }
  )

  const handleRefetch = () => {
    refetch()
  }

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading tests." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title={`${organization?.name} Users`}
        subhead="Add or manage company members."
      >
        <div>
          <OrganizationUserCreateButton
            organizationId={organizationId}
            onSuccess={handleRefetch}
          />
        </div>
      </PageHeader>
      {data.organizationUsers ? (
        <>
          <OrganizationUsersTable data={data.organizationUsers.nodes} />
          {data.organizationUsers.pageCount &&
          data.organizationUsers.pageCount > 1 ? (
            <Pagination
              page={page}
              pageCount={data.organizationUsers.pageCount}
              paginate={paginate}
            />
          ) : null}
        </>
      ) : null}
    </div>
  )
}

gql(`
  fragment Org_OrganizationUsers on OrganizationUser {
    id
    user {
      id
      email
    }
  }
`)

const ORGANIZATION_USERS_QUERY_DOCUMENT = gql(`
  query OrganizationUsers($organizationId: ID!, $first: Int!, $after: String) {
    organizationUsers(organizationId: $organizationId, first: $first, after: $after) {
      pageCount(first: $first)
      nodes {
        ...Org_OrganizationUsers
      }
    }
  }
`)
