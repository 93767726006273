import { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getMetaVar } from "~/common/getMetaVar"
import { signInPath, tokenConfirmationPath } from "~/common/paths"
import { Button } from "~/shadcn/ui/button"
import { OTPInput, SlotProps, REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import { cn } from "~/common/shadcn-utils"

export const OTPSlot = (props: SlotProps) => {
  return (
    <div
      className={cn(
        "relative w-[50px] h-[50px] text-xl",
        "flex items-center justify-center",
        "border-[1px] border-gray-E3E2E0 rounded-lg",
        { "border-primary": props.isActive }
      )}
    >
      {props.char !== null && <div>{props.char}</div>}
    </div>
  )
}

export const SignInTokenScreen = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const [token, setToken] = useState("")
  const { sessionId } = useParams()
  invariant(sessionId)

  const csrfToken = getMetaVar("csrf-token")

  useEffect(() => {
    if (token.length === 6) {
      formRef.current?.submit()
    }
  }, [token.length])

  return (
    <div className="flex-1 flex flex-col justify-center p-6">
      <div className="w-full sm:w-[360px] mx-auto">
        <div className="text-2xl text-gray-333 font-semibold">
          Magic Link Sent
        </div>
        <div className="text-2xl text-gray-999 font-semibold mb-8">
          Click the link or enter the code sent to your email to login
        </div>
        <form
          className="my-8"
          method="POST"
          action={tokenConfirmationPath({ id: sessionId })}
          ref={formRef}
        >
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={csrfToken} />

          <label className="block">
            <div className="mb-2 text-gray-999">Confirmation Code</div>
            <OTPInput
              value={token}
              onChange={(newValue: string) => setToken(newValue.toUpperCase())}
              maxLength={6}
              inputMode="text"
              containerClassName="group flex justify-between w-full"
              pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
              name="passwordless[token]"
              data-1p-ignore="true"
              render={({ slots }) => (
                <>
                  {slots.map((slot, idx) => (
                    <OTPSlot key={idx} {...slot} />
                  ))}
                </>
              )}
            />
          </label>

          <div className="h-[1px] my-8 bg-gray-E3E2E0" />

          <Button
            className="btn w-full"
            disabled={token.length === 0}
            type="submit"
          >
            Confirm Code
          </Button>
        </form>

        <div className="text-gray-999 text-center">
          Didn't receive the code?{" "}
          <Link className="underline" to={signInPath.pattern}>
            Send Again
          </Link>
        </div>
      </div>
    </div>
  )
}
