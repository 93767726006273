import { useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import { Divider } from "~/ui/Divider"
import { useTestFlow } from "./TestFlowProvider"
import { ContinueOrSaveBar } from "./ContinueOrSaveBar"

export const TextResponseForm = ({ timeExpired }: { timeExpired: boolean }) => {
  const { answerQuestion } = useTestFlow()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [response, setResponse] = useState("")

  const onClick = async () => {
    setLoading(true)
    if (response.length === 0) {
      setError(true)
    } else {
      setError(false)
      await answerQuestion({ textResponse: response })
    }
    setLoading(false)
  }

  return (
    <div>
      <div className="mb-4">Answer</div>
      <textarea
        className={cn("w-full border-1 border-gray-E3E2E0 rounded h-40")}
        value={response}
        onChange={(e) => {
          setResponse(e.target.value)
          setError(false)
        }}
        disabled={timeExpired}
      />
      {error && <div className="text-red-500">Please enter a response</div>}

      <Divider className="my-10" />

      <ContinueOrSaveBar>
        <Button isLoading={loading} disabled={loading} onClick={onClick}>
          Continue
        </Button>
      </ContinueOrSaveBar>
    </div>
  )
}
