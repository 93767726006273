import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import {
  organizationQuestionGroupEditPath,
  organizationQuestionGroupCreatePath,
  organizationTestQuestionImportPath,
  organizationTestPreviewPath,
  questionsExportPath,
} from "~/common/paths"
import { LinkButton } from "~/shadcn/ui/button"
import {
  TestQuestionGroupFragment,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import MoveHandleIcon from "~/images/icons/move-handle.svg?react"
import PlusCircleIcon from "~/images/icons/plus-circle.svg?react"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"
import { ArchiveQuestionGroupButton } from "~/questions/ArchiveQuestionGroupButton"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from "~/shadcn/ui/tooltip"
import { Download, Upload } from "lucide-react"
import { humanizeSeconds } from "~/common/dates"
import { List, arrayMove } from "react-movable"
import { useEffect, useState } from "react"

const questionTypeLabels = {
  [QuestionTypeEnum.MultipleChoice]: "Multiple Choice",
  [QuestionTypeEnum.SingleChoice]: "Single Choice",
  [QuestionTypeEnum.TextResponse]: "Text Input",
  [QuestionTypeEnum.VideoResponse]: "Audio & Video",
}

const TagsList = ({
  tags,
}: {
  tags: {
    __typename?: "Tag" | undefined
    id: string
    name: string
  }[]
}) => {
  if (tags.length === 0) {
    return <div></div>
  }

  const firstTag = tags[0]
  const otherTags = tags.slice(1)

  return (
    <div className="flex flex-row gap-2">
      <div className="text-xs text-primary bg-[#EBF3FB] border border-primary/50 p-2 rounded [overflow-wrap:normal]">
        {firstTag.name}
      </div>
      {otherTags.length > 0 && (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="text-xs text-primary bg-[#EBF3FB] border border-primary/50 p-2 rounded">
                +{otherTags.length}
              </div>
            </TooltipTrigger>
            <TooltipContent side="bottom">
              <TooltipArrow className="bg-gray-F7F7F5" />
              {otherTags.map((tag) => (
                <div key={tag.id} className="text-xs text-gray-333 p-1.5">
                  {tag.name}
                </div>
              ))}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  )
}

export const QuestionsList = ({
  testId,
  testName,
  organizationId,
  questionGroups: _questionGroups,
  showCSVImportLink,
}: {
  testId: string
  testName: string
  organizationId: string
  questionGroups: TestQuestionGroupFragment[]
  showCSVImportLink?: boolean
}) => {
  const [questionGroups, setQuestionGroups] = useState<
    TestQuestionGroupFragment[]
  >([])

  useEffect(() => {
    setQuestionGroups(_questionGroups)
  }, [_questionGroups])

  const approxSeconds = questionGroups.reduce((val, questionGroup) => {
    return val + (questionGroup.expectedTimeSeconds || 0)
  }, 0)

  const [movePositions, { loading }] = useSafeMutation(
    QUESTION_GROUP_POSITION_MOVE_MUTATION
  )
  const handleMovePositions = async (draggedId: string, droppedId: string) => {
    const { data, errors } = await movePositions({
      variables: {
        input: {
          testId,
          questionGroupIdHave: draggedId,
          questionGroupIdWant: droppedId,
        },
      },
    })
    if (errors) {
      toast.error("Error reordering.")
      console.log(errors)
    } else if (!data?.questionGroupPositionMove?.test) {
      toast.error("Error reordering.")
    }
  }

  const placeholder = document.createElement("tr")
  placeholder.innerHTML = "<td class='' colspan='6'></td>"
  placeholder.classList.add("bg-[#3783DB]", "h-1")

  return (
    <div className="flex flex-col gap-8">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="test-base text-gray-333">{testName}</h1>
          <p className="text-sm text-gray-999">
            Start building your test by adding question groups below.
          </p>
        </div>
        <div className="flex flex-row gap-6">
          <div className="flex flex-col">
            <p className="text-sm text-gray-999 text-right">
              Approximate Test Length
            </p>
            <p className="text-base text-gray-333 text-right">
              {humanizeSeconds(approxSeconds)}
            </p>
          </div>
          <div>
            <LinkButton
              to={organizationQuestionGroupCreatePath({
                organizationId,
                testId,
              })}
            >
              <PlusCircleIcon className="w-6 h-6 mr-2" />
              Add
            </LinkButton>
          </div>
        </div>
      </div>
      <List
        disabled={loading}
        values={questionGroups}
        onChange={({ oldIndex, newIndex }) => {
          handleMovePositions(
            questionGroups[oldIndex].id,
            questionGroups[newIndex].id
          )
          setQuestionGroups(arrayMove(questionGroups, oldIndex, newIndex))
        }}
        renderList={({ children, props }) => (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>&nbsp;</TableHead>
                <TableHead>Question Group</TableHead>
                <TableHead>Tags</TableHead>
                <TableHead>Type</TableHead>
                <TableHead className="text-right">Time Limit</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody {...props}>{children}</TableBody>
          </Table>
        )}
        renderItem={({ value: questionGroup, props, isDragged }) => {
          const row = (
            <TableRow
              {...(isDragged ? {} : props)}
              data-id={questionGroup.id}
              className="text-gray-333"
              navigationPath={organizationQuestionGroupEditPath({
                questionGroupId: questionGroup.id,
                testId,
                organizationId,
              })}
            >
              <TableCell className="cursor-move w-[70px]">
                <MoveHandleIcon
                  className="h-4 w-4 text-gray-333 cursor-move"
                  data-movable-handle
                />
              </TableCell>
              <TableCell className="w-[400px] max-w-[400px]">
                <div className="text-base">{questionGroup.name}</div>
                {questionGroup.questions.map((question) => (
                  <div className="text-sm text-gray-999" key={question.id}>
                    {question.questionCopy}
                  </div>
                ))}
              </TableCell>
              <TableCell className="align-baseline">
                <TagsList tags={questionGroup.tags} />
              </TableCell>
              <TableCell className="align-baseline test-sm">
                {questionTypeLabels[questionGroup.questionType]}
              </TableCell>
              <TableCell className="align-baseline text-right test-sm">
                {!!questionGroup.timeLimitSeconds &&
                  questionGroup.timeLimitSeconds > 0 && (
                    <>{humanizeSeconds(questionGroup.timeLimitSeconds)}</>
                  )}
              </TableCell>
              <TableCell className="align-baseline">
                <div className="flex flex-row items-center gap-2 justify-end test-sm">
                  <LinkButton
                    size="sm"
                    variant="outline"
                    to={organizationQuestionGroupEditPath({
                      questionGroupId: questionGroup.id,
                      testId,
                      organizationId,
                    })}
                  >
                    Edit
                  </LinkButton>
                  <ArchiveQuestionGroupButton
                    questionGroupId={questionGroup.id}
                    questionGroupName={questionGroup.name}
                  />
                </div>
              </TableCell>
            </TableRow>
          )

          return isDragged ? (
            <table {...props}>
              <tbody>{row}</tbody>
            </table>
          ) : (
            row
          )
        }}
      />
      <div className="w-full h-[1px] my-4 bg-gray-E3E2E0" />
      <div className="flex justify-start items-center gap-2 pb-3">
        <LinkButton
          to={organizationTestPreviewPath({
            organizationId: organizationId,
            testId: testId,
          })}
          variant="outline"
        >
          Preview Test
        </LinkButton>

        <LinkButton
          to={organizationTestQuestionImportPath({ organizationId, testId })}
          variant="ghost"
          className="ml-auto"
        >
          <Upload size={16} className="mr-2" />
          Import Questions in CSV format
        </LinkButton>
        <LinkButton
          to={questionsExportPath({ testId })}
          target="_blank"
          variant="ghost"
          className="ml-4"
        >
          <Download size={16} className="mr-2" />
          Download CSV
        </LinkButton>
      </div>
    </div>
  )
}

const QUESTION_GROUP_POSITION_MOVE_MUTATION = gql(`
  mutation QuestionGroupPositionMove($input: QuestionGroupPositionMoveInput!) {
    questionGroupPositionMove(input: $input) {
      test {
        id
        questionGroups {
          id
          position
        }
      }
    }
  }
`)
