import { PageHeader } from "~/ui/PageHeader"
import newTestImage from "~/images/new-test.svg"
import { LinkButton } from "~/shadcn/ui/button"
import {
  organizationCreateTestPath,
  organizationTestOverviewPath,
  organizationTestResponsesPath,
} from "~/common/paths"
import { ChevronRight } from "lucide-react"
import { gql } from "~/__generated__"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import invariant from "tiny-invariant"
import {
  Test_OrgTestTableFragment,
  TestAccessEnum,
} from "~/__generated__/graphql"

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { formatDateAndTime } from "~/common/dates"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"
import { PublishStatusToggle } from "~/tests/PublishStatusUI"
import { Link } from "react-router-dom"

const accessDisplayText = {
  [TestAccessEnum.Public]: "Public",
  [TestAccessEnum.PrivateUrl]: "Shared",
  [TestAccessEnum.EmailInvitation]: "Invite Only",
}

const TestTable = ({
  organizationId,
  tests,
}: {
  organizationId: string
  tests: Test_OrgTestTableFragment[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Test Name</TableHead>
          <TableHead className="text-center">Published</TableHead>
          <TableHead className="text-right">Access</TableHead>
          <TableHead className="text-right">Questions</TableHead>
          <TableHead className="text-right">Responses</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tests.map((test) => (
          <TableRow
            key={test.id}
            navigationPath={organizationTestOverviewPath({
              organizationId,
              testId: test.id,
            })}
          >
            <TableCell>
              <div className="text-base">{test.name}</div>
              <div className="text-gray-999">
                Edited: {formatDateAndTime(test.updatedAt)}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-row items-center">
                <PublishStatusToggle test={test} />
              </div>
            </TableCell>
            <TableCell className="text-right">
              {accessDisplayText[test.access]}
            </TableCell>
            <TableCell className="text-right">
              {test.questionGroupsCount}
            </TableCell>
            <TableCell className="text-right">
              <Link
                to={organizationTestResponsesPath({
                  organizationId,
                  testId: test.id,
                })}
                className="text-blue-600 flex flex-col items-stretch py-3"
              >
                {test.completedCandidateTestsCount}
              </Link>
            </TableCell>
            <TableCell className="flex flex-row items-center gap-2 justify-end">
              <LinkButton
                to={organizationTestOverviewPath({
                  organizationId,
                  testId: test.id,
                })}
              >
                View / Edit
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const PER_PAGE = 10

export const OrganizationTestsScreen = () => {
  const { organizationId } = useParams()
  invariant(organizationId)

  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { loading, data, error } = useQuery(TESTS_QUERY_DOCUMENT, {
    variables: { organizationId, first: PER_PAGE, after },
  })
  const tests =
    data?.tests?.edges
      .map((e) => e.node)
      .filter((e) => e.discardedAt === null) || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading tests." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title="Tests"
        subhead="Tests I have created or am working on."
      />
      {tests.length > 0 && (
        <>
          <TestTable organizationId={organizationId} tests={tests}></TestTable>
          {data.tests && data.tests.pageCount > 1 ? (
            <Pagination
              page={page}
              pageCount={data.tests.pageCount}
              paginate={paginate}
            />
          ) : null}
        </>
      )}
      <div className="bg-gray-F7F7F5 p-8 flex items-center rounded">
        <div className="flex-1 pr-10 lg:pr-20">
          <div className="text-2xl font-semibold text-gray-333 ">
            Create a new test.
          </div>
          <div className="text-2xl font-semibold text-gray-999 mb-1">
            Get started building a test below
          </div>
          <div className="mb-4">
            Use a test to evaluate candidates for a new role in your
            organization.  Find the best candidates based on what they know and
            how they think.
          </div>
          <LinkButton to={organizationCreateTestPath({ organizationId })}>
            Create new test <ChevronRight className="ml-2" size={16} />
          </LinkButton>
        </div>
        <img src={newTestImage} alt="create a test" className="w-[233px]" />
      </div>
    </div>
  )
}

gql(`
  fragment Test_OrgTestTable on Test {
    id
    name
    access
    publishStatus
    questionGroupsCount
    completedCandidateTestsCount
    updatedAt
    discardedAt
  }
`)

const TESTS_QUERY_DOCUMENT = gql(`
  query OrgTests($organizationId: ID!, $first: Int!, $after: String) {
    tests(organizationId: $organizationId, first: $first, after: $after) {
      pageCount(first: $first)
      edges {
        node {
          ...Test_OrgTestTable
        }
      }
    }
  }
`)
