import { createContext, useContext } from "react"
import {
  QuestionSuggestion,
  Test_OrgTestLayoutFragment,
} from "~/__generated__/graphql"
import { PromptsFormValues } from "./PromptsForm"
import invariant from "tiny-invariant"

interface AIWizardContextValues {
  test: Test_OrgTestLayoutFragment
  currentStep: number
  setCurrentStep: (step: number) => void
  prompts: PromptsFormValues | undefined
  setPrompts: (prompts: PromptsFormValues) => void
  loading: boolean
  reset: () => void
  suggestions: QuestionSuggestion[]
  setSuggestions: (suggestions: QuestionSuggestion[]) => void
  addSelectedSuggestions: (suggestions: QuestionSuggestion[]) => void
  generateCSV: () => void
}

const AIWizardContext = createContext<AIWizardContextValues | undefined>(
  undefined
)

interface AIWizardProviderProps extends AIWizardContextValues {
  children: React.ReactNode
}

export const AIWizardProvider = ({
  children,
  ...props
}: AIWizardProviderProps) => {
  return (
    <AIWizardContext.Provider value={props}>
      {children}
    </AIWizardContext.Provider>
  )
}

export const useAIWizard = () => {
  const context = useContext(AIWizardContext)
  invariant(context, "useAIWizard must be used within a AIWizardProvider")
  return context
}
