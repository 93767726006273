import { LinkButton } from "~/shadcn/ui/button"
import {
  organizationQuestionGroupCreatePath,
  organizationTestQuestionImportPath,
} from "~/common/paths"
import PlusCircleIcon from "~/images/icons/plus-circle.svg?react"
import { Link } from "react-router-dom"

export const QuestionsEmpty = ({
  organizationId,
  testId,
}: {
  organizationId: string
  testId: string
}) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-gray-F7F7F5 p-8 items-center justify-center">
      <h2 className="text-2xl text-gray-333 font-medium">
        Start Adding Questions
      </h2>
      <p className="text-base text-gray-333 max-w-sm text-center">
        Add questions to your test to evaluate people for your role.
      </p>
      <div>
        <LinkButton
          to={organizationQuestionGroupCreatePath({ organizationId, testId })}
        >
          <PlusCircleIcon className="w-6 h-6 mr-2" />
          Add Question
        </LinkButton>
      </div>

      <Link
        className="text-gray-999 underline mt-6"
        to={organizationTestQuestionImportPath({ organizationId, testId })}
      >
        Import Questions in CSV format
      </Link>
    </div>
  )
}
