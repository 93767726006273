import { useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import { Divider } from "~/ui/Divider"
import { useTestFlow } from "./TestFlowProvider"
import { StandaloneRadioButton } from "~/ui/StandaloneRadioButton"
import { ContinueOrSaveBar } from "./ContinueOrSaveBar"

export const SingleChoiceResponseForm = ({
  timeExpired,
}: {
  timeExpired: boolean
}) => {
  const { answerQuestion, currentAttempt } = useTestFlow()
  const [loading, setLoading] = useState(false)
  const [selectedChoiceId, setSelectedChoiceId] = useState<string | null>(null)

  const onClick = async () => {
    setLoading(true)
    const params = selectedChoiceId
      ? { selectedChoiceIds: [selectedChoiceId] }
      : {}
    await answerQuestion(params)
    setLoading(false)
  }

  return (
    <div>
      <div className="mb-4">Select One Option</div>

      <div className="rounded border border-gray-E6E6E3 overflow-hidden">
        {currentAttempt?.question.questionChoices.map((choice, index) => (
          <div
            key={choice.id}
            className={cn("p-6 flex items-center cursor-pointer", {
              "bg-gray-F7F7F5": choice.id === selectedChoiceId,
              "border-b border-gray-E6E6E3":
                index !== currentAttempt.question.questionChoices.length - 1,
              "cursor-not-allowed opacity-50 bg-gray-F7F7F5": timeExpired,
            })}
            onClick={() => !timeExpired && setSelectedChoiceId(choice.id)}
          >
            <StandaloneRadioButton
              value={choice.id === selectedChoiceId}
              className="mr-4"
            />
            <div>{choice.choiceCopy}</div>
          </div>
        ))}
      </div>

      <Divider className="my-10" />

      <ContinueOrSaveBar>
        <Button
          isLoading={loading}
          disabled={(!timeExpired && !selectedChoiceId) || loading}
          onClick={onClick}
        >
          Continue
        </Button>
      </ContinueOrSaveBar>
    </div>
  )
}
