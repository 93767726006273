import { useState } from "react"
import { useQuery } from "@apollo/client"
import { cn } from "~/common/shadcn-utils"
import { gql } from "~/__generated__"
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/ui/popover"
import FilterIcon from "~/images/icons/filters.svg?react"
import XIcon from "~/images/icons/x.svg?react"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { CheckedState } from "@radix-ui/react-checkbox"
import Toggle from "react-toggle"
import {
  CandidateTestStateEnum,
  CandidateTestsOrganizationFilter,
} from "~/__generated__/graphql"

export const CandidateTestsTableFilter = ({
  organizationId,
  filter,
  onFilterChange,
  allowTestFilter = true,
}: {
  organizationId: string
  filter: CandidateTestsOrganizationFilter
  onFilterChange: (filter: CandidateTestsOrganizationFilter) => void
  allowTestFilter?: boolean
}) => {
  const [open, setOpen] = useState(false)

  const { loading, data, error } = useQuery(ORGANIZATION_TESTS_QUERY_DOCUMENT, {
    variables: { organizationId },
  })

  if (error) {
    console.error("Error loading tests for filter", error)
  }

  const onStateChanged = (
    checked: CheckedState,
    state: CandidateTestStateEnum
  ) => {
    const states = [...(filter?.candidateTestStates || [])]
    if (checked) {
      states.push(state)
    } else {
      states.splice(states.indexOf(state), 1)
    }

    onFilterChange({ ...filter, candidateTestStates: states })
  }

  const onTestIdChanged = (testId: string | undefined) => {
    onFilterChange({ ...filter, testId: testId })
    setOpen(false)
  }

  const tests = data?.tests?.nodes

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-6">
        {allowTestFilter && (
          <div className="flex flex-row items-center">
            <Popover defaultOpen={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild disabled={loading}>
                <div
                  className={cn(
                    "color-gray-999 cursor-pointer flex flex-row gap-2 rounded p-4 border border-gray-E6E6E3 hover:text-white hover:bg-primary hover:border-primary",
                    open && "bg-primary"
                  )}
                >
                  <FilterIcon className="hover:text-white h-6 w-6" />
                  <div className="text-base">Apply Filters</div>
                </div>
              </PopoverTrigger>
              <PopoverContent
                asChild
                align="start"
                onOpenAutoFocus={(e) => e.preventDefault()}
                className="border-primary"
              >
                <div className="text-gray-999 flex flex-col">
                  <div className="text-sm font-medium py-2 text-gray-999">
                    Filter by test
                  </div>
                  <div className="flex flex-col">
                    {filter.testId && (
                      <button
                        type="button"
                        onClick={() => onTestIdChanged(undefined)}
                        className="border-0 p-2 cursor-pointer text-gray-999 text-left hover:bg-gray-E6E6E3 hover:text-gray-333 rounded"
                      >
                        All Tests
                      </button>
                    )}
                    {tests?.map((test) => (
                      <button
                        key={test.id}
                        type="button"
                        onClick={() => onTestIdChanged(test.id)}
                        className={cn(
                          "border-0 p-2 cursor-pointer text-gray-999 text-left hover:bg-gray-E6E6E3 hover:text-gray-333 rounded",
                          test.id === filter.testId && "text-gray-333"
                        )}
                      >
                        {test.name}
                      </button>
                    ))}
                  </div>
                </div>
              </PopoverContent>
            </Popover>
            {filter.testId && (
              <button
                type="button"
                onClick={() => onTestIdChanged(undefined)}
                className="flex flex-row gap-1 items-center text-[#F14C76] p-4 text-sm"
              >
                <XIcon className="h-4 w-4" />
                <span>Clear Filters</span>
              </button>
            )}
          </div>
        )}

        <div className="flex items-center gap-6">
          <label className="flex items-center gap-1">
            <Checkbox
              name="state_not-started"
              onCheckedChange={(checked) => {
                onStateChanged(checked, CandidateTestStateEnum.NotStarted)
              }}
            />
            <span>Not Started</span>
          </label>
          <label className="flex items-center gap-1">
            <Checkbox
              name="state_in-progress"
              onCheckedChange={(checked) => {
                onStateChanged(checked, CandidateTestStateEnum.InProgress)
              }}
            />
            <span>In Progress</span>
          </label>
          <label className="flex items-center gap-1">
            <Checkbox
              name="state_completed"
              onCheckedChange={(checked) => {
                onStateChanged(checked, CandidateTestStateEnum.Completed)
              }}
            />
            <span>Completed</span>
          </label>
        </div>
      </div>

      <div className="flex items-center pl-6">
        <label className="flex items-center gap-2">
          <Toggle
            icons={false}
            defaultChecked={filter.archived || false}
            onChange={(e) =>
              onFilterChange({ ...filter, archived: e.target.checked })
            }
          />
          <span className="text-gray-333">Include Archived</span>
        </label>
      </div>
    </div>
  )
}

gql(`
  fragment Org_OrgCandidatesTable on CandidateTest {
    id
    state
    timeTakenSeconds
    test {
      id
      name
    }
    user {
      name
      email
    }
    completedAt
    manualScore
  }
`)

const ORGANIZATION_TESTS_QUERY_DOCUMENT = gql(`
  query OrganizationTests($organizationId: ID!) {
    tests(organizationId: $organizationId) {
      nodes {
        id
        name
      }
    }
  }
`)
