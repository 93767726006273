import { Link } from "react-router-dom"
import {
  termsOfServicePath,
  privacyPolicyPath,
  cookiePolicyPath,
} from "~/common/paths"
import { cn } from "~/common/shadcn-utils"

export const Footer = ({ className }: { className?: string }) => (
  <div
    className={cn(
      "text-gray-999 flex flex-col md:flex-row gap-8 justify-between px-6 py-12",
      className
    )}
  >
    <div>©2024 Samco. All Rights Reserved</div>
    <div className="flex flex-col gap-2 md:flex-row md:gap-6">
      <Link to={termsOfServicePath.pattern}>Terms & Conditions</Link>
      <Link to={privacyPolicyPath.pattern}>Privacy Policy</Link>
      <Link to={cookiePolicyPath.pattern}>Cookies Policy</Link>
    </div>
  </div>
)
