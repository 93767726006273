import { Checkbox } from "~/shadcn/ui/checkbox"
import { AIWizardStep } from "../AIWizard"
import { useAIWizard } from "./AIWizardProvider"
import { QuestionSuggestion, QuestionTypeEnum } from "~/__generated__/graphql"
import { useEffect, useMemo, useState } from "react"
import { Asterisk, Download } from "lucide-react"
import { Button } from "~/shadcn/ui/button"
import { Divider } from "~/ui/Divider"
import { Badge } from "~/shadcn/ui/badge"

export const Suggestions = () => {
  const {
    suggestions,
    setCurrentStep,
    reset,
    addSelectedSuggestions,
    generateCSV,
  } = useAIWizard()
  const [selectedSuggestions, setSelectedSuggestions] = useState<
    QuestionSuggestion[]
  >(suggestions || [])

  const areAllSelected = useMemo(() => {
    return selectedSuggestions.length === suggestions.length
  }, [selectedSuggestions, suggestions])

  const suggestionsByType = useMemo(() => {
    return suggestions.reduce(
      (acc, suggestion) => {
        const type = suggestion.type
        if (!acc[type]) {
          acc[type] = []
        }
        acc[type].push(suggestion)
        return acc
      },
      {} as Record<string, QuestionSuggestion[]>
    )
  }, [suggestions])

  useEffect(() => {
    setSelectedSuggestions(suggestions)
  }, [suggestions])

  if (suggestions.length === 0) {
    setCurrentStep(AIWizardStep.Error)
    return null
  }

  return (
    <>
      <div className="flex flex-col border border-gray-E6E6E3 rounded-lg divide-y divide-gray-E6E6E3">
        <div className="p-6 text-gray-999 flex justify-between items-center text-sm">
          <div className="flex items-center gap-4">
            <Checkbox
              checked={areAllSelected}
              onCheckedChange={() =>
                setSelectedSuggestions(areAllSelected ? [] : suggestions)
              }
            />
            <span>Select All / Deselect All</span>
          </div>
          <div className="flex items-center gap-2">
            <Download className="w-6 h-6" />
            <span>
              <Button variant="link" size="link" onClick={generateCSV}>
                Download CSV
              </Button>{" "}
              of questions and import later
            </span>
          </div>
        </div>

        {suggestionsByType[QuestionTypeEnum.VideoResponse]?.length > 0 && (
          <>
            <div className="p-6 bg-gray-F7F7F5 text-gray-333">
              Video Response Questions
            </div>
            <SuggestionsList
              suggestions={suggestionsByType[QuestionTypeEnum.VideoResponse]}
              selectedSuggestions={selectedSuggestions}
              setSelectedSuggestions={setSelectedSuggestions}
            />
          </>
        )}

        {suggestionsByType[QuestionTypeEnum.TextResponse]?.length > 0 && (
          <>
            <div className="p-6 bg-gray-F7F7F5 text-gray-333">
              Long Form Questions
            </div>
            <SuggestionsList
              suggestions={suggestionsByType[QuestionTypeEnum.TextResponse]}
              selectedSuggestions={selectedSuggestions}
              setSelectedSuggestions={setSelectedSuggestions}
            />
          </>
        )}

        {suggestionsByType[QuestionTypeEnum.MultipleChoice]?.length > 0 && (
          <>
            <div className="p-6 bg-gray-F7F7F5 text-gray-333">
              Multiple Choice
            </div>
            <SuggestionsList
              suggestions={suggestionsByType[QuestionTypeEnum.MultipleChoice]}
              selectedSuggestions={selectedSuggestions}
              setSelectedSuggestions={setSelectedSuggestions}
            />
          </>
        )}

        {suggestionsByType[QuestionTypeEnum.SingleChoice]?.length > 0 && (
          <>
            <div className="p-6 bg-gray-F7F7F5 text-gray-333">
              Single Choice
            </div>
            <SuggestionsList
              suggestions={suggestionsByType[QuestionTypeEnum.SingleChoice]}
              selectedSuggestions={selectedSuggestions}
              setSelectedSuggestions={setSelectedSuggestions}
            />
          </>
        )}
      </div>

      <Divider className="my-10" />

      <div className="flex justify-between">
        <Button
          type="button"
          disabled={selectedSuggestions.length === 0}
          onClick={() => addSelectedSuggestions(selectedSuggestions)}
        >
          Add Selected Questions To My Test
        </Button>
        <Button
          variant="link"
          type="button"
          className="ml-auto text-gray-999 font-medium"
          onClick={reset}
        >
          Start Over
        </Button>
      </div>
    </>
  )
}

const SuggestionsList = ({
  suggestions,
  selectedSuggestions,
  setSelectedSuggestions,
}: {
  suggestions: QuestionSuggestion[]
  selectedSuggestions: QuestionSuggestion[]
  setSelectedSuggestions: (suggestions: QuestionSuggestion[]) => void
}) => {
  const select = (suggestion: QuestionSuggestion) => {
    if (selectedSuggestions.includes(suggestion)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((s) => s !== suggestion)
      )
    } else {
      setSelectedSuggestions([...selectedSuggestions, suggestion])
    }
  }

  return (
    <div className="px-6 py-3 flex flex-col divide-y">
      {suggestions.map((suggestion) => (
        <div
          key={suggestion.prompt}
          className="py-3 flex flex-col gap-4 text-sm"
        >
          <div className="flex items-center gap-4">
            <Checkbox
              checked={selectedSuggestions.includes(suggestion)}
              onCheckedChange={() => select(suggestion)}
            />
            <Badge>{suggestion.category}</Badge>
            <span>{suggestion.prompt}</span>
          </div>
          {suggestion.choices && (
            <div className="text-gray-999 ml-10 flex flex-col gap-1">
              {suggestion.choices.map((choice) => (
                <div key={choice} className="flex gap-1 items-center">
                  {choice}
                  {suggestion.preferredChoices?.includes(choice) && (
                    <Asterisk className="w-4 h-4 text-primary" />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
