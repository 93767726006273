import { ChevronDown, ChevronUp } from "lucide-react"
import { cn } from "~/common/shadcn-utils"

export const AdvancedToggle = ({
  open,
  onClick,
  text,
  containerClassName,
}: {
  open: boolean
  onClick: () => void
  text: string
  containerClassName?: string
}) => (
  <div
    className={cn(
      "cursor-pointer flex items-center gap-2 text-sm mt-4",
      containerClassName
    )}
    onClick={onClick}
  >
    <div className="bg-white px-2 py-1 rounded">
      {open ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
    </div>
    <div className="text-gray-999">{text}</div>
  </div>
)
