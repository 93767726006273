import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

export const usePagination = ({
  perPage,
  paramKey = "page",
}: {
  perPage: number
  paramKey?: string
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get(paramKey) || "1")

  const paginate = useCallback(
    (page: number) => {
      setSearchParams({ [paramKey]: page.toString() })
    },
    [setSearchParams, paramKey]
  )

  const after = useMemo(() => {
    return btoa(((page - 1) * perPage).toString())
  }, [perPage, page])

  return { paginate, page, after }
}
