import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react"
import { ReactNode } from "react"
import ReactPaginate from "react-paginate"
import { cn } from "~/common/shadcn-utils"

export const Pagination = ({
  page,
  pageCount,
  paginate,
}: {
  page: number
  pageCount: number
  paginate: (pageNo: number) => void
}) => {
  const linkClassName =
    "border px-2 rounded min-w-[44px] h-[44px] flex items-center justify-center border-gray-E6E6E3 text-gray-333"

  const LinkContainer = ({ children }: { children: ReactNode }) => (
    <div className={cn(linkClassName)}>{children}</div>
  )

  return (
    <ReactPaginate
      breakLabel={
        <LinkContainer>
          <MoreHorizontal size={18} />
        </LinkContainer>
      }
      nextLabel={
        <LinkContainer>
          <ChevronRight size={18} />
        </LinkContainer>
      }
      previousLabel={
        <LinkContainer>
          <ChevronLeft size={18} />
        </LinkContainer>
      }
      forcePage={page - 1}
      onPageChange={({ selected }) => paginate(selected + 1)}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      containerClassName="flex gap-1 mb-8"
      pageLinkClassName={linkClassName}
      disabledLinkClassName="cursor-not-allowed text-gray-999 opacity-50"
      activeLinkClassName="text-white font-semibold bg-primary border-primary"
    />
  )
}
