import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { useEffect } from "react"
import { userPostLoginPath } from "~/common/paths"

export const RequireUserSignedOut = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser) {
      navigate(userPostLoginPath(currentUser))
    }
  }, [currentUser, navigate])

  return <Outlet />
}
