import { Hourglass } from "lucide-react"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { FormPanel } from "~/questions/QuestionGroupForm/FormPanel"

export const GeneratingQuestions = () => {
  return (
    <FormPanel>
      <div className="flex flex-col gap-5 items-center justify-center text-center py-32">
        <div className="bg-primary text-white rounded-full p-5">
          <Hourglass className="w-6 h-6" />
        </div>
        <div className="text-gray-333 text-xl font-medium">
          Generating Questions
        </div>
        <div className="text-gray-333 text-sm">
          Please wait while we generate questions for your test.
          <br />
          This will just take a minute.
        </div>
        <LoadingIndicatorCentered />
      </div>
    </FormPanel>
  )
}
