import { CurrentAttempt_QuestionAttemptFragment } from "~/__generated__/graphql"
import { useEffect, useState } from "react"
import { parseISO } from "date-fns"
import { cn } from "~/common/shadcn-utils"
import { secondsToMMSS } from "~/common/secondsToMMSS"

export const useSecondsRemaining = ({
  currentAttempt,
}: {
  currentAttempt?: CurrentAttempt_QuestionAttemptFragment | null
}) => {
  if (!currentAttempt) return null
  const timeLimitSeconds =
    currentAttempt.question.questionGroup.timeLimitSeconds

  if (typeof timeLimitSeconds !== "number" || isNaN(timeLimitSeconds))
    return null

  const startedAt = parseISO(currentAttempt.createdAt)
  const secondsSpent = new Date().getTime() / 1000 - startedAt.getTime() / 1000
  const secondsRemaining =
    timeLimitSeconds - secondsSpent - currentAttempt.previousAttemptTimeSeconds
  return secondsRemaining > 0 ? secondsRemaining : 0
}

export const Timer = ({ secondsRemaining }: { secondsRemaining: number }) => {
  const [, setRerenderCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setRerenderCount((prevCount) => prevCount + 1)
    }, 100)
    return () => clearInterval(interval)
  }, [])

  if (secondsRemaining === null) return null

  return (
    <div className={cn({ "text-communist": secondsRemaining <= 30 })}>
      Question Time Remaining - {secondsToMMSS(secondsRemaining)}
    </div>
  )
}
