import * as React from "react"
import "~/common/sentry-init"
import { RouterProvider } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { router } from "./router"
import colors from "tailwindcss/colors"

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import { getMetaVar } from "./common/getMetaVar"
import { FlashMessageToasts } from "./ui/FlashMessageToasts"
import { CurrentUserProvider } from "./auth/CurrentUserContext"

import "react-toggle/style.css"
import { ErrorBoundary } from "@sentry/react"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

const csrfToken = getMetaVar("csrf-token")

if (csrfToken == null) {
  console.warn("missing csrf token")
}

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken!,
  },
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

export const App = () => {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <CurrentUserProvider>
            <Toaster
              toastOptions={{
                style: {
                  maxWidth: "100%",
                  textAlign: "left",
                },
                success: {
                  style: {
                    color: colors.white,
                    backgroundColor: colors.green[700],
                  },
                },
                error: {
                  style: {
                    color: colors.white,
                    backgroundColor: colors.red[700],
                  },
                },
              }}
            />
            <FlashMessageToasts />
            <RouterProvider router={router} />
          </CurrentUserProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
