import { Link } from "react-router-dom"
import { QuestionResponse } from "~/__generated__/graphql"
import { ChevronDown, ChevronUp } from "lucide-react"
import { cn } from "~/common/shadcn-utils"

type QuestionNavigatorQuestionResponse = Pick<
  QuestionResponse,
  "questionNumber" | "previousQuestionResponseId" | "nextQuestionResponseId"
> & {
  candidateTest: {
    questionCount: number
  }
}

interface QuestionNavigatorProps {
  questionResponse: QuestionNavigatorQuestionResponse
  viewPath: (questionResponseId: string) => string
}

export const QuestionNavigator = ({
  questionResponse,
  viewPath,
}: QuestionNavigatorProps) => {
  return (
    <div className="flex items-center whitespace-nowrap">
      <Link
        to={
          questionResponse.previousQuestionResponseId
            ? viewPath(questionResponse.previousQuestionResponseId)
            : "#"
        }
        className={cn(
          "bg-gray-F7F7F5 cursor-pointer p-2 mr-2 block",
          !questionResponse.previousQuestionResponseId &&
            "opacity-50 cursor-not-allowed"
        )}
      >
        <ChevronUp size={18} />
      </Link>
      <Link
        to={
          questionResponse.nextQuestionResponseId
            ? viewPath(questionResponse.nextQuestionResponseId)
            : "#"
        }
        className={cn(
          "bg-gray-F7F7F5 cursor-pointer p-2 mr-4 block",
          !questionResponse.nextQuestionResponseId &&
            "opacity-50 cursor-not-allowed"
        )}
      >
        <ChevronDown size={18} />
      </Link>
      Question {questionResponse.questionNumber} of{" "}
      {questionResponse.candidateTest.questionCount}
    </div>
  )
}
