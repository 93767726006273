import { QuestionResponse_CandidateTestResponsesFragment } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { CheckCircle2 } from "lucide-react"

export const ChoiceQuestionResponse = ({
  questionResponse,
  className,
}: {
  questionResponse: QuestionResponse_CandidateTestResponsesFragment
  className?: string
}) => {
  return (
    <div className={cn("flex flex-col gap-2 text-xl", className)}>
      {questionResponse.question.questionChoices.map((questionChoice) => (
        <div
          key={questionChoice.id}
          className={cn(
            "flex gap-2 items-center",
            questionChoice.isCorrect && "text-success"
          )}
        >
          <div>
            <CheckCircle2
              className={cn(
                "w-5 h-5 text-gray-999",
                !questionResponse.responseChoices.find(
                  (c) => c.id === questionChoice.id
                ) && "opacity-0"
              )}
            />
          </div>
          <div>{questionChoice.choiceCopy}</div>
        </div>
      ))}
    </div>
  )
}
