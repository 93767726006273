import { useState } from "react"
import { useQuery } from "@apollo/client"
import { Button } from "~/shadcn/ui/button"
import {
  QuestionTypeEnum,
  Test,
  TestPublishStatusEnum,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"
import Toggle from "react-toggle"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "~/shadcn/ui/dialog"
import { AlertTriangle } from "lucide-react"
import { Link } from "react-router-dom"
import { organizationTestQuestionsPath } from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"

export const PublishWarnOnIssues = ({
  test,
  onPublish,
  checkTestState,
  setCheckTestState,
}: {
  test: Pick<Test, "id">
  onPublish: () => void
  checkTestState: boolean
  setCheckTestState: (check: boolean) => void
}) => {
  const organizationId = useOrganizationId()
  const { data, loading } = useQuery(TEST_PUBLISH_CHECK_QUERY, {
    variables: { testId: test.id },
  })

  const doPublish = () => {
    setTimeout(() => {
      setCheckTestState(false)
      onPublish()
    }, 0)
  }

  if (loading) {
    return null
  }

  if (!checkTestState) {
    return null
  }

  let issues: { title: string; description: string; cta: string }[] = []
  if (data?.test?.questionGroups) {
    const keptQuestions = data.test.questionGroups.filter((group) => {
      return group.discardedAt === null
    })
    const missingScoringPrompt = keptQuestions
      .filter(
        (group) =>
          group.questionType === QuestionTypeEnum.TextResponse ||
          group.questionType === QuestionTypeEnum.VideoResponse
      )
      .filter((group) => {
        return group.questions.some((question) => !question.scoringPrompt)
      })
    if (missingScoringPrompt.length > 0) {
      issues.push({
        title: "Enable AI Test Scoring",
        description: `${missingScoringPrompt.length} of your questions don't have scoring prompts.  If you add example answers we can provide AI test scores`,
        cta: "Add Scoring Prompts",
      })
    }
    const missingTimeLimit = keptQuestions.filter((group) => {
      return group.timeLimitSeconds === null
    })
    if (missingTimeLimit.length > 0) {
      issues.push({
        title: "Add Time Limits",
        description: `${missingTimeLimit.length} of your questions don't have time limits.  Setting limits can deter offline research and AI use and make your test more objective.`,
        cta: "Add Time Limits",
      })
    }
  }

  if (issues.length === 0) {
    doPublish()
    return null
  }

  return (
    <Dialog open={checkTestState} onOpenChange={setCheckTestState}>
      <DialogContent className="p-8 max-w-2xl">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-gray-333 leading-normal tracking-normal">
            Publish Test
          </DialogTitle>
          <DialogDescription className="font-semibold text-gray-333">
            Before you publish, there are a few things you should consider.
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="font-bold text-primary pb-3">
            {issues.length} FYI{issues.length > 1 ? "s" : ""}
          </div>
          <ul className="flex flex-col gap-4">
            {issues.map((issue, index) => (
              <li
                key={index}
                className="flex items-center p-4 rounded-lg bg-gray-F7F7F5"
              >
                <div className="text-primary pl-2 pr-6">
                  <AlertTriangle />
                </div>
                <div className="flex flex-col gap-4">
                  <h4 className="text-primary font-bold">{issue.title}</h4>
                  <p className="text-gray-333 font-semibold">
                    {issue.description}
                  </p>
                  <div>
                    <Link
                      to={organizationTestQuestionsPath({
                        organizationId,
                        testId: test.id,
                      })}
                      className="text-primary"
                    >
                      {issue.cta}
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <DialogFooter className="sm:justify-between pt-4">
          <Button type="button" variant="default" onClick={() => doPublish()}>
            Publish Anyway
          </Button>
          <Button
            type="button"
            variant="ghost"
            onClick={() => setCheckTestState(false)}
          >
            Close & Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export const PublishTestButton = ({
  test,
}: {
  test: Pick<Test, "id" | "name" | "publishStatus">
}) => {
  const [checkTestState, setCheckTestState] = useState(false)

  const [updatePublishStatus, { loading: loadingPublishStatus }] =
    useSafeMutation(TEST_UPDATE_PUBLISH_STATUS_MUTATION)

  const handlePublishStatusClick = async () => {
    const { data, errors } = await updatePublishStatus({
      variables: {
        input: {
          testId: test.id,
          name: test.name as string,
          publishStatus: TestPublishStatusEnum.Published,
        },
      },
    })

    if (!data?.testUpdate?.test) {
      toast.error("Error updating publish status.")
      if (errors) {
        console.log(errors)
      }
    } else {
      toast.success(`${test.name} test published.`)
    }
  }
  return (
    <>
      <PublishWarnOnIssues
        test={test}
        onPublish={handlePublishStatusClick}
        checkTestState={checkTestState}
        setCheckTestState={setCheckTestState}
      />
      <Button
        type="button"
        variant="default"
        disabled={test.publishStatus === TestPublishStatusEnum.Published}
        onClick={() => setCheckTestState(true)}
        isLoading={loadingPublishStatus}
      >
        Publish Test
      </Button>
    </>
  )
}

export const PublishStatusToggle = ({
  test,
}: {
  test: Pick<Test, "id" | "name" | "publishStatus">
}) => {
  const [checkTestState, setCheckTestState] = useState(false)
  const [toggleOn, setToggleOn] = useState(
    test.publishStatus === TestPublishStatusEnum.Published
  )

  const [updatePublishStatus, { loading: loadingPublishStatus }] =
    useSafeMutation(TEST_UPDATE_PUBLISH_STATUS_MUTATION)

  const handlePublishStatusChange = async () => {
    const newStatus =
      test.publishStatus === TestPublishStatusEnum.Published
        ? TestPublishStatusEnum.Unpublished
        : TestPublishStatusEnum.Published

    const { data, errors } = await updatePublishStatus({
      variables: {
        input: {
          testId: test.id,
          name: test.name as string,
          publishStatus: newStatus,
        },
      },
    })

    if (!data?.testUpdate?.test) {
      toast.error("Error updating publish status.")
      if (errors) {
        console.log(errors)
      }
    } else {
      setToggleOn(newStatus === TestPublishStatusEnum.Published)
    }
  }

  return (
    <>
      <PublishWarnOnIssues
        test={test}
        onPublish={handlePublishStatusChange}
        checkTestState={checkTestState}
        setCheckTestState={setCheckTestState}
      />
      <Toggle
        onChange={(_e) => {
          if (test.publishStatus === TestPublishStatusEnum.Published) {
            handlePublishStatusChange()
          } else {
            setCheckTestState(true)
          }
        }}
        checked={toggleOn}
        className="toggle-primary ml-auto mx-auto"
        disabled={loadingPublishStatus}
      />
    </>
  )
}

const TEST_UPDATE_PUBLISH_STATUS_MUTATION = gql(`
  mutation TestUpdatePublishStatus($input: TestUpdateInput!) {
    testUpdate(input: $input) {
      test {
        id
        name
        publishStatus
      }
    }
  }
`)

const TEST_PUBLISH_CHECK_QUERY = gql(`
  query TestPublishCheck($testId: ID!) {
    test(testId: $testId) {
      id
      questionGroups {
        id
        questionType
        timeLimitSeconds
        discardedAt
        questions {
          id
          scoringPrompt
        }
      }
    }
  }
`)
