import { cn } from "~/common/shadcn-utils"
import featuresImg from "~/images/marketing/marketing_key_features.png"
import lessinProfile from "~/images/marketing/lessin-profile.png"
import lessinSignature from "~/images/marketing/lessin-signature.svg"
import newTestImage from "~/images/new-test.svg"
import { createAccountPath } from "~/common/paths"
import { ChevronRight } from "lucide-react"
import { LinkButton } from "~/shadcn/ui/button"

export const AboutSection = () => {
  return (
    <Section id="about">
      <QuarterWidth quarters={4}>
        <SectionHeaderText>About Standard Aptitude</SectionHeaderText>
        <SectionHeaderText color="text-gray-999">
          Who We Are & What We Believe
        </SectionHeaderText>
        <p className="my-6 text-lg text-gray-333">
          It doesn’t matter where you are from or what you look like, it matters
          what you can do.  This is the organizing principle that drove us to
          create standard aptitude.  We are done with resumes, who you know
          shouldn’t matter.  There is no signal in it, especially not anymore.
        </p>
        <p className="my-6 text-lg text-gray-333">
          So how do you prove what you can do as an applicant?  How do you know
          who can do what as an organization? 
        </p>
        <p className="my-6 text-lg text-gray-333">
          The answer is Testing.
          <br />
          Modern, simple, elegant, effective Testing.
          <br />
          This is what we have built with Standard Aptitude.  
        </p>
        <p className="my-6 text-lg text-gray-333">
          We use the best in modern technology and AI tools so that you can
          easily make highly engaging, highly effective tests, deliver those
          tests with confidence to the right people, make sure no one is
          cheating, and evaluate candidate responses.  
        </p>
        <p className="my-6 text-lg text-gray-333">
          We built SA for ourselves.  We need this to find the right people in
          2024 for our companies and funds.  We would be thrilled if you would
          use it too… 
        </p>
        <div className="flex flex-row gap-8">
          <div>
            <img
              src={lessinProfile}
              alt="Sam Lessin"
              className="w-full h-auto"
              width="130"
              height="130"
            />
          </div>
          <div className="py-4">
            <img
              src={lessinSignature}
              alt=""
              className="w-full h-auto mb-2"
              width="174"
            />
            <div className="text-lg font-bold text-gray-333 mb-[-6px]">
              Sam Lessin
            </div>
            <div className="text-gray-999">@lessin</div>
          </div>
        </div>
      </QuarterWidth>
    </Section>
  )
}

export const BuildATestSection = () => {
  return (
    <Section id="build-a-test" backgroundColor="bg-gray-F7F7F5">
      <QuarterWidth quarters={1} className="pb-2 md:pb-8">
        <img
          src={newTestImage}
          alt="Create a New Test"
          className="w-[114px] md:w-full h-auto"
        />
      </QuarterWidth>

      <QuarterWidth quarters={3} className="pt-2 md:pt-8">
        <SectionHeaderText>Build a Test</SectionHeaderText>
        <SectionHeaderText color="text-gray-999">
          Quick, Clean, Easy & Sophisticated
        </SectionHeaderText>
        <p className="my-6 text-gray-333">
          You can be up and running in minutes with a test to screen candidates
          for your organization. Our platform has great AI features to speed up
          test creation and evaluation. Powerful tools prevent use of bots,
          companions, and cheating - so you get a clean signal on who is the
          best for your role.
        </p>
        <LinkButton className="mt-4" to={createAccountPath.pattern}>
          Get Started
          <ChevronRight className="ml-2" size={16} />
        </LinkButton>
      </QuarterWidth>
    </Section>
  )
}

export const KeyFeaturesSection = () => {
  return (
    <Section id="key-features" reverse>
      <QuarterWidth quarters={2} className="pb-6 md:pb-8">
        <img src={featuresImg} alt="Key Features" className="w-full h-auto" />
      </QuarterWidth>
      <QuarterWidth quarters={2} className="pt-6 md:pt-8">
        <SectionHeaderText>Key Features</SectionHeaderText>
        <p className="my-6 text-gray-333">
          We built Standard Aptitude for ourselves to evaluate candidates for
          roles in finance and VC backed startups without relying on
          increasingly irrelevant signals like where you went to school or your
          degree and to focus on actual abilities.
        </p>
        <ol className="list-decimal list-outside ml-5 space-y-6">
          <li className="font-semibold text-lg md:text-base">
            Easy to Use Test Builder Supercharged with Thoughtful AI
            <p className="font-normal text-base pt-3 text-gray-333">
              Build a clean modern test in minutes that fits your needs. Use our
              AI tools to suggest possible questions and speed deployment.
            </p>
          </li>
          <li className="font-semibold text-lg md:text-base">
            Delightful Candidate Experience
            <p className="font-normal text-base pt-3 text-gray-333">
              Easy to use, simple, fast and clean. Our tests make it easy for
              candidates to sign-in and respond.
            </p>
          </li>
          <li className="font-semibold text-lg md:text-base">
            Proof of Humanity & Secured At-Home Testing
            <p className="font-normal text-base pt-3 text-gray-333">
              Audio and video recording tools as well as several layers of
              authentication make sure that candidates are who they say they
              are, and aren't cheating.
            </p>
          </li>
          <li className="font-semibold text-lg md:text-base">
            Modern Test Management & Candidate Evaluation Tools
            <p className="font-normal text-base pt-3 text-gray-333">
              Our platform has a full suite of test management tools, including
              AI scoring, and powerful flows for grading and sharing results.
            </p>
          </li>
          <li className="font-semibold text-lg md:text-base">
            Compliance Approved
            <p className="font-normal text-base pt-3 text-gray-333">
              Share tests with confidence. Our platform allows you to manage any
              required disclaimers or agreements with candidates.
            </p>
          </li>
        </ol>
        <LinkButton className="mt-6" to={createAccountPath.pattern}>
          Get Stared it's FREE!
        </LinkButton>
      </QuarterWidth>
    </Section>
  )
}

export const SectionHeaderText = ({
  children,
  color = "text-black",
  className,
}: {
  children: React.ReactNode
  color?: string
  className?: string
}) => (
  <h2
    className={cn(
      `text-2xl md:text-6xl font-semibold mb-2 ${color}`,
      className
    )}
  >
    {children}
  </h2>
)

export const QuarterWidth = ({
  quarters,
  className,
  children,
}: {
  quarters: number
  className?: string
  children: React.ReactNode
}) => {
  const widthClass = cn(`w-full`, {
    "md:w-1/4": quarters === 1,
    "md:w-2/4": quarters === 2,
    "md:w-3/4": quarters === 3,
    "md:w-4/4": quarters === 4,
  })
  return <div className={cn("p-8", widthClass, className)}>{children}</div>
}

export const Section = ({
  children,
  reverse = false,
  mobileReverse = false,
  backgroundColor = "bg-white",
  id,
  className,
}: {
  children: React.ReactNode
  reverse?: boolean
  mobileReverse?: boolean
  backgroundColor?: string
  id?: string
  className?: string
}) => {
  return (
    <div id={id} className={`${backgroundColor} py-8`}>
      <div
        className={cn(
          "container mx-auto xl:px-16 flex md:flex-row",
          {
            "md:flex-row-reverse": reverse,
            "flex-col": !mobileReverse,
            "flex-col-reverse": mobileReverse,
          },
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}
