import { useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import { Divider } from "~/ui/Divider"
import { useTestFlow } from "./TestFlowProvider"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { ContinueOrSaveBar } from "./ContinueOrSaveBar"

export const MultipleChoiceResponseForm = ({
  timeExpired,
}: {
  timeExpired: boolean
}) => {
  const { answerQuestion, currentAttempt } = useTestFlow()
  const [loading, setLoading] = useState(false)
  const [selectedChoiceIds, setSelectedChoiceIds] = useState<string[]>([])

  const onClick = async () => {
    setLoading(true)
    await answerQuestion({ selectedChoiceIds })
    setLoading(false)
  }

  const toggleChoice = (choiceId: string) => {
    const existingIndex = selectedChoiceIds.findIndex((cid) => cid === choiceId)
    if (existingIndex > -1) {
      setSelectedChoiceIds(selectedChoiceIds.toSpliced(existingIndex, 1))
    } else {
      setSelectedChoiceIds([...selectedChoiceIds, choiceId])
    }
  }

  return (
    <div>
      <div className="mb-4">Select All That Apply</div>

      <div className="rounded border border-gray-E6E6E3 overflow-hidden">
        {currentAttempt?.question.questionChoices.map((choice, index) => (
          <div
            key={choice.id}
            className={cn("p-6 flex items-center cursor-pointer", {
              "bg-gray-F7F7F5": selectedChoiceIds.includes(choice.id),
              "border-b border-gray-E6E6E3":
                index !== currentAttempt.question.questionChoices.length - 1,
              "cursor-not-allowed opacity-50 bg-gray-F7F7F5": timeExpired,
            })}
            onClick={() => !timeExpired && toggleChoice(choice.id)}
          >
            <Checkbox
              checked={selectedChoiceIds.includes(choice.id)}
              className="mr-4"
            />
            <div>{choice.choiceCopy}</div>
          </div>
        ))}
      </div>

      <Divider className="my-10" />

      <ContinueOrSaveBar>
        <Button
          isLoading={loading}
          disabled={(!timeExpired && selectedChoiceIds.length === 0) || loading}
          onClick={onClick}
        >
          Continue
        </Button>
      </ContinueOrSaveBar>
    </div>
  )
}
