import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import invariant from "tiny-invariant"
import { AVController } from "./AVController"

interface RecordingContextType {
  avController: AVController
  videoEnabled: boolean
  audioEnabled: boolean
  isRecording: boolean
  microphoneTestEnabled: boolean
  audioDeviceId: string | null
  videoDeviceId: string | null
}

const RecordingContext = createContext<RecordingContextType | null>(null)

export const RecordingProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const avControllerRef = useRef<AVController>(new AVController())
  const [videoEnabled, setVideoEnabled] = useState(false)
  const [audioEnabled, setAudioEnabled] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [audioDeviceId, setAudioDeviceId] = useState<string | null>(null)
  const [videoDeviceId, setVideoDeviceId] = useState<string | null>(null)
  const [microphoneTestEnabled, setMicrophoneTestEnabled] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVideoEnabled(avControllerRef.current.videoEnabled)
      setAudioEnabled(avControllerRef.current.audioEnabled)
      setIsRecording(avControllerRef.current.isRecording)
      setMicrophoneTestEnabled(avControllerRef.current.microphoneTestEnabled)
      setAudioDeviceId(avControllerRef.current.audioDeviceId)
      setVideoDeviceId(avControllerRef.current.videoDeviceId)
    }, 50)
    return () => clearInterval(intervalId)
  }, [
    setVideoEnabled,
    setAudioEnabled,
    setIsRecording,
    setMicrophoneTestEnabled,
    setAudioDeviceId,
    setVideoDeviceId,
  ])

  const value = useMemo(
    () => ({
      avController: avControllerRef.current,
      videoEnabled,
      audioEnabled,
      isRecording,
      microphoneTestEnabled,
      audioDeviceId,
      videoDeviceId,
    }),
    [
      videoEnabled,
      audioEnabled,
      isRecording,
      microphoneTestEnabled,
      audioDeviceId,
      videoDeviceId,
    ]
  )

  return (
    <RecordingContext.Provider value={value}>
      {children}
    </RecordingContext.Provider>
  )
}

export const useRecording = () => {
  const contextValue = useContext(RecordingContext)
  invariant(contextValue, "RecordingContext has no value")
  return contextValue
}
