import { ReactNode } from "react"
import { candidateTestsPath } from "~/common/paths"
import { LinkButton } from "~/shadcn/ui/button"

export const ContinueOrSaveBar = ({ children }: { children: ReactNode }) => (
  <div className="flex items-center justify-between">
    {children}
    <LinkButton variant="ghost" to={candidateTestsPath.pattern}>
      Save as Draft & Exit
    </LinkButton>
  </div>
)
