import { PageHeader } from "~/ui/PageHeader"
import { Button, LinkButton, AnchorButton } from "~/shadcn/ui/button"
import { Form } from "~/shadcn/ui/form"
import { TextField } from "~/ui/forms/TextField"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  organizationTestsPath,
  candidateTestsPath,
  signOutPath,
} from "~/common/paths"
import { useNavigate, useParams } from "react-router-dom"
import { GravatarImage } from "~/ui/GravatarImage"
import { PhoneNumberField } from "~/ui/forms/PhoneNumberField"

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Required",
  }),
  email: z.string().readonly(),
  phone: z.string().min(1, {
    message: "Required",
  }),
})

export const UserProfileScreen = () => {
  const currentUser = useCurrentUser()
  const { organizationId } = useParams()
  const navigate = useNavigate()

  const onCancel = () => {
    if (organizationId) {
      navigate(
        organizationTestsPath({
          organizationId,
        })
      )
    } else {
      navigate(candidateTestsPath.pattern)
    }
  }

  const [userUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      name: currentUser.name || "",
      email: currentUser.email || "",
      phone: currentUser.phone || "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await userUpdate({
      variables: {
        input: {
          userId: currentUser.id,
          name: values.name,
          phone: values.phone,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.userUpdate?.user) {
      toast.success("Profile Updated")
    } else {
      toast.error("Error updating profile.")
    }
  }

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader title="Profile" subhead="Please provide a few details" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-6 flex flex-col gap-4">
              <TextField
                control={form.control}
                name="name"
                label="Name"
                required
              />
              <TextField
                control={form.control}
                name="email"
                label="Email Address"
                disabled
                inputClassName="bg-gray-F7F7F5"
              />
              <PhoneNumberField
                control={form.control}
                name="phone"
                label="Phone Number"
                required
              />
            </div>

            <div className="col-span-6">
              <div className="flex flex-col gap-2">
                <span className="text-base font-normal text-gray-999">
                  Avatar Photo
                </span>
                <div className="flex items-center gap-4 p-6 border border-gray-E6E6E3 rounded">
                  <div>
                    <GravatarImage
                      className="w-[96px] h-[96px]"
                      email={currentUser.email}
                      name={currentUser.name}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="text-gray-333">{currentUser.email}</p>
                    <LinkButton
                      to="https://gravatar.com/"
                      className="button"
                      target="_blank"
                    >
                      Change Photo
                    </LinkButton>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 h-[1px] my-4 bg-gray-E3E2E0" />

            <div className="col-span-12 flex flex-row justify-between">
              <div className="flex flex-row gap-2">
                <Button
                  name="action"
                  className="btn"
                  disabled={loading}
                  type="submit"
                >
                  Save & Update
                </Button>
              </div>
              <div>
                <Button
                  className="btn"
                  variant="ghost"
                  disabled={loading}
                  onClick={() => onCancel()}
                >
                  Close & Cancel
                </Button>
                <AnchorButton
                  href={signOutPath.pattern}
                  className="btn"
                  variant="ghost"
                >
                  Sign Out
                </AnchorButton>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}

export const USER_UPDATE_MUTATION = gql(`
  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        id
        name
        phone
      }
    }
  }
`)
