import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/common/shadcn-utils"
import { Link, LinkProps } from "react-router-dom"
import { LoadingIndicator } from "~/components/LoadingIndicator"

const buttonVariants = cva(
  "inline-flex rounded items-center justify-center whitespace-nowrap font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-60",
  {
    variants: {
      variant: {
        default: "bg-primary text-white hover:bg-primary/90",
        destructive: "bg-red-500 text-slate-50 hover:bg-red-500/90",
        outline: "border border-gray-E6E6E3 bg-white hover:bg-gray-E6E6E3/20",
        "outline-light":
          "border border-gray-E6E6E3 text-gray-999 bg-white hover:bg-gray-E6E6E3/20",
        secondary:
          "bg-white text-gray-999 border border-gray-E6E6E3 hover:bg-gray-E6E6E3/20",
        ghost: "text-gray-999 hover:text-gray-333",
        link: "text-slate-900 underline-offset-4 hover:underline",
        tableHeader: "",
      },
      size: {
        default: "h-12 py-3 px-4",
        sm: "h-10 px-3 text-sm",
        lg: "h-12 px-8",
        icon: "h-10 w-10",
        link: "",
        content: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

type SharedProps = {
  isLoading?: boolean
  loadingText?: string
}

export interface ButtonProps
  extends SharedProps,
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const ButtonContents = ({
  children,
  isLoading,
  loadingText,
}: {
  children: React.ReactNode
  isLoading?: boolean
  loadingText: string
}) => {
  return isLoading ? (
    <>
      <LoadingIndicator size="small" /> {loadingText}
    </>
  ) : (
    children
  )
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading,
      loadingText = "Saving...",
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        <ButtonContents
          isLoading={isLoading}
          loadingText={loadingText}
          children={children}
        />
      </Comp>
    )
  }
)
Button.displayName = "Button"

export interface LinkButtonProps
  extends SharedProps,
    LinkProps,
    VariantProps<typeof buttonVariants> {}

const LinkButton = ({
  className,
  variant,
  size,
  isLoading,
  loadingText = "Saving...",
  children,
  ...props
}: LinkButtonProps) => {
  return (
    <Link
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      <ButtonContents
        isLoading={isLoading}
        loadingText={loadingText}
        children={children}
      />
    </Link>
  )
}
LinkButton.displayName = "LinkButton"

export interface FakeButtonProps
  extends SharedProps,
    React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof buttonVariants> {}

const FakeButton = ({
  className,
  variant,
  size,
  isLoading,
  loadingText = "Saving...",
  children,
  ...props
}: FakeButtonProps) => {
  return (
    <div className={cn(buttonVariants({ variant, size, className }))}>
      <ButtonContents
        isLoading={isLoading}
        loadingText={loadingText}
        children={children}
      />
    </div>
  )
}
FakeButton.displayName = "FakeButton"

export interface AnchorButtonProps
  extends SharedProps,
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {}
const AnchorButton = ({
  className,
  variant,
  size,
  isLoading,
  children,
  ...props
}: AnchorButtonProps) => {
  return (
    <a className={cn(buttonVariants({ variant, size, className }))} {...props}>
      {children}
    </a>
  )
}
AnchorButton.displayName = "AnchorButton"

export { Button, buttonVariants, LinkButton, FakeButton, AnchorButton }
