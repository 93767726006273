import { ConfirmDialog } from "~/ui/Confirm"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"

export const OrganizationUserDestroyButton = ({
  organizationUserId,
  email,
}: {
  organizationUserId: string
  email: string
}) => {
  const [inviteDestroy, { loading: loadingDestroy }] = useSafeMutation(
    ORGANIZATION_USER_DESTROY_MUTATION,
    {
      refetchQueries: ["OrganizationUsers"],
    }
  )
  const handleDestroy = async () => {
    const { data, errors } = await inviteDestroy({
      variables: {
        input: {
          organizationUserId,
        },
      },
    })

    if (errors) {
      toast.error("Error removing user.")
      console.log(errors)
    } else if (data?.organizationUserDestroy?.organizationUser) {
      toast.success("User removed from organization.")
    } else {
      toast.error("Error removing user.")
    }
  }

  return (
    <ConfirmDialog
      text={`Remove ${email}?`}
      onSuccess={async () => handleDestroy()}
    >
      <Button variant="outline" size="sm" disabled={loadingDestroy}>
        Delete
      </Button>
    </ConfirmDialog>
  )
}

const ORGANIZATION_USER_DESTROY_MUTATION = gql(`
  mutation OrganizationUserDestroy($input: OrganizationUserDestroyInput!) {
    organizationUserDestroy(input: $input) {
      organizationUser {
        id
      }
    }
  }
`)
