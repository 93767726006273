import { NavLink } from "react-router-dom"
import { cn } from "~/common/shadcn-utils"
import {
  organizationTestOverviewPath,
  organizationTestQuestionsPath,
  organizationTestAccessPath,
  organizationTestResponsesPath,
  organizationTestAIWizardPath,
} from "~/common/paths"

export const Navigation = ({
  organizationId,
  testId,
}: {
  organizationId: string
  testId: string
}) => {
  return (
    <div className="gap-8 py-4 flex justify-center border-b border-gray-E6E6E3">
      <TestNavLink
        text="Overview"
        path={organizationTestOverviewPath({ organizationId, testId })}
      />
      <TestNavLink
        text="🦄 AI Test Wizard"
        path={organizationTestAIWizardPath({ organizationId, testId })}
      />
      <TestNavLink
        text="Questions"
        path={organizationTestQuestionsPath({ organizationId, testId })}
      />
      <TestNavLink
        text="Access"
        path={organizationTestAccessPath({ organizationId, testId })}
      />
      <TestNavLink
        text="Responses"
        path={organizationTestResponsesPath({ organizationId, testId })}
      />
    </div>
  )
}

const TestNavLink = ({ text, path }: { text: string; path: string }) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        cn("text-gray-999 font-medium", {
          "text-gray-333": isActive,
        })
      }
    >
      {text}
    </NavLink>
  )
}
