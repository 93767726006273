import * as Sentry from "@sentry/react"
import { maybeGetMetaVar } from "./getMetaVar"

const sentryJsDsn = maybeGetMetaVar("sentry-js-dsn")
const sentryEnv = maybeGetMetaVar("sentry-env")

if (sentryJsDsn) {
  Sentry.init({
    dsn: "https://9bf66a0355673cfe424d37c0fcd35bbe@o4505950240964608.ingest.us.sentry.io/4507742732615680",
    integrations: [],
    environment: sentryEnv || "development",
  })
}
