import { useState } from "react"
import { PageHeader } from "~/ui/PageHeader"
import { organizationTestResponsePath } from "~/common/paths"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { CandidateTests } from "~/tests/CandidateTests"
import {
  CandidateTestSortEnum,
  CandidateTestsOrganizationFilter,
} from "~/__generated__/graphql"
import { CandidateTestsTableFilter } from "~/tests/CandidateTestsTableFilter"

export const OrganizationCandidatesScreen = () => {
  const { organizationId } = useParams()

  const [filter, setFilter] = useState<CandidateTestsOrganizationFilter>({})

  invariant(organizationId)

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title="Candidates"
        subhead="Candidate who have responded to my tests"
      />
      <CandidateTestsTableFilter
        organizationId={organizationId}
        filter={filter}
        onFilterChange={setFilter}
      />
      <CandidateTests
        columns={[
          "candidate",
          "test",
          "timeTakenSeconds",
          "manualScore",
          "aiScore",
          "actions",
        ]}
        defaultSort={CandidateTestSortEnum.CompletedAt}
        organizationId={organizationId}
        filter={filter}
        viewPath={(candidateTest) =>
          organizationTestResponsePath({
            organizationId,
            testId: candidateTest.test.id,
            candidateTestId: candidateTest.id,
          })
        }
      />
    </div>
  )
}
