import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { QuestionGroupForm } from "~/questions/QuestionGroupForm/QuestionGroupForm"

export const OrganizationQuestionGroupCreateScreen = () => {
  const { test } = useOrganizationTest()

  return (
    <OrganizationTestLayout test={test} heading="Add a Question">
      <div>
        <QuestionGroupForm />
      </div>
    </OrganizationTestLayout>
  )
}
