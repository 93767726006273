import MicIcon from "~/images/icons/mic.svg?react"
import { Button } from "~/shadcn/ui/button"
import { VideoCanvas } from "./VideoCanvas"
import { useRecording } from "./RecordingProvider"
import { useEffect } from "react"
import { AVDeviceSelector } from "~/testFlow/AVDeviceSelector"
import { useTestFlow } from "~/testFlow/TestFlowProvider"

export const VideoPreviewModule = ({ setup = false }: { setup?: boolean }) => {
  const { candidateTest } = useTestFlow()
  const { avController, audioEnabled, videoEnabled, microphoneTestEnabled } =
    useRecording()

  const videoRequired = candidateTest?.test.requireVideo
  const audioRequired = candidateTest?.test.requireAudio

  useEffect(() => {
    if (!videoRequired) return
    avController.configureCanvas()
  }, [avController, videoRequired])

  if (!videoRequired && !audioRequired) return null

  return (
    <div className="ml-8 w-1/3">
      <div className="p-6 border border-gray-E6E6E3 rounded">
        {videoRequired && (
          <>
            <RecordingIndicator
              mediaType="Video"
              enabled={videoEnabled}
              className="mb-6"
            />
            <VideoCanvas
              avController={avController}
              videoEnabled={videoEnabled}
            />
          </>
        )}

        {!videoRequired && audioRequired && (
          <RecordingIndicator mediaType="Audio" enabled={audioEnabled} />
        )}

        {audioRequired && setup && (
          <>
            <Button
              type="button"
              variant="outline"
              className="w-full mt-6"
              onClick={
                microphoneTestEnabled
                  ? avController.disableMicrophoneTest
                  : avController.enableMicrophoneTest
              }
              disabled={!audioEnabled}
            >
              <MicIcon height={20} width={20} className="mr-2" />
              {microphoneTestEnabled ? "Stop Testing" : "Test Microphone"}
            </Button>
            <audio id={avController.MICROPHONE_TEST_ELEMENT_ID} />
          </>
        )}
      </div>

      {setup && <AVDeviceSelector />}
    </div>
  )
}

const RecordingIndicator = ({
  enabled,
  mediaType,
  className,
}: {
  enabled: boolean
  mediaType: "Video" | "Audio"
  className?: string
}) => (
  <div className={className}>
    {enabled ? (
      <div className="flex items-center gap-2">
        <div className="bg-red-600 h-[8px] w-[8px] rounded-full" />
        <div>Recording {mediaType}</div>
      </div>
    ) : (
      `No ${mediaType} Detected`
    )}
  </div>
)
