import { AVController } from "./AVController"

export const VideoCanvas = ({
  avController,
  videoEnabled,
}: {
  avController: AVController
  videoEnabled: boolean
}) => (
  <>
    <div
      id={avController.VIDEO_ELEMENT_ID}
      style={{
        aspectRatio: avController.WIDTH / avController.HEIGHT,
        width: "100%",
        display: videoEnabled ? undefined : "none",
      }}
      className="border border-gray-E6E6E3 rounded overflow-hidden"
    />
    <div
      style={{
        aspectRatio: avController.WIDTH / avController.HEIGHT,
        width: "100%",
        display: videoEnabled ? "none" : undefined,
      }}
      className="border border-black bg-black rounded text-white p-6 flex flex-col justify-center gap-2"
    >
      <div className="text-center">Camera is disabled</div>
      <div className="text-sm text-center">
        Once video is enabled you will see yourself here.
      </div>
    </div>
  </>
)
