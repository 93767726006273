import TrashIcon from "~/images/icons/trash.svg?react"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"
import { Button } from "~/shadcn/ui/button"
import { ConfirmDialog } from "~/ui/Confirm"

export const ArchiveQuestionGroupButton = ({
  questionGroupId,
  questionGroupName,
}: {
  questionGroupId: string
  questionGroupName: string
}) => {
  const [questionGroupArchive, { loading: loadingQuestionGroupArchive }] =
    useSafeMutation(QUESTION_GROUP_ARCHIVE_MUTATION)

  const handleArchiveClick = async (questionGroupId: string) => {
    const { data, errors } = await questionGroupArchive({
      variables: {
        input: {
          questionGroupId: questionGroupId,
          archive: true,
        },
      },
    })

    if (errors) {
      toast.error("Error archiving question.")
      console.log(errors)
    } else if (data?.questionGroupArchive?.questionGroup) {
      toast.success("Question has been archived.")
    } else {
      toast.error("Error archiving question.")
    }
  }

  return (
    <ConfirmDialog
      text={`Archive ${questionGroupName}?`}
      onSuccess={() => handleArchiveClick(questionGroupId)}
    >
      <Button
        variant="outline"
        size="sm"
        disabled={loadingQuestionGroupArchive}
      >
        <TrashIcon className="h-4 w-4" />
      </Button>
    </ConfirmDialog>
  )
}

const QUESTION_GROUP_ARCHIVE_MUTATION = gql(`
  mutation QuestionGroupArchive($input: QuestionGroupArchiveInput!) {
    questionGroupArchive(input: $input) {
      questionGroup {
        id
        discardedAt
      }
    }
  }
`)
