import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/shadcn/ui/dialog"
import ChevronRightIcon from "~/images/icons/chevron-right.svg?react"
import { cn } from "~/common/shadcn-utils"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"

export const OrganizationUserCreateButton = ({
  organizationId,
  onSuccess,
}: {
  organizationId: string
  onSuccess: () => void
}) => {
  const [email, setEmail] = useState("")
  const [hasError, setHasError] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const [createOrganizationUser, { loading }] = useSafeMutation(
    ORGANIZATION_USER_CREATE_MUTATION
  )
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!email) {
      setHasError(true)
      toast.error("Please provide an email address.")
      return
    }

    const { data, errors } = await createOrganizationUser({
      variables: {
        input: {
          organizationId: organizationId,
          email,
        },
      },
    })

    if (errors) {
      toast.error("Error adding user.")
      setHasError(true)
      console.log(errors)
    } else if (data?.organizationUserCreate?.organizationUser) {
      setHasError(false)
      setEmail("")
      setDialogOpen(false)
      onSuccess()
      toast.success("User added.")
    } else {
      setHasError(true)
      toast.error("Error adding user.")
    }
  }
  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild={true}>
        <Button className="flex flex-row gap-2">
          Add User
          <ChevronRightIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-lg font-medium text-gray-333">
            Add User
          </DialogTitle>
        </DialogHeader>
        <form className="flex flex-col gap-6" onSubmit={(e) => handleSubmit(e)}>
          <div className="flex flex-col gap-2">
            <label className="text-gray-999">Email</label>
            <input
              type="email"
              name="email"
              className={cn(
                "w-full border-1 border-gray-E3E2E0 rounded",
                hasError ? "border-red-500" : ""
              )}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className="flex flex-row gap-2 items-center justify-between">
            <Button type="submit" disabled={loading}>
              Add User
            </Button>
            <Button
              type="button"
              onClick={() => setDialogOpen(false)}
              variant="ghost"
            >
              Close & Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

const ORGANIZATION_USER_CREATE_MUTATION = gql(`
  mutation OrganizationUserCreate($input: OrganizationUserCreateInput!) {
    organizationUserCreate(input: $input) {
      organizationUser {
        id
      }
    }
  }
`)
