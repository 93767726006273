import { CandidateTest } from "~/__generated__/graphql"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { gql } from "@apollo/client"
import { ConfirmDialog } from "~/ui/Confirm"
import { useState } from "react"
import { ORG_CANDIDATE_TESTS_COUNT_QUERY } from "~/layouts/AppLayout"

export const ToggleArchivedButton = ({
  candidateTest,
  onChange,
}: {
  candidateTest: Pick<CandidateTest, "id" | "archivedAt">
  onChange?: (candidateTestId: string) => void
}) => {
  const [updateArchivedAt, { loading }] = useSafeMutation(
    CANDIDATE_TEST_TOGGLE_ARCHIVED_AT,
    {
      refetchQueries: [ORG_CANDIDATE_TESTS_COUNT_QUERY],
    }
  )

  const handleArchivedAtChange = async () => {
    const { data, errors } = await updateArchivedAt({
      variables: {
        input: {
          candidateTestId: candidateTest.id,
        },
      },
    })

    if (!data?.candidateTestToggleArchivedAt?.candidateTest) {
      toast.error("Error toggling archived at.")
      if (errors) {
        console.log(errors)
      }
    }
    onChange && onChange(candidateTest.id)
    setOpen(false)
  }
  const [open, setOpen] = useState(false)

  const labelText = candidateTest.archivedAt ? "Unarchive" : "Archive"

  return (
    <>
      <ConfirmDialog
        text={`Are you sure you want to ${labelText.toLowerCase()} this candidate?`}
        open={open}
        onOpenChange={setOpen}
        onSuccess={handleArchivedAtChange}
      />
      <Button
        onClick={() => setOpen(true)}
        disabled={loading}
        variant="outline"
        size="sm"
      >
        {labelText}
      </Button>
    </>
  )
}

const CANDIDATE_TEST_TOGGLE_ARCHIVED_AT = gql(`
  mutation CandidateTestToggleArchivedAt($input: CandidateTestToggleArchivedAtInput!) {
    candidateTestToggleArchivedAt(input: $input) {
      candidateTest {
        id
        archivedAt
      }
    }
  }
`)
