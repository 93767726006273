import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { getMetaVar } from "~/common/getMetaVar"
import { FileWithPath, useDropzone } from "react-dropzone"
import toast from "react-hot-toast"
import { useCallback, useState } from "react"
import { directImageUpload } from "~/common/directImageUpload"
import { cn } from "~/common/shadcn-utils"
import { sleep } from "~/common/sleep"
import { LoadingIndicator } from "~/components/LoadingIndicator"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { GraphQLError } from "graphql"
import { ApolloError } from "@apollo/client"
import { displayErrors } from "~/common/validations"
import { useNavigate } from "react-router-dom"
import { organizationTestQuestionsPath } from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"

const importTemplateUrl = getMetaVar("question-import-template")

export const OrganizationTestQuestionImportScreen = () => {
  const organizationId = useOrganizationId()
  const { test, testId } = useOrganizationTest()
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const navigate = useNavigate()

  const [runQuestionImport] = useSafeMutation(QUESTION_IMPORT_MUTATION)

  const onFileAdd = useCallback(
    async (file: FileWithPath) => {
      setIsSaving(true)

      try {
        const directUploadJson = await directImageUpload(file)
        const { signedId } = directUploadJson

        await sleep(1000)

        const { data, errors } = await runQuestionImport({
          variables: {
            input: {
              testId,
              csvSignedId: signedId,
            },
          },
        })

        if (errors) {
          const lineErrors = extractCsvErrors(errors)
          if (lineErrors.length > 0) {
            setErrorMessages(lineErrors)
          } else {
            displayErrors(errors)
          }
        } else {
          toast.success(
            `${data?.questionImport.createdCount} questions imported`
          )
          navigate(organizationTestQuestionsPath({ organizationId, testId }))
        }
        console.log("upload success", signedId)
      } catch (e) {
        toast.error("Error uploading file.")
      }

      setIsSaving(false)
    },
    [runQuestionImport, testId, organizationId, navigate]
  )

  const dropzoneBag = useDropzone({
    accept: {
      "text/csv": [],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 1000000,
    onDropRejected: (_fileRejections, _event) => {
      toast.error("Only files under 1mb are accepted.")
    },
    onDrop: (files) => {
      files.forEach((file) => onFileAdd(file))
    },
  })

  return (
    <OrganizationTestLayout
      test={test}
      heading="Question Import"
      subhead="Import questions from a CSV"
    >
      {isSaving ? (
        <div className="flex items-center gap-4">
          <LoadingIndicator />
          Uploading your questions...
        </div>
      ) : (
        <>
          <div
            {...dropzoneBag.getRootProps()}
            className={cn(
              "px-6 py-6 border-2 border-dashed border-gray-400 mb-8",
              {
                "ring-2": dropzoneBag.isDragActive,
              }
            )}
          >
            <input {...dropzoneBag.getInputProps()} />
            Drag image files or click here to upload
          </div>

          <a href={importTemplateUrl} className="text-primary underline">
            Download template
          </a>

          {errorMessages.length > 0 && (
            <div className="text-communist mt-4">
              <div className="font-semibold">
                Imported CSV has the following errors:
              </div>
              <ul className="list-disc ml-6">
                {errorMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </OrganizationTestLayout>
  )
}

const QUESTION_IMPORT_MUTATION = gql(`
  mutation QuestionImport($input: QuestionImportInput!) {
    questionImport(input: $input) {
      createdCount
    }
  }
`)

const extractCsvErrors = (_errors: readonly GraphQLError[]): string[] => {
  // @ts-ignore
  const errors = _errors as ApolloError

  // @ts-ignore
  return errors.graphQLErrors
    .map((graphqlError) => graphqlError.extensions)
    .flat()
    .map((error) => error.csvErrors)
    .flat()
}
