import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { SelectField } from "~/ui/forms/SelectField"
import { TextareaField } from "~/ui/forms/TextareaField"
import { useAIWizard } from "./AIWizardProvider"
import { FormPanel } from "~/questions/QuestionGroupForm/FormPanel"
import { Form } from "~/shadcn/ui/form"
import { Divider } from "~/ui/Divider"
import { Button } from "~/shadcn/ui/button"
import { Wand2 } from "lucide-react"

const formSchema = z.object({
  roleDescription: z.string().min(1, "Please provide a role description"),
  desiredSkills: z.string().min(1, "Please provide desired skills"),
  exampleQuestions: z.string(),
  numberOfQuestions: z.coerce.number().min(1).max(25),
})

export type PromptsFormValues = z.infer<typeof formSchema>

export const PromptsForm = () => {
  const { prompts, setPrompts, loading } = useAIWizard()
  const form = useForm<PromptsFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      roleDescription: prompts?.roleDescription || "",
      desiredSkills: prompts?.desiredSkills || "",
      exampleQuestions: prompts?.exampleQuestions || "",
      numberOfQuestions: `${prompts?.numberOfQuestions || "10"}` as any, // zod doesn't play nice with a coerced number from a select value
    },
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(setPrompts)}
        className="flex flex-col pb-12"
      >
        <FormPanel className="gap-4">
          <TextareaField
            control={form.control}
            name="roleDescription"
            label="Describe the role you're testing for"
            placeholder="Type your response here, the more detailed you can be the better the results..."
            rows={3}
            required
          />
          <TextareaField
            control={form.control}
            name="desiredSkills"
            label="What type of skills and abilities do you want to test for?"
            placeholder="Please list all of the required skills and abilities..."
            rows={3}
            required
          />
          <TextareaField
            control={form.control}
            name="exampleQuestions"
            label="Provide a few examples of questions you would like to ask"
            placeholder="Provide samples questions here..."
            rows={3}
          />
          <SelectField
            control={form.control}
            name="numberOfQuestions"
            label="How many questions do you want generated?"
            selectEntries={new Array(25).fill(0).map((_, i) => ({
              label: `${i + 1}`,
              value: `${i + 1}`,
            }))}
          />
        </FormPanel>
        <Divider className="my-10" />

        <div className="flex justify-between">
          <Button type="submit" disabled={loading}>
            <Wand2 className="w-5 h-5 mr-2" />
            Generate Test Questions
          </Button>
          <div></div>
        </div>
      </form>
    </Form>
  )
}
