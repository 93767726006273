import { HeaderNavigation } from "~/ui/HeaderNavigation"
import { Footer } from "~/ui/Footer"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { usePromotionalTests } from "~/common/usePromotionalTests"
import { PromotionalTestTable } from "~/components/PromotionalTestTable"
import { LinkButton } from "~/shadcn/ui/button"
import { createAccountPath, promotionalTestsPath } from "~/common/paths"
import heroSlide1 from "~/images/marketing/hero-slide-1.png"
import heroSlide2 from "~/images/marketing/hero-slide-2.png"
import heroSlide3 from "~/images/marketing/hero-slide-3.png"
import { ScrollRestoration } from "react-router-dom"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselDots,
} from "~/shadcn/ui/carousel"
import Autoplay from "embla-carousel-autoplay"
import {
  Section,
  QuarterWidth,
  SectionHeaderText,
  AboutSection,
  BuildATestSection,
  KeyFeaturesSection,
} from "~/ui/Marketing"

export const MarketingScreen = () => {
  const { currentUser } = useCurrentUserMaybe()
  const { promotionalTests } = usePromotionalTests({ first: 5 })

  return (
    <div id="home" className="flex-1 flex flex-col">
      <HeaderNavigation currentUser={currentUser}>
        <div className="mx-auto hidden md:flex items-center">
          <a
            className="px-6 py-4 rounded text-gray-333 font-medium hover:text-blue-500"
            href={"#build-a-test"}
          >
            Build Your Test
          </a>
          <a
            className="px-6 py-4 rounded text-gray-333 font-medium hover:text-blue-500"
            href={"#tests"}
          >
            Free Community Tests
          </a>
          <a
            className="px-6 py-4 rounded text-gray-333 font-medium hover:text-blue-500"
            href={"#about"}
          >
            About SAMco
          </a>
        </div>
      </HeaderNavigation>

      <main>
        <Section id="hero" reverse mobileReverse className="items-center">
          <QuarterWidth quarters={2} className="py-0">
            <Carousel
              orientation="vertical"
              opts={{
                loop: true,
              }}
              plugins={[Autoplay()]}
            >
              <CarouselContent className="items-center max-h-[670px] content-stretch">
                <CarouselItem className="min-h-[670px] flex items-center">
                  <img
                    src={heroSlide1}
                    alt="Identify and Recognize Talent"
                    className="w-full h-auto"
                  />
                </CarouselItem>
                <CarouselItem className="min-h-[670px] flex items-center">
                  <img
                    src={heroSlide2}
                    alt="Identify and Recognize Talent"
                    className="w-full h-auto"
                    loading="lazy"
                  />
                </CarouselItem>
                <CarouselItem className="min-h-[670px] flex items-center">
                  <img
                    src={heroSlide3}
                    alt="Identify and Recognize Talent"
                    className="w-full h-auto"
                    loading="lazy"
                  />
                </CarouselItem>
              </CarouselContent>
              <CarouselDots />
            </Carousel>
          </QuarterWidth>

          <QuarterWidth quarters={2}>
            <SectionHeaderText>
              Identify Talent From Anywhere.
            </SectionHeaderText>
            <SectionHeaderText color="text-gray-999">
              Evaluate Abilities, Not Resumes.
            </SectionHeaderText>
            <p className="my-6">
              Standard Aptitude is the modern testing platform for demonstrating
              actual abilities regardless of where it comes from.
            </p>
            <LinkButton className="mt-4" to={createAccountPath.pattern}>
              For Organization: Build Your Test
            </LinkButton>
          </QuarterWidth>
        </Section>

        <BuildATestSection />

        <KeyFeaturesSection />

        <Section id="tests" backgroundColor="bg-gray-F7F7F5">
          <QuarterWidth quarters={4}>
            <SectionHeaderText>Community tests</SectionHeaderText>
            <SectionHeaderText color="text-gray-999">
              Take Your First Test & Demonstrate What You Can Do
            </SectionHeaderText>
            <p className="my-6 text-gray-333">
              The majority of Standard Aptitude tests are invite only, but more
              and more organizations make their tests public - so anyone can
              demonstrate what they can do. Here is a list of open community
              tests you can take now and prove your abilities:
            </p>
            <PromotionalTestTable header={false} tests={promotionalTests} />
            <LinkButton className="mt-8" to={promotionalTestsPath.pattern}>
              More Community Tests
            </LinkButton>
          </QuarterWidth>
        </Section>

        <AboutSection />
      </main>

      <div className="mx-auto max-w-[1024px] w-full">
        <Footer />
      </div>
      <ScrollRestoration />
    </div>
  )
}
