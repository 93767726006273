import { CheckCircle2, ChevronDown, Video, Volume2 } from "lucide-react"
import { useRecording } from "~/av/RecordingProvider"
import { useMemo, useState } from "react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"
import { useTestFlow } from "./TestFlowProvider"

export const AVDeviceSelector = () => {
  const { avController, audioDeviceId, videoDeviceId } = useRecording()
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const { candidateTest } = useTestFlow()
  const videoRequired = candidateTest?.test.requireVideo
  const audioRequired = candidateTest?.test.requireAudio

  const fetchDevices = async () => {
    const _devices = await avController.fetchDevices()
    setDevices(_devices)
  }

  const audioDevices = useMemo(() => {
    const _audioDevices = devices.filter((d) => d.kind === "audioinput")

    if (_audioDevices.length > 0) {
      return _audioDevices
    } else {
      return [{ deviceId: null, label: "Default" }]
    }
  }, [devices])

  const videoDevices = useMemo(() => {
    const _videoDevices = devices.filter((d) => d.kind === "videoinput")

    if (_videoDevices.length > 0) {
      return _videoDevices
    } else {
      return [{ deviceId: null, label: "Default" }]
    }
  }, [devices])

  return (
    <div className="flex items-center justify-center gap-8 py-6">
      {audioRequired && (
        <DropdownMenu onOpenChange={fetchDevices}>
          <DropdownMenuTrigger asChild>
            <div className="flex items-center gap-2 cursor-pointer text-sm text-gray-999">
              <Volume2 size={14} /> Audio Device <ChevronDown size={12} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Audio Devices</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {audioDevices.map((o) => (
              <DropdownMenuItem
                asChild
                key={o.deviceId}
                onClick={() => avController.setAudioDevice(o.deviceId)}
              >
                <div className="cursor-pointer">
                  {o.label}
                  {o.deviceId === audioDeviceId && (
                    <CheckCircle2 size={16} className="text-primary ml-2" />
                  )}
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}

      {videoRequired && (
        <DropdownMenu onOpenChange={fetchDevices}>
          <DropdownMenuTrigger asChild>
            <div className="flex items-center gap-2 cursor-pointer text-sm text-gray-999">
              <Video size={14} /> Video Display <ChevronDown size={12} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Video Devices</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {videoDevices.map((o) => (
              <DropdownMenuItem
                asChild
                key={o.deviceId}
                onClick={() => avController.setVideoDevice(o.deviceId)}
              >
                <div className="cursor-pointer">
                  {o.label}
                  {o.deviceId === videoDeviceId && (
                    <CheckCircle2 size={16} className="text-primary ml-2" />
                  )}
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}
