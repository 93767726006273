import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { AccessForm } from "~/tests/AccessForm"

export const OrganizationTestAccessScreen = () => {
  const { test } = useOrganizationTest()

  return (
    <OrganizationTestLayout
      test={test}
      heading="Access"
      subhead="Who has access to view or edit permissions for this test?"
    >
      {test && <AccessForm test={test} />}
    </OrganizationTestLayout>
  )
}
