import { Button } from "~/shadcn/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogFooter,
  DialogPortal,
} from "~/shadcn/ui/dialog"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form } from "~/shadcn/ui/form"
import { TextareaField } from "~/ui/forms/TextareaField"
import { displayErrors } from "~/common/validations"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"

const formSchema = z.object({
  disclaimerText: z.string().min(1, {
    message: "Required",
  }),
})

export type DisclaimerDialogStateType = {
  dialogOpen: boolean
  testId: string
  disclaimerId: string | null
  disclaimerText: string
}

export const DisclaimerDialogForm = ({
  testId,
  disclaimerId,
  disclaimerText,
  dialogOpen,
  setDialogOpen,
  onSuccess,
}: {
  testId: string
  disclaimerId: string | null
  disclaimerText: string
  dialogOpen: boolean
  setDialogOpen: (val: boolean) => void
  onSuccess: () => void
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      disclaimerText: disclaimerText,
    },
  })

  const [disclaimerUpdate, { loading: loadingUpdate }] = useSafeMutation(
    DISCLAIMER_UPDATE_MUTATION
  )

  const submitUpdateDisclaimer = async (
    disclaimerId: string,
    values: z.infer<typeof formSchema>
  ) => {
    const { data, errors } = await disclaimerUpdate({
      variables: {
        input: {
          disclaimerId: disclaimerId,
          ...values,
        },
      },
    })
    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.disclaimerUpdate?.disclaimer) {
      toast.success("Test disclaimer updated.")
      onOpenChange(false)
      onSuccess()
    } else {
      toast.error("Error updating disclaimer.")
    }
  }

  const [disclaimerCreate, { loading: loadingCreate }] = useSafeMutation(
    DISCLAIMER_CREATE_MUTATION
  )

  const submitCreateDisclaimer = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await disclaimerCreate({
      variables: {
        input: {
          testId: testId,
          ...values,
        },
      },
    })
    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.disclaimerCreate?.disclaimer) {
      toast.success("Test disclaimer added.")
      onOpenChange(false)
      onSuccess()
    } else {
      toast.error("Error adding disclaimer.")
    }
  }

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (disclaimerId) {
      submitUpdateDisclaimer(disclaimerId, values)
    } else {
      submitCreateDisclaimer(values)
    }
  }

  const onOpenChange = (open: boolean) => {
    if (!open) {
      form.reset({ disclaimerText: "" })
    }
    setDialogOpen(open)
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <DialogHeader>
                <DialogTitle>
                  {disclaimerId ? "Update" : "Add"} Test Disclaimer
                </DialogTitle>
              </DialogHeader>

              <div className="">
                <TextareaField
                  inputClassName="w-full h-96"
                  labelClassName="sr-only"
                  control={form.control}
                  name="disclaimerText"
                  label="Disclaimer"
                  placeholder="Paste your test disclaimer here..."
                />
              </div>

              <DialogFooter className="sm:justify-between">
                <Button type="submit" disabled={loadingUpdate || loadingCreate}>
                  Save Disclaimer
                </Button>
                <DialogClose asChild>
                  <Button type="button" variant="ghost">
                    Close & Cancel
                  </Button>
                </DialogClose>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

const DISCLAIMER_UPDATE_MUTATION = gql(`
  mutation DisclaimerUpdate($input: DisclaimerUpdateInput!) {
    disclaimerUpdate(input: $input) {
      disclaimer {
        id
        disclaimerText
      }
    }
  }
`)

const DISCLAIMER_CREATE_MUTATION = gql(`
  mutation DisclaimerCreate($input: DisclaimerCreateInput!) {
    disclaimerCreate(input: $input) {
      disclaimer {
        id
        disclaimerText
      }
    }
  }
`)
