import { Divider } from "~/ui/Divider"
import { ChevronLeft } from "lucide-react"
import { Link } from "react-router-dom"
import { candidateTestsPath } from "~/common/paths"
import { VideoPreviewModule } from "~/av/VideoPreviewModule"
import { useTestFlow } from "./TestFlowProvider"
import { LinkButton } from "~/shadcn/ui/button"
import { Markdown } from "~/ui/Markdown"

export const TestComplete = () => {
  const { candidateTest } = useTestFlow()

  return (
    <div className="container mx-auto">
      <div className="py-3 text-gray-999 flex justify-start">
        <Link to={candidateTestsPath.pattern} className="flex items-center">
          <ChevronLeft className="mr-2 mt-0.5" size={16} />
          Tests
        </Link>
      </div>
      <Divider className="mb-16" />

      <div className="flex items-start">
        <div className="flex-1">
          <div className="bg-gray-F7F7F5 p-6 rounded">
            <div className="text-gray-999 mb-6">Test Complete 🎉</div>
            <div className="text-gray-333 text-2xl font-semibold">
              {candidateTest?.test.closingNotes ? (
                <Markdown>{candidateTest.test.closingNotes}</Markdown>
              ) : (
                `Thank you for completing "${candidateTest?.test.name}". Your
              responses will be submitted.`
              )}
            </div>
          </div>
        </div>

        <VideoPreviewModule />
      </div>

      <Divider className="my-10" />
      <LinkButton to={candidateTestsPath.pattern}>Submit Test</LinkButton>
    </div>
  )
}
