import { useMemo } from "react"
import sha256 from "crypto-js/sha256"
import { cn } from "~/common/shadcn-utils"

export const GravatarImage = ({
  name,
  email,
  className,
}: {
  name: string | null | undefined
  email: string
  className?: string
}) => {
  const gravatarUrl = useMemo(() => {
    const hash = sha256(email)
    return `https://www.gravatar.com/avatar/${hash}?d=mp`
  }, [email])

  return (
    <img
      src={gravatarUrl}
      alt={name || "profile photo"}
      className={cn("w-[42px] h-[42px] rounded-full", className)}
    />
  )
}
