import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  adminCandidateTestPath,
  adminQuestionResponsePath,
} from "~/common/paths"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import { QuestionNavigator } from "~/questions/QuestionNavigator"
import { QuestionResponseDetails } from "~/questions/QuestionResponseDetails"
import { Error } from "~/ui/Error"
import { PageHeader } from "~/ui/PageHeader"

export const AdminQuestionResponseScreen = () => {
  const { questionResponseId } = useParams()
  invariant(questionResponseId)

  const { loading, data, error } = useQuery(
    ADMIN_QUESTION_RESPONSE_QUERY_DOCUMENT,
    {
      variables: { questionResponseId },
    }
  )

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading response." />

  const questionResponse = data.questionResponse
  const { question, questionGroup, candidateTest } = questionResponse
  const { test } = candidateTest
  const { organization } = test

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title={`${questionGroup.name}: ${question.questionCopy}`}
        subhead={`${test.name} - ${organization.name}`}
      />
      <CandidateTestLayout
        test={test}
        candidateTest={candidateTest}
        backLabel="Return to Candidate Test Overview"
        backPath={adminCandidateTestPath({ candidateTestId: candidateTest.id })}
        rightModule={
          <QuestionNavigator
            questionResponse={questionResponse}
            viewPath={(questionResponseId) =>
              adminQuestionResponsePath({ questionResponseId })
            }
          />
        }
      >
        <QuestionResponseDetails questionResponse={questionResponse} />
        <QuestionNavigator
          questionResponse={questionResponse}
          viewPath={(questionResponseId) =>
            adminQuestionResponsePath({ questionResponseId })
          }
        />
      </CandidateTestLayout>
    </div>
  )
}

const ADMIN_QUESTION_RESPONSE_QUERY_DOCUMENT = gql(`
  query AdminQuestionResponse($questionResponseId: ID!) {
    questionResponse(questionResponseId: $questionResponseId) {
      ...QuestionResponse_Details
    }
  }
`)
