import { Divider } from "~/ui/Divider"
import { VideoPreviewModule } from "~/av/VideoPreviewModule"
import { useTestFlow } from "./TestFlowProvider"
import invariant from "tiny-invariant"
import { QuestionTypeEnum } from "~/__generated__/graphql"
import { TextResponseForm } from "./TextResponseForm"
import { VideoResponseForm } from "./VideoResponseForm"
import { SingleChoiceResponseForm } from "./SingleChoiceResponseForm"
import { MultipleChoiceResponseForm } from "./MultipleChoiceResponseForm"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useEffect } from "react"
import { Timer, useSecondsRemaining } from "./Timer"

const QUESTION_TYPE_COMPONENTS: Record<
  QuestionTypeEnum,
  React.FC<{ timeExpired: boolean }>
> = {
  [QuestionTypeEnum.TextResponse]: TextResponseForm,
  [QuestionTypeEnum.SingleChoice]: SingleChoiceResponseForm,
  [QuestionTypeEnum.MultipleChoice]: MultipleChoiceResponseForm,
  [QuestionTypeEnum.VideoResponse]: VideoResponseForm,
}

export const TestFlowQuestionForm = () => {
  const { currentAttempt, candidateTest } = useTestFlow()
  invariant(candidateTest, "candidateTest expected")

  const [questionAttemptCheckIn] = useSafeMutation(
    QUESTION_ATTEMPT_CHECK_IN_MUTATION
  )
  const questionType = currentAttempt?.question.questionGroup.questionType
  const QuestionTypeComponent = questionType
    ? QUESTION_TYPE_COMPONENTS[questionType]
    : null
  const secondsRemaining = useSecondsRemaining({ currentAttempt })
  const timeExpired = secondsRemaining === 0

  useEffect(() => {
    if (!currentAttempt) return

    const intervalId = setInterval(() => {
      questionAttemptCheckIn({
        variables: { input: { questionAttemptId: currentAttempt.id } },
      })
    }, 1000)

    return () => clearInterval(intervalId)
  }, [currentAttempt, questionAttemptCheckIn])

  return (
    <div className="container mx-auto pb-12">
      <div className="py-3 text-gray-999 flex items-center">
        <div className="flex-1">{candidateTest.test.name}</div>
      </div>
      <Divider className="mb-16" />

      <div className="flex items-start">
        <div className="flex-1">
          {timeExpired && (
            <div className="bg-communist text-white rounded p-4 text-center mb-8 text-sm">
              Your time has expired for this question.
            </div>
          )}

          <div className="bg-gray-F7F7F5 border border-gray-E6E6E3 rounded p-6 mb-6">
            <div className="mb-6 text-gray-999 flex items-center">
              <div className="flex-1">
                Question {candidateTest.currentQuestionNumber} of{" "}
                {candidateTest.questionCount}
              </div>

              {secondsRemaining !== null && (
                <Timer secondsRemaining={secondsRemaining} />
              )}
            </div>
            <div className="font-weight font-semibold text-2xl text-gray-333">
              {currentAttempt?.question.questionCopy}
            </div>
          </div>

          {QuestionTypeComponent && (
            <QuestionTypeComponent timeExpired={timeExpired} />
          )}
        </div>

        <VideoPreviewModule />
      </div>
    </div>
  )
}

const QUESTION_ATTEMPT_CHECK_IN_MUTATION = gql(`
  mutation QuestionAttemptCheckIn($input: QuestionAttemptCheckInInput!) {
    questionAttemptCheckIn(input: $input) {
      candidateTest {
        ...TestFlow_CandidateTest
      }
    }
  }
`)
