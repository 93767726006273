import { LogoText } from "~/ui/LogoText"
import { Link } from "react-router-dom"
import { LinkButton } from "~/shadcn/ui/button"
import {
  signInPath,
  createAccountPath,
  candidateTestPath,
  candidateTestsPath,
  createOrganizationPath,
  organizationTestsPath,
  signOutPath,
  loadingPath,
  adminCandidatesPath,
  userProfilePath,
} from "~/common/paths"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"
import { GravatarImage } from "~/ui/GravatarImage"
import {
  Maybe,
  Organization,
  CurrentUserProviderQuery,
} from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { matchRoutes } from "react-router-dom"

export const HeaderNavigation = ({
  children,
  currentUser,
  currentOrganization,
  containerClassName,
  admin,
}: {
  children?: React.ReactNode
  currentUser?: CurrentUserProviderQuery["currentUser"]
  currentOrganization?: Maybe<Pick<Organization, "id" | "name">>
  containerClassName?: string
  admin?: boolean
}) => {
  const matches = matchRoutes(
    [
      { path: candidateTestPath.pattern },
      { path: signInPath.pattern },
      { path: createAccountPath.pattern },
    ],
    window.location.pathname
  )
  const hideNavigation = matches && matches.length > 0

  return (
    <nav
      className={cn(
        "px-6 py-4 flex items-center justify-between",
        containerClassName
      )}
    >
      <Link
        className="block"
        to={
          currentOrganization
            ? organizationTestsPath({
                organizationId: currentOrganization.id,
              })
            : loadingPath.pattern
        }
      >
        <LogoText admin={admin} />
      </Link>

      {children}

      {currentUser ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex items-center gap-4 cursor-pointer">
              <div className="flex flex-col items-end">
                <div
                  className={cn(
                    "text-gray-333 font-medium",
                    admin && "text-white"
                  )}
                >
                  {currentUser.name}
                </div>
                {currentOrganization && (
                  <div
                    className={cn(
                      "text-gray-999 text-sm",
                      admin && "text-white"
                    )}
                  >
                    {currentOrganization?.name}
                  </div>
                )}
              </div>
              <GravatarImage
                email={currentUser.email}
                name={currentUser.name}
              />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {currentUser.organizations.length > 0 ? (
              <>
                <DropdownMenuLabel>Organizations</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {currentUser.organizations.map(
                  (o: Pick<Organization, "id" | "name">) => (
                    <DropdownMenuItem asChild key={o.id}>
                      <Link
                        to={organizationTestsPath({ organizationId: o.id })}
                        className="cursor-pointer"
                      >
                        {o.name}
                      </Link>
                    </DropdownMenuItem>
                  )
                )}
                <DropdownMenuItem asChild>
                  <Link
                    to={createOrganizationPath.pattern}
                    className="cursor-pointer"
                  >
                    Create organization
                  </Link>
                </DropdownMenuItem>
              </>
            ) : (
              <>
                <DropdownMenuItem asChild>
                  <Link to={userProfilePath.pattern} className="cursor-pointer">
                    Settings
                  </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />

                <DropdownMenuItem asChild>
                  <Link
                    to={createOrganizationPath.pattern}
                    className="cursor-pointer"
                  >
                    Create a test
                  </Link>
                </DropdownMenuItem>
              </>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link to={candidateTestsPath.pattern} className="cursor-pointer">
                Take a test
              </Link>
            </DropdownMenuItem>
            {currentUser.admin && (
              <DropdownMenuItem asChild>
                <Link
                  to={adminCandidatesPath.pattern}
                  className="cursor-pointer"
                >
                  Admin
                </Link>
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <a href={signOutPath.pattern} className="cursor-pointer">
                Sign out
              </a>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        !hideNavigation && (
          <div className="items-center">
            <LinkButton
              className="btn mr-3"
              variant="secondary"
              type="button"
              to={signInPath.pattern}
            >
              Login
            </LinkButton>

            <LinkButton type="button" to={createAccountPath.pattern}>
              Get Started
            </LinkButton>
          </div>
        )
      )}
    </nav>
  )
}
