import invariant from "tiny-invariant"
import { MediaStreamComposer } from "~/vendor/api.video-typescript-media-stream-composer"
import WaveSurfer from "wavesurfer.js"
import RecordPlugin from "wavesurfer.js/dist/plugins/record"

export class AVController {
  WIDTH = 1280
  HEIGHT = 720
  AUDIO_DEVICE_ID_KEY = "audioDeviceId"
  VIDEO_DEVICE_ID_KEY = "videoDeviceId"
  MICROPHONE_TEST_ELEMENT_ID = "audio-capture"
  WAVEFORM_AUDIO_ELEMENT_ID = "waveform-audio-capture"
  WAVEFORM_DISPLAY_ELEMENT_ID = "waveform-display"
  VIDEO_ELEMENT_ID = "video-canvas-container"

  composer: MediaStreamComposer | null = null

  videoStreamId: string | null = null
  audioStreamId: string | null = null
  audioStream: MediaStream | null = null
  isRecording = false

  videoDeviceId: string | null = null
  audioDeviceId: string | null = null

  addVideoStreamPending = false
  addAudioStreamPending = false

  microphoneTestEnabled = false

  wavesurfer: WaveSurfer | null = null
  wavesurferRecorder: RecordPlugin | null = null

  get videoEnabled() {
    return !!this.videoStreamId
  }

  get audioEnabled() {
    return !!this.audioStreamId
  }

  constructor() {
    this.videoDeviceId = localStorage.getItem(this.VIDEO_DEVICE_ID_KEY)
    this.audioDeviceId = localStorage.getItem(this.AUDIO_DEVICE_ID_KEY)
  }

  initializeComposer = () => {
    this.composer = new MediaStreamComposer({
      resolution: {
        width: this.WIDTH,
        height: this.HEIGHT,
      },
    })
  }

  fetchDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices.filter(
      (device) => device.label.length > 0 && device.deviceId.length > 0
    )
  }

  enableVideo = async () => {
    console.log("enabling video")
    if (this.addVideoStreamPending) return
    if (this.videoEnabled) return
    if (!this.composer) this.initializeComposer()

    try {
      this.addVideoStreamPending = true
      const stream = await navigator.mediaDevices.getUserMedia({
        video: this.videoDeviceId ? { deviceId: this.videoDeviceId } : true,
      })
      this.videoStreamId = await this.composer!.addStream(stream, {
        position: "cover",
      })
      this.fetchDevices()
    } finally {
      this.addVideoStreamPending = false
    }

    this.configureCanvas()
  }

  disableVideo = async () => {
    console.log("disabling video")
    if (!this.videoStreamId) return

    await this.composer?.removeStream(this.videoStreamId)
    this.videoStreamId = null
  }

  enableAudio = async () => {
    console.log("enabling audio")
    if (this.addAudioStreamPending) return
    if (this.audioEnabled) return
    if (!this.composer) this.initializeComposer()

    try {
      this.addAudioStreamPending = true
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: this.audioDeviceId ? { deviceId: this.audioDeviceId } : true,
      })
      this.audioStreamId = await this.composer!.addAudioSource(this.audioStream)
      this.fetchDevices()
    } finally {
      this.addAudioStreamPending = false
    }
  }

  disableAudio = async () => {
    console.log("disabling audio")
    if (!this.audioStreamId) return
    this.disableMicrophoneTest()
    await this.composer?.removeAudioSource(this.audioStreamId)
    this.audioStreamId = null
    this.audioStream = null
  }

  enableWaveform = () => {
    if (!this.wavesurfer) {
      this.wavesurfer = WaveSurfer.create({
        container: document.getElementById(this.WAVEFORM_DISPLAY_ELEMENT_ID)!,
        waveColor: "#3783DB",
        backend: "MediaElement",
        barWidth: 6,
        barRadius: 6,
        barHeight: 1.5,
        barGap: 2,
      })
    }
    if (!this.wavesurferRecorder) {
      this.wavesurferRecorder = this.wavesurfer.registerPlugin(
        RecordPlugin.create()
      )
    }
    this.wavesurferRecorder.startRecording()

    console.log("enabling wavesurfer")
  }

  disableWaveform = () => {
    console.log("disabling wavesurfer")

    if (this.wavesurferRecorder) {
      this.wavesurferRecorder.stopRecording()
      this.wavesurferRecorder.destroy()
      this.wavesurferRecorder = null
    }
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
      this.wavesurfer = null
    }
  }

  enableMicrophoneTest = () => {
    if (!this.audioEnabled) return

    var audioElement = document.getElementById(
      this.MICROPHONE_TEST_ELEMENT_ID
    ) as HTMLAudioElement
    invariant(audioElement)

    audioElement.srcObject = this.audioStream
    audioElement.play()

    this.microphoneTestEnabled = true
  }

  disableMicrophoneTest = () => {
    var audioElement = document.getElementById(
      this.MICROPHONE_TEST_ELEMENT_ID
    ) as HTMLAudioElement
    if (!audioElement) return
    audioElement.srcObject = null
    this.microphoneTestEnabled = false
  }

  setAudioDevice = async (deviceId: string | null) => {
    const deviceWasEnabled = this.audioEnabled
    this.disableMicrophoneTest()
    await this.disableAudio()

    this.audioDeviceId = deviceId
    if (deviceId) {
      localStorage.setItem(this.AUDIO_DEVICE_ID_KEY, deviceId)
    } else {
      localStorage.removeItem(this.AUDIO_DEVICE_ID_KEY)
    }

    if (deviceWasEnabled) {
      await this.enableAudio()
    }
  }

  setVideoDevice = async (deviceId: string | null) => {
    const deviceWasEnabled = this.videoEnabled
    await this.disableVideo()

    this.videoDeviceId = deviceId
    if (deviceId) {
      localStorage.setItem(this.VIDEO_DEVICE_ID_KEY, deviceId)
    } else {
      localStorage.removeItem(this.VIDEO_DEVICE_ID_KEY)
    }

    if (deviceWasEnabled) {
      await this.enableVideo()
    }
  }

  startRecording = async (videoId: string) => {
    if (this.isRecording) return
    await this.composer!.startRecording({
      uploadToken: "fake-but-necessary",
      videoId,
    })
    console.log("started recording")
    this.isRecording = true
  }
  stopRecording = async () => {
    if (!this.isRecording) return
    console.log("stopping recording...")
    await this.composer!.stopRecording()
    console.log("recording complete")
    this.isRecording = false
  }

  configureCanvas = () => {
    if (!this.composer) return

    try {
      this.composer.appendCanvasTo(`#${this.VIDEO_ELEMENT_ID}`)
      const canvas = this.composer.getCanvas()
      canvas!.style.width = "100%"
      canvas!.style.boxSizing = "unset"
    } catch (e) {
      console.warn(e)
    }
  }

  cleanup = () => {
    if (!this.composer) return
    this.disableVideo()
    this.disableAudio()
    this.disableWaveform()
    this.composer.destroy()
    this.composer = null
  }
}
