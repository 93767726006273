import {
  QuestionResponse_CandidateTestResponsesFragment,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import { secondsToMMSS } from "~/common/secondsToMMSS"
import { formatTimeWithSeconds, dateTimePlusSeconds } from "~/common/dates"
import { Button, LinkButton } from "~/shadcn/ui/button"
import { Link } from "react-router-dom"
import { gql } from "~/__generated__"
import { formatPercentage } from "~/common/numbers"
import { QUESTION_TYPE_NAMES } from "~/questions/QuestionGroupForm/utils"
import { ManualScoreDialogForm } from "~/questions/ManualScoreDialog"
import ReactPlayer from "react-player"
import { Score } from "~/ui/Score"
import { ChoiceQuestionResponse } from "~/questions/ChoiceQuestionResponse"

interface TestQuestionResponsesListProps {
  data: QuestionResponse_CandidateTestResponsesFragment[]
  viewPath: (test: QuestionResponse_CandidateTestResponsesFragment) => string
}

const ChoiceAnswer = ({
  response,
  name,
}: {
  response: QuestionResponse_CandidateTestResponsesFragment
  name: string
}) => (
  <>
    <div className="text-sm text-gray-999">{name}</div>
    <ChoiceQuestionResponse questionResponse={response} />
  </>
)

const VideoResponseAnswer = ({
  response,
  linkTo,
}: {
  response: QuestionResponse_CandidateTestResponsesFragment
  linkTo: string
}) => {
  const VIDEO_ASPECT_RATIO = 1280 / 720
  const VIDEO_WIDTH = 320
  const VIDEO_HEIGHT = (1 / VIDEO_ASPECT_RATIO) * VIDEO_WIDTH

  const attempt =
    response.questionAttempts[response.questionAttempts.length - 1]

  let transcript = attempt?.transcript

  if (transcript) {
    transcript =
      transcript.slice(0, 300) + (transcript.length > 300 ? "..." : "")
  }

  return (
    <>
      <div className="text-sm text-gray-999">Audio/Video Response</div>
      <div className="flex gap-6">
        <div>
          {attempt?.playbackUrl ? (
            <ReactPlayer
              url={attempt.playbackUrl}
              controls
              width={VIDEO_WIDTH}
              height={VIDEO_HEIGHT}
            />
          ) : attempt ? (
            `Transcoding state: ${attempt.transcodingState}`
          ) : null}
        </div>
        {transcript && (
          <div className="flex flex-col gap-4">
            <div className="text-sm text-gray-999">Transcription</div>
            <div className="text-gray-333 text-lg">
              {transcript}
              <br />
              <Link to={linkTo} className="text-primary">
                Read More
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const TextResponseAnswer = ({
  response,
}: {
  response: QuestionResponse_CandidateTestResponsesFragment
}) => {
  return (
    <>
      <div className="text-sm text-gray-999">Answer</div>
      <div className="text-gray-333 text-lg">
        {response.textResponse?.slice(0, 500)}
        {response.textResponse && response.textResponse.length > 500
          ? "..."
          : ""}
      </div>
    </>
  )
}

export const TestQuestionResponsesList = ({
  data,
  viewPath,
}: TestQuestionResponsesListProps) => {
  const startedAt = (
    response: QuestionResponse_CandidateTestResponsesFragment
  ) => {
    return (
      response.questionAttempts.length > 0 &&
      response.questionAttempts[0].startedAt
    )
  }

  const endedAt = (
    response: QuestionResponse_CandidateTestResponsesFragment
  ) => {
    const startTime = startedAt(response)
    if (response.timeTakenSeconds && startTime) {
      return dateTimePlusSeconds(startTime, response.timeTakenSeconds)
    } else {
      return null
    }
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        {data.map((response) => (
          <div
            key={response.id}
            className="border border-gray-E6E6E3 rounded-lg grid grid-cols-5"
          >
            <div className="p-6 col-span-4 bg-white flex flex-col gap-4">
              <div className="text-sm text-gray-999">
                {QUESTION_TYPE_NAMES[response.questionGroup.questionType]}
              </div>
              <div className="text-xl text-gray-333">
                {response.question.questionCopy}
              </div>

              {response.questionGroup.questionType ===
                QuestionTypeEnum.VideoResponse && (
                <VideoResponseAnswer
                  response={response}
                  linkTo={viewPath(response)}
                />
              )}

              {response.questionGroup.questionType ===
                QuestionTypeEnum.TextResponse && (
                <TextResponseAnswer response={response} />
              )}

              {response.questionGroup.questionType ===
                QuestionTypeEnum.SingleChoice && (
                <ChoiceAnswer response={response} name="Single Choice Answer" />
              )}

              {response.questionGroup.questionType ===
                QuestionTypeEnum.MultipleChoice && (
                <ChoiceAnswer
                  response={response}
                  name="Multiple  Choice Answer"
                />
              )}

              {response.questionGroup.tags.length > 0 && (
                <>
                  <div className="text-sm text-gray-999 mt-4">
                    Question Tags
                  </div>
                  <div>
                    {/* TODO use styles from other PR */}
                    {response.questionGroup.tags.map((tag) => (
                      <div key={tag.id}>{tag.name}</div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="bg-gray-F7F7F5 divide-y divide-gray-E6E6E3">
              <div className="py-3 px-4 flex justify-between items-center">
                <div className="text-gray-333 text-xs">Response Time</div>
                <div className="text-gray-333 text-right">
                  {secondsToMMSS(response.timeTakenSeconds) || "-"}
                </div>
              </div>
              <div className="py-3 px-4 flex justify-between items-center">
                <div className="text-gray-333 text-xs">Manual Score</div>
                <div className="text-gray-333 text-right">
                  {formatPercentage(response.manualScore) || "-"}
                </div>
              </div>
              <div className="py-3 px-4 flex justify-between items-center">
                <div className="text-gray-333 text-xs">AI Score</div>
                <div className="text-gray-333 text-right min-w-16">
                  {response.aiScore !== null &&
                  response.aiScore !== undefined ? (
                    <Score value={response.aiScore} />
                  ) : (
                    <span className="text-gray-999">Not scored</span>
                  )}
                </div>
              </div>
              <div className="py-3 px-4 flex flex-col justify-between gap-2">
                <div className="flex justify-between gap-1 items-center">
                  <div className="text-gray-999 text-xs">
                    Question Difficulty
                  </div>
                  <div className="text-gray-333 text-xs text-right">
                    {response.questionGroup.difficulty || "-"}
                  </div>
                </div>
                <div className="flex justify-between gap-1 items-center">
                  <div className="text-gray-999 text-xs">
                    Question Frequency
                  </div>
                  <div className="text-gray-333 text-xs text-right">
                    {formatPercentage(
                      response.questionGroup.frequencyPercentage
                    ) || "-"}
                  </div>
                </div>
                <div className="flex justify-between gap-1 items-center">
                  <div className="text-gray-999 text-xs">Start Timestamp</div>
                  <div className="text-gray-333 text-xs text-right">
                    {!!startedAt(response)
                      ? formatTimeWithSeconds(startedAt(response))
                      : "-"}
                  </div>
                </div>
                <div className="flex justify-between gap-1 items-center">
                  <div className="text-gray-999 text-xs">End Timestamp</div>
                  <div className="text-gray-333 text-xs text-right">
                    {!!endedAt(response)
                      ? formatTimeWithSeconds(endedAt(response))
                      : "-"}
                  </div>
                </div>
                <div className="flex justify-between gap-1 items-center">
                  <div className="text-gray-999 text-xs">Expected Time</div>
                  <div className="text-gray-333 text-xs text-right">
                    {response.questionGroup.expectedTimeSeconds
                      ? secondsToMMSS(
                          response.questionGroup.expectedTimeSeconds
                        )
                      : "-"}
                  </div>
                </div>
                <div className="flex flex-col gap-3 pt-1">
                  <LinkButton
                    to={viewPath(response)}
                    size="sm"
                    className="w-full"
                  >
                    Full Details
                  </LinkButton>
                  <ManualScoreDialogForm
                    questionResponse={response}
                    text="Manually Score"
                  >
                    <Button size="sm" variant="outline" className="w-full">
                      Manually Score
                    </Button>
                  </ManualScoreDialogForm>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

gql(`
  fragment CandidateTest_CandidateTestResponses on CandidateTest {
    id
    startedAt
    completedAt
    timeTakenSeconds

    user {
      id
      name
      email
      phone
    }
    test {
      id
      name
      updatedAt

      organization {
        id
        name
      }
    }
  }
`)

gql(`
  fragment QuestionResponse_CandidateTestResponses on QuestionResponse {
    id
    state
    textResponse
    timeTakenSeconds
    manualScore
    aiScore
    questionAttempts {
      id
      transcript
      timeTakenSeconds
      transcodingState
      playbackUrl
      startedAt
    }
    responseChoices {
      id
      choiceCopy
      isCorrect
    }
    questionGroup {
      id
      name
      questionType
      frequencyPercentage
      timeLimitSeconds
      expectedTimeSeconds
      difficulty
      tags {
        id
        name
      }
    }
    question {
      id
      questionCopy
      exampleBadAnswer
      exampleGoodAnswer
      scoringPrompt
      frequencyPercentage
      questionChoices {
        id
        choiceCopy
        isCorrect
      }
    }
  }
`)

export const CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT = gql(`
  query CandidateTestQuestionResponses($candidateTestId: ID!) {
    candidateTest(candidateTestId: $candidateTestId) {
      ...CandidateTest_CandidateTestResponses
    }

    candidateTestQuestionResponses(candidateTestId: $candidateTestId) {
      nodes {
        ...QuestionResponse_CandidateTestResponses
      }
    }
  }
`)
