import { Switch } from "~/shadcn/ui/switch"
import { Test_OrgTestLayoutFragment } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"

export const TestSettingToggle = ({
  id,
  title,
  subTitle,
  fieldName,
  icon,
  test,
}: {
  id: string
  title: string
  subTitle: string
  fieldName: string
  icon: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  test: Test_OrgTestLayoutFragment
}) => {
  const fieldNameKey = fieldName as keyof Test_OrgTestLayoutFragment
  const Icon = icon

  const [testUpdate, { loading }] = useSafeMutation(TEST_UPDATE_MUTATION)

  const handleCheckedChange = async (checked: boolean) => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          testId: test.id,
          name: test.name as string,
          [fieldName]: checked,
        },
      },
    })

    if (errors) {
      toast.error("Error updating setting.")
      console.log(errors)
    } else if (data?.testUpdate?.test) {
      toast.success(`${title} setting updated`)
    } else {
      toast.error("Error updating setting.")
    }
  }

  return (
    <div className="p-4 flex flex-row gap-4 justify-beteen items-center">
      <div className="flex flex-row flex-grow">
        <div>
          <Icon className="m-4 h-6 w-6 text-gray-333" />
        </div>
        <div className="flex flex-col">
          <label htmlFor={id} className="cursor-pointer">
            <span className="text-base font-medium text-gray-333">{title}</span>
            <p className="text-sm text-gray-999">{subTitle}</p>
          </label>
        </div>
      </div>
      <div>
        <div className="flex items-center space-x-2">
          <Switch
            id={id}
            onCheckedChange={handleCheckedChange}
            checked={test[fieldNameKey]}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  )
}

const TEST_UPDATE_MUTATION = gql(`
  mutation TestSettingsUpdate($input: TestUpdateInput!) {
    testUpdate(input: $input) {
      test {
        id
        requireEmailVerification
        requirePhoneVerification
        requireVideo
        requireAudio
      }
    }
  }
`)
