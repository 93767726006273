import { useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { AccessPanel } from "~/tests/AccessPanel"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { Test_OrgTestLayoutFragment } from "~/__generated__/graphql"
import TrashIcon from "~/images/icons/trash.svg?react"
import { ConfirmDialog } from "~/ui/Confirm"
import capitalize from "lodash.capitalize"
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { GravatarImage } from "~/ui/GravatarImage"

const ResendInviteButton = ({ inviteId }: { inviteId: string }) => {
  const [inviteResend, { loading: loadingResend }] = useSafeMutation(
    INVITE_RESEND_MUTATION,
    {
      refetchQueries: ["OrgLayoutTest"],
    }
  )
  const handleResend = async (inviteId: string) => {
    const { data, errors } = await inviteResend({
      variables: {
        input: {
          inviteId: inviteId,
        },
      },
    })

    if (errors) {
      toast.error("Error resending invitation.")
      console.log(errors)
    } else if (data?.inviteResend?.invite) {
      toast.success("Invitation resent.")
    } else {
      toast.error("Error resending invitation.")
    }
  }

  return (
    <Button
      size="sm"
      disabled={loadingResend}
      onClick={() => handleResend(inviteId)}
    >
      Resend Test
    </Button>
  )
}

const DestroyInviteButton = ({
  inviteId,
  name,
}: {
  inviteId: string
  name: string | null | undefined
}) => {
  const [inviteDestroy, { loading: loadingDestroy }] = useSafeMutation(
    INVITE_DESTROY_MUTATION,
    {
      refetchQueries: ["OrgLayoutTest"],
    }
  )
  const handleDestroy = async (inviteId: string) => {
    const { data, errors } = await inviteDestroy({
      variables: {
        input: {
          inviteId: inviteId,
        },
      },
    })

    if (errors) {
      toast.error("Error deleting invitation.")
      console.log(errors)
    } else if (data?.inviteDestroy?.invite) {
      toast.success("Invitation deleted.")
    } else {
      toast.error("Error deleting invitation.")
    }
  }

  return (
    <ConfirmDialog
      text={`Archive ${name}?`}
      onSuccess={async () => handleDestroy(inviteId)}
    >
      <Button variant="outline" size="sm" disabled={loadingDestroy}>
        <TrashIcon className="h-4 w-4" />
      </Button>
    </ConfirmDialog>
  )
}

const InvitesTable = ({ test }: { test: Test_OrgTestLayoutFragment }) => {
  return (
    <Table containerClassName="border-0 rounded-none border-t">
      <TableHeader>
        <TableRow className="text-gray-333">
          <TableHead>Users</TableHead>
          <TableHead>&nbsp;</TableHead>
          <TableHead className="text-right">Status</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {test?.invites.map((invite) => (
          <TableRow key={invite.id}>
            <TableCell>
              <div className="py-2">
                {invite.user && (
                  <GravatarImage
                    name={invite.user?.name}
                    email={invite.user.email}
                  />
                )}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-col gap-1 flex-grow">
                <span className="text-gray-333 font-medium text-base">
                  {invite.user?.name || invite.user?.email}
                </span>
                <span className="text-gray-999 font-medium">
                  {invite.user?.name && invite.user?.email}
                </span>
              </div>
            </TableCell>
            <TableCell className="text-gray-333 text-right">
              {capitalize(invite.invitationStatus)}
            </TableCell>
            <TableCell>
              <div className="flex flex-row gap-2 justify-end items-center">
                <ResendInviteButton inviteId={invite.id} />
                <DestroyInviteButton
                  inviteId={invite.id}
                  name={invite.user?.name || invite.user?.email}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const AccessPanelInvite = ({
  test,
  display,
}: {
  test: Test_OrgTestLayoutFragment
  display: boolean
}) => {
  const [email, setEmail] = useState("")
  const [hasError, setHasError] = useState(false)

  const [testInviteUser, { loading }] = useSafeMutation(
    TEST_INVITE_USER_MUTATION
  )
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!email) {
      setHasError(true)
      toast.error("Please provide one or more emails.")
      return
    }

    const { data, errors } = await testInviteUser({
      variables: {
        input: {
          testId: test.id,
          email,
        },
      },
    })

    if (errors) {
      toast.error("Error inviting user.")
      setHasError(true)
      console.log(errors)
    } else if (data?.testInviteUser?.test) {
      setHasError(false)
      setEmail("")
      toast.success("User invited.")
    } else {
      setHasError(true)
      toast.error("Error inviting user.")
    }
  }

  if (!display) {
    return null
  } else {
    return (
      <AccessPanel>
        <div className="flex flex-col divide-y divide-gray-E6E6E3">
          <div className="p-6 text-gray-333 flex flex-row gap-2 justify-between items-center">
            <span className="font-medium pr-12">Invite Users</span>
            <form
              className="flex flex-row gap-2 items-stretch flex-grow"
              onSubmit={(e) => handleSubmit(e)}
            >
              <label className="sr-only">Email</label>
              <input
                type="text"
                name="email"
                placeholder="Email, comma separated"
                className={cn(
                  "w-full border-1 border-gray-E3E2E0 rounded",
                  hasError ? "border-red-500" : ""
                )}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <Button type="submit" disabled={loading}>
                Invite
              </Button>
            </form>
          </div>
        </div>
        <InvitesTable test={test} />
      </AccessPanel>
    )
  }
}

const TEST_INVITE_USER_MUTATION = gql(`
  mutation TestInviteUser($input: TestInviteUserInput!) {
    testInviteUser(input: $input) {
      test {
        id
        invites {
          id
          userId
          invitationStatus
          user {
            id
            name
            email
          }
        }
      }
    }
  }
`)

const INVITE_DESTROY_MUTATION = gql(`
  mutation InviteDestroy($input: InviteDestroyInput!) {
    inviteDestroy(input: $input) {
      invite {
        id
      }
    }
  }
`)

const INVITE_RESEND_MUTATION = gql(`
  mutation InviteResend($input: InviteResendInput!) {
    inviteResend(input: $input) {
      invite {
        id
      }
    }
  }
`)
