import {
  CandidateTest_TableFragment,
  CandidateTestSortEnum,
  CandidateTestStateEnum,
} from "~/__generated__/graphql"
import { formatDate, formatDateAndTime } from "~/common/dates"
import { Button } from "~/shadcn/ui/button"
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { secondsToMMSS } from "~/common/secondsToMMSS"
import { useMemo } from "react"
import { CheckCircle, ChevronDown, ChevronUp } from "lucide-react"
import { formatPercentage } from "~/common/numbers"
import InProgressIcon from "~/images/icons/candidate-test-in-progress.svg?react"
import CompletedIcon from "~/images/icons/candidate-test-completed.svg?react"
import NotStartedIcon from "~/images/icons/candidate-test-not-started.svg?react"
import { ToggleArchivedButton } from "~/tests/ToggleArchivedAtButton"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"
import { useNavigate } from "react-router-dom"
import EllipsisVerticalIcon from "~/images/icons/ellipsis_vertical.svg?react"
import { cn } from "~/common/shadcn-utils"

type CandidateTestColumns =
  | "candidate"
  | "candidateName"
  | "test"
  | "testName"
  | "email"
  | "phone"
  | "startedAt"
  | "completedAt"
  | "timeTakenSeconds"
  | "verified"
  | "manualScore"
  | "aiScore"
  | "actions"

export interface CandidateTestsTableProps {
  data: CandidateTest_TableFragment[]
  columns?: CandidateTestColumns[]
  viewPath: (candidateTest: CandidateTest_TableFragment) => string
  sort?: CandidateTestSortEnum
  setSort?: (sort: CandidateTestSortEnum) => void
  onCandidateTestArchivedChanged?: (candidateTestId: string) => void
}

const isVerified = (candidateTest: CandidateTest_TableFragment) => {
  const { test } = candidateTest

  if (test.requirePhoneVerification) {
    return candidateTest.phoneVerificationCompleted
  } else if (test.requireEmailVerification) {
    return true
  }
}

const CandidateTestStateLabels = {
  [CandidateTestStateEnum.NotStarted]: "Not Started",
  [CandidateTestStateEnum.InProgress]: "In Progress",
  [CandidateTestStateEnum.Completed]: "Completed",
}

export const CandidateTestsTable = ({
  data,
  columns = [
    "candidate",
    "startedAt",
    "completedAt",
    "timeTakenSeconds",
    "verified",
    "manualScore",
    "aiScore",
    "actions",
  ],
  viewPath,
  sort,
  setSort,
  onCandidateTestArchivedChanged,
}: CandidateTestsTableProps) => {
  const navigate = useNavigate()
  console.log(data)
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {columns.map((column, index) => (
            <CandidateTestsTableHead
              key={index}
              column={column}
              sort={sort}
              setSort={setSort}
            />
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((response) => (
          <TableRow key={response.id} navigationPath={viewPath(response)}>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                className={cn({
                  "text-right": [
                    "actions",
                    "timeTakenSeconds",
                    "manualScore",
                    "aiScore",
                  ].includes(column),
                })}
              >
                {column === "candidate" && (
                  <div className="flex flex-col">
                    <div className="text-base flex items-center gap-2">
                      {response.user.name}
                      {isVerified(response) ? <CheckCircle size={14} /> : null}
                    </div>
                    <div className="text-gray-999 hidden md:block">
                      {response.user.email}
                    </div>
                  </div>
                )}
                {column === "candidateName" && response.user.name}
                {column === "testName" && response.test.name}
                {column === "test" && (
                  <div className="flex flex-row gap-1">
                    <div className="pt-1">
                      {response.state === CandidateTestStateEnum.NotStarted && (
                        <NotStartedIcon className="w-4 h-4" />
                      )}
                      {response.state === CandidateTestStateEnum.InProgress && (
                        <InProgressIcon className="w-4 h-4" />
                      )}
                      {response.state === CandidateTestStateEnum.Completed && (
                        <CompletedIcon className="w-4 h-4" />
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="text-base text-gray-333">
                        {response.test.name}
                      </div>
                      <div className="text-sm text-gray-999">
                        {CandidateTestStateLabels[response.state]}
                        {response.state === CandidateTestStateEnum.Completed &&
                          ` on ${formatDateAndTime(response.completedAt)}`}
                      </div>
                    </div>
                  </div>
                )}
                {column === "email" && response.user.email}
                {column === "phone" && response.user.phone}
                {column === "startedAt" &&
                  (response.startedAt ? formatDate(response.startedAt) : null)}
                {column === "completedAt" &&
                  (response.completedAt
                    ? formatDateAndTime(response.completedAt)
                    : null)}
                {column === "timeTakenSeconds" &&
                  secondsToMMSS(response.timeTakenSeconds)}
                {column === "verified" &&
                  (isVerified(response) ? <CheckCircle size={16} /> : null)}
                {column === "manualScore" &&
                  formatPercentage(response.manualScore)}
                {column === "aiScore" && formatPercentage(response.aiScore)}
                {column === "actions" && (
                  <div className="flex flex-row items-center gap-2 justify-end">
                    <ToggleArchivedButton
                      candidateTest={response}
                      onChange={() =>
                        onCandidateTestArchivedChanged &&
                        onCandidateTestArchivedChanged(response.id)
                      }
                    />
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <EllipsisVerticalIcon className="w-4 h-4 hover:text-primary" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                          onClick={() => navigate(viewPath(response))}
                        >
                          View
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

interface CandidateTestsTableHeadProps {
  className?: string
  column: CandidateTestColumns
  sort?: CandidateTestSortEnum
  setSort?: (sort: CandidateTestSortEnum) => void
}

const CandidateTestsTableHead = ({
  className = "",
  column,
  sort,
  setSort,
}: CandidateTestsTableHeadProps) => {
  const label = useMemo(() => {
    switch (column) {
      case "candidate":
        return "Candidates"
      case "candidateName":
        return "Candidate Name"
      case "email":
        return "Email"
      case "phone":
        return "Phone"
      case "testName":
        return "Tests Taken"
      case "test":
        return "Tests"
      case "startedAt":
        return "Start Date"
      case "completedAt":
        return "Completed Date"
      case "timeTakenSeconds":
        return "Test Time"
      case "verified":
        return "Verified"
      case "manualScore":
        return "Manual Score"
      case "aiScore":
        return "AI Score"
      case "actions":
        return "Actions"
    }
  }, [column])

  const Content = useMemo(() => {
    let Slot = ({ children }: { children: string }) => <>{children}</>

    if (
      setSort &&
      ["timeTakenSeconds", "manualScore", "aiScore", "completedAt"].includes(
        column
      )
    ) {
      Slot = ({ children }: { children: string }) => (
        <Button
          variant="tableHeader"
          size="content"
          onClick={() => {
            if (column === "timeTakenSeconds") {
              setSort(CandidateTestSortEnum.TimeTakenSeconds)
            } else if (column === "manualScore") {
              setSort(CandidateTestSortEnum.ManualScore)
            } else if (column === "aiScore") {
              setSort(CandidateTestSortEnum.AiScore)
            } else if (column === "completedAt") {
              setSort(CandidateTestSortEnum.CompletedAt)
            }
          }}
        >
          {children}
          {sort === CandidateTestSortEnum.TimeTakenSeconds &&
          column === "timeTakenSeconds" ? (
            <ChevronUp className="ml-1 w-4 h-4" />
          ) : null}
          {sort === CandidateTestSortEnum.ManualScore &&
          column === "manualScore" ? (
            <ChevronDown className="ml-1 w-4 h-4" />
          ) : null}
          {sort === CandidateTestSortEnum.AiScore && column === "aiScore" ? (
            <ChevronDown className="ml-1 w-4 h-4" />
          ) : null}
          {sort === CandidateTestSortEnum.CompletedAt &&
          column === "completedAt" ? (
            <ChevronDown className="ml-1 w-4 h-4" />
          ) : null}
        </Button>
      )
    }

    return <Slot>{label}</Slot>
  }, [label, column, setSort, sort])

  return (
    <TableHead
      className={cn(className, {
        "text-right": [
          "actions",
          "timeTakenSeconds",
          "manualScore",
          "aiScore",
        ].includes(column),
      })}
    >
      {Content}
    </TableHead>
  )
}
