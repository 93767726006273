import { useQuery } from "@apollo/client"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  CandidateTestStateEnum,
  CurrentAttempt_QuestionAttemptFragment,
  TestFlow_CandidateTestFragment,
} from "~/__generated__/graphql"
import { useRecording } from "~/av/RecordingProvider"
import { candidateTestsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Error as UiError } from "~/ui/Error"

export enum TestUiStateEnum {
  Loading = "Loading",
  Disclaimers = "Disclaimers",
  AvSetup = "AvSetup",
  PhoneVerification = "PhoneVerification",
  AnsweringQuestion = "AnsweringQuestion",
  TestComplete = "TestComplete",
}

type AnswerQuestionFn = (opts?: {
  textResponse?: string
  selectedChoiceIds?: string[]
}) => Promise<void>

type TestFlowType = {
  candidateTest: TestFlow_CandidateTestFragment | null
  testUiState: TestUiStateEnum
  nextQuestion: () => void
  acceptTerms: () => void
  answerQuestion: AnswerQuestionFn
  currentAttempt: CurrentAttempt_QuestionAttemptFragment | null
  phoneVerificationComplete: (
    candidateTest: TestFlow_CandidateTestFragment
  ) => void
}

const TestFlow = createContext<TestFlowType | null>(null)

export const TestFlowProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [questionAttemptCreate] = useSafeMutation(
    QUESTION_ATTEMPT_CREATE_MUTATION
  )
  const [questionAttemptComplete] = useSafeMutation(
    QUESTION_ATTEMPT_COMPLETE_MUTATION
  )
  const [candidateTestUpdate] = useSafeMutation(
    CANDIDATE_TEST_ACCEPT_TERMS_MUTATION
  )

  const [currentAttempt, setCurrentAttempt] =
    useState<CurrentAttempt_QuestionAttemptFragment | null>(null)
  const { avController } = useRecording()
  const [testUiState, setTestUiState] = useState<TestUiStateEnum>(
    TestUiStateEnum.Loading
  )
  const initialTransitionCompletedRef = useRef(false)
  const navigate = useNavigate()
  const { testId } = useParams()
  invariant(testId)

  const candidateTestQueryResult = useQuery(CANDIDATE_TEST_QUERY_DOCUMENT, {
    variables: { testId },
    fetchPolicy: "network-only",
  })

  const candidateTest = useMemo(
    () => candidateTestQueryResult.data?.candidateTest || null,
    [candidateTestQueryResult.data]
  )
  const avRequired = useMemo(
    () => candidateTest?.test.requireAudio || candidateTest?.test.requireVideo,
    [candidateTest?.test.requireAudio, candidateTest?.test.requireVideo]
  )
  const candidateTestId = candidateTestQueryResult.data?.candidateTest.id

  const nextQuestion = useCallback(async () => {
    setTestUiState(TestUiStateEnum.AnsweringQuestion)
    setCurrentAttempt(null)

    invariant(candidateTestId)
    const { data } = await questionAttemptCreate({
      variables: { input: { candidateTestId } },
    })
    invariant(data)
    const attempt = data.questionAttemptCreate.questionAttempt

    if (attempt) {
      if (avRequired) {
        await avController.startRecording(attempt.id)
      }
      setCurrentAttempt(attempt)
    } else {
      setTestUiState(TestUiStateEnum.TestComplete)
    }
  }, [
    setTestUiState,
    avController,
    candidateTestId,
    questionAttemptCreate,
    avRequired,
  ])

  const transitionToNextSetupState = useCallback(
    (candidateTest: TestFlow_CandidateTestFragment) => {
      const nextState = getNextState(testUiState, candidateTest)
      if (nextState === TestUiStateEnum.AnsweringQuestion) nextQuestion()
      setTestUiState(nextState)
    },
    [testUiState, nextQuestion]
  )

  useEffect(() => {
    if (!candidateTestQueryResult.data?.candidateTest) return
    if (initialTransitionCompletedRef.current === true) return
    initialTransitionCompletedRef.current = true
    transitionToNextSetupState(candidateTestQueryResult.data.candidateTest)
  }, [candidateTestQueryResult.data?.candidateTest, transitionToNextSetupState])

  const phoneVerificationComplete = useCallback(
    (candidateTest: TestFlow_CandidateTestFragment) => {
      transitionToNextSetupState(candidateTest)
    },
    [transitionToNextSetupState]
  )

  const acceptTerms = useCallback(async () => {
    invariant(candidateTestId)

    const { data, errors } = await candidateTestUpdate({
      variables: {
        input: {
          candidateTestId: candidateTestId,
        },
      },
    })

    if (
      errors ||
      !data?.candidateTestAcceptTerms?.candidateTest?.acceptedTerms
    ) {
      toast.error("Error accepting terms.")
    } else {
      transitionToNextSetupState(data.candidateTestAcceptTerms.candidateTest)
    }
  }, [transitionToNextSetupState, candidateTestId, candidateTestUpdate])

  const answerQuestion: AnswerQuestionFn = useCallback(
    async ({ textResponse, selectedChoiceIds } = {}) => {
      await avController.stopRecording()
      const { errors } = await questionAttemptComplete({
        variables: {
          input: {
            questionAttemptId: currentAttempt!.id,
            textResponse,
            selectedChoiceIds,
          },
        },
      })
      if (errors) {
        displayErrors(errors)
      } else {
        nextQuestion()
      }
    },
    [nextQuestion, avController, questionAttemptComplete, currentAttempt]
  )

  const value = useMemo(
    () => ({
      candidateTest,
      testUiState: testUiState,
      nextQuestion,
      acceptTerms,
      answerQuestion,
      currentAttempt,
      phoneVerificationComplete,
    }),
    [
      candidateTest,
      testUiState,
      nextQuestion,
      acceptTerms,
      answerQuestion,
      currentAttempt,
      phoneVerificationComplete,
    ]
  )

  useEffect(() => {
    if (
      candidateTestQueryResult.data?.candidateTest.state ===
      CandidateTestStateEnum.Completed
    ) {
      toast.success("You've already completed this test.")
      navigate(candidateTestsPath.pattern)
    }
  }, [candidateTestQueryResult.data?.candidateTest, navigate])

  useEffect(() => {
    return () => {
      console.log("disabling AV")
      avController.cleanup()
    }
  }, [avController])

  if (candidateTestQueryResult.error)
    return <UiError message="Error loading test." />

  return <TestFlow.Provider value={value}>{children}</TestFlow.Provider>
}

export const useTestFlow = () => {
  const contextValue = useContext(TestFlow)
  invariant(contextValue, "TestFlow context has no value")
  return contextValue
}

const getNextState = (
  currentState: TestUiStateEnum,
  candidateTest: TestFlow_CandidateTestFragment
): TestUiStateEnum => {
  const termsRequired = !candidateTest.acceptedTerms
  const avRequired =
    candidateTest.test.requireAudio || candidateTest.test.requireVideo
  const phoneVerificationCompleted =
    candidateTest.test.requirePhoneVerification &&
    !candidateTest.phoneVerificationCompleted

  const steps: [TestUiStateEnum, boolean][] = [
    [TestUiStateEnum.Loading, true],
    [TestUiStateEnum.Disclaimers, termsRequired],
    [TestUiStateEnum.PhoneVerification, phoneVerificationCompleted],
    [TestUiStateEnum.AvSetup, avRequired],
    [TestUiStateEnum.AnsweringQuestion, true],
  ]

  const currentStepIndex = steps.findIndex(
    (stepRecord) => stepRecord[0] === currentState
  )
  const nextSteps = steps.slice(currentStepIndex + 1)

  for (let s = 0; s < nextSteps.length; s++) {
    const [step, isRequired] = nextSteps[s]
    if (!isRequired) continue
    return step
  }

  invariant("next step not found")

  // never reached but make TypeScript happy
  return TestUiStateEnum.AnsweringQuestion
}

gql(`
  fragment TestFlow_CandidateTest on CandidateTest {
    id
    state
    questionCount
    currentQuestionNumber
    acceptedTerms
    phoneVerificationCompleted

    test {
      id
      name
      requireAudio
      requireVideo
      requirePhoneVerification
      description
      welcomeNote
      disclaimers {
        id
        disclaimerText
      }
      closingNotes
      organization {
       id
       name
      }
    }
  }
`)

const CANDIDATE_TEST_QUERY_DOCUMENT = gql(`
  query CandidateTestQuery($testId: ID!) {
    candidateTest(testId: $testId) {
      ...TestFlow_CandidateTest
    }
  }
`)

gql(`
  fragment CurrentAttempt_QuestionAttempt on QuestionAttempt {
    id
    previousAttemptTimeSeconds
    createdAt
    question {
      id
      questionCopy
      questionGroup {
        id
        questionType
        timeLimitSeconds
      }
      questionChoices {
        id
        choiceCopy
      }
    }
  }
`)

const QUESTION_ATTEMPT_CREATE_MUTATION = gql(`
  mutation QuestionAttemptCreate($input: QuestionAttemptCreateInput!) {
    questionAttemptCreate(input: $input) {
      questionAttempt {
        ...CurrentAttempt_QuestionAttempt
      }
    }
  }
`)

const QUESTION_ATTEMPT_COMPLETE_MUTATION = gql(`
  mutation QuestionAttemptComplete($input: QuestionAttemptCompleteInput!) {
    questionAttemptComplete(input: $input) {
      candidateTest {
        ...TestFlow_CandidateTest
      }
    }
  }
`)

const CANDIDATE_TEST_ACCEPT_TERMS_MUTATION = gql(`
  mutation CandidateTestAcceptTerms($input: CandidateTestAcceptTermsInput!) {
    candidateTestAcceptTerms(input: $input) {
      candidateTest {
        ...TestFlow_CandidateTest
      }
    }
  }
`)
