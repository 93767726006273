import { Outlet, useRouteError } from "react-router-dom"
import * as Sentry from "@sentry/react"
import * as React from "react"

const Fallback: Sentry.FallbackRender = ({ error }) => {
  return (
    <div className="max-w-7xl mx-auto">
      <div className="border border-red-700 p-4 py-2 mt-4">
        <h1 className="text-red-700">{error?.toString()}</h1>
      </div>
    </div>
  )
}

export const ErrorBoundary = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      {children ? children : <Outlet />}
    </Sentry.ErrorBoundary>
  )
}

export const SentryRouteErrorFallback = () => {
  const routeError = useRouteError()

  React.useEffect(() => {
    Sentry.captureException(routeError, { level: "fatal" })
  }, [routeError])

  return (
    <Fallback
      error={routeError}
      componentStack=""
      eventId=""
      resetError={() => null}
    />
  )
}
