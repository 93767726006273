import { useFormContext } from "react-hook-form"
import { z } from "zod"
import { TextField } from "~/ui/forms/TextField"

import { QuestionTypeEnum } from "~/__generated__/graphql"
import { ReactNode } from "react"
import { cn } from "~/common/shadcn-utils"
import { Divider } from "~/ui/Divider"
import { XCircle } from "lucide-react"
import { PercentageField } from "~/ui/forms/PercentageField"
import { TextareaField } from "~/ui/forms/TextareaField"
import { questionGroupFormSchema } from "./QuestionGroupForm"
import { ChoicesSubsection } from "./ChoicesSubsection"
import { includesChoices } from "./utils"
import { AdvancedToggle } from "./AdvancedToggle"

export const QuestionSubsection = ({
  index,
  removeQuestion,
  canRemove,
}: {
  index: number
  removeQuestion: () => void
  canRemove: boolean
}) => {
  const { control, watch, setValue, getFieldState } =
    useFormContext<z.infer<typeof questionGroupFormSchema>>()
  const questionType = watch(`questionType`)
  const hasMultipleQuestions = watch(`questions`).length > 1
  const values = watch(`questions.${index}`)
  const { advancedSettingsOpen } = values

  const { invalid } = getFieldState(`questions.${index}`)

  return (
    <FormPanel className={cn("mb-2 p-0", invalid && "border-red-600")}>
      <div className="px-6 py-5 flex items-center">
        <div className="font-medium text-gray-333 ml-6 mr-auto">
          {values.questionCopy.length > 0 ? (
            values.questionCopy
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        {hasMultipleQuestions && (
          <div className="flex items-center gap-2">
            Percentage of time question asked
            <PercentageField
              control={control}
              name={`questions.${index}.frequencyPercentage`}
              label={null}
              containerClassName="flex-col items-end"
            />
          </div>
        )}
        <XCircle
          size={18}
          className={cn("text-gray-999 cursor-pointer ml-4", {
            invisible: !canRemove,
          })}
          onClick={() => removeQuestion()}
        />
      </div>

      <div className="bg-gray-E6E6E3 h-[1px]" />

      <div className="p-6">
        <TextField
          control={control}
          name={`questions.${index}.questionCopy`}
          label="Question"
          placeholder="Enter question here"
        />

        {includesChoices(questionType) && (
          <>
            <Divider className="my-8" />
            <ChoicesSubsection
              questionIndex={index}
              questionType={questionType}
            />
          </>
        )}
        {includesPromptingFields(questionType) && (
          <>
            {advancedSettingsOpen && (
              <>
                <Divider className="my-8" />
                <div className="flex flex-col gap-4">
                  <TextareaField
                    control={control}
                    name={`questions.${index}.scoringPrompt`}
                    label="Scoring Prompt"
                    rows={3}
                  />
                  <TextareaField
                    control={control}
                    name={`questions.${index}.exampleGoodAnswer`}
                    label="Example Good Answer"
                    rows={3}
                  />
                  <TextareaField
                    control={control}
                    name={`questions.${index}.exampleBadAnswer`}
                    label="Example Bad Answer"
                    rows={3}
                  />
                </div>
              </>
            )}

            <AdvancedToggle
              text="Expected Responses & AI Scoring Tools"
              open={advancedSettingsOpen}
              onClick={() =>
                setValue(
                  `questions.${index}.advancedSettingsOpen`,
                  !advancedSettingsOpen
                )
              }
              containerClassName="mt-8"
            />
          </>
        )}
      </div>
    </FormPanel>
  )
}

const FormPanel = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => (
  <div
    className={cn(
      "bg-gray-F7F7F5 rounded p-6 border border-gray-E6E6E3 flex flex-col",
      className
    )}
  >
    {children}
  </div>
)

const includesPromptingFields = (questionType: QuestionTypeEnum) => {
  return (
    questionType === QuestionTypeEnum.TextResponse ||
    questionType === QuestionTypeEnum.VideoResponse
  )
}
