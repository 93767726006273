import { ClipboardX } from "lucide-react"
import { FormPanel } from "~/questions/QuestionGroupForm/FormPanel"
import { Button } from "~/shadcn/ui/button"
import { useAIWizard } from "./AIWizardProvider"

export const Error = () => {
  const { reset } = useAIWizard()

  return (
    <FormPanel>
      <div className="flex flex-col gap-5 items-center justify-center text-center py-32">
        <div className="bg-communist text-white rounded-full p-5">
          <ClipboardX className="w-6 h-6" />
        </div>
        <div className="text-gray-333 text-xl font-medium">
          Something went wrong
        </div>
        <div className="text-gray-333 text-sm">
          <Button variant="link" size="link" onClick={reset}>
            Click Here
          </Button>{" "}
          to try again.
        </div>
      </div>
    </FormPanel>
  )
}
