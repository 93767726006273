import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const CURRENT_USER_QUERY_DOCUMENT = gql(`
  query CurrentUserProvider {
    currentUser {
      id
      email
      name
      phone
      invitedToAnyTests
      startedAnyTests
      admin

      impersonator {
        id
        email
      }

      organizations {
        id
        name
        candidateTestsCount
      }

      __typename
    }
  }
`)
