import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import { QuestionResponse } from "~/__generated__/graphql"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "~/shadcn/ui/dialog"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form } from "~/shadcn/ui/form"
import { TextField } from "~/ui/forms/TextField"
import { TextareaField } from "~/ui/forms/TextareaField"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { cn } from "~/common/shadcn-utils"

const formSchema = z.object({
  manualScore: z.preprocess(
    (v) => {
      return v === "" ? null : Number(v)
    },
    z
      .number({
        message: "Please enter a number between 0 and 100",
      })
      .int()
      .lte(100)
      .gte(0)
  ),
  manualScoreNote: z.string().optional(),
})

type PickedQuestionResponse = Pick<
  QuestionResponse,
  "id" | "manualScore" | "manualScoreNote"
>

export const ManualScoreDialog = ({
  questionResponse,
}: {
  questionResponse: PickedQuestionResponse
}) => {
  return (
    <>
      {questionResponse.manualScore !== null &&
      questionResponse !== undefined ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 justify-between">
            <span>{questionResponse.manualScore}%</span>
            <ManualScoreDialogForm
              text="Update"
              questionResponse={questionResponse}
            />
          </div>
          {questionResponse.manualScoreNote &&
            questionResponse.manualScoreNote.length > 0 && (
              <div>{questionResponse.manualScoreNote}</div>
            )}
        </div>
      ) : (
        <ManualScoreDialogForm
          text="Add Manual Score"
          questionResponse={questionResponse}
        />
      )}
    </>
  )
}

export const ManualScoreDialogForm = ({
  text,
  questionResponse,
  children,
}: {
  text: string
  questionResponse: PickedQuestionResponse
  children?: React.ReactNode
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const [testUpdate, { loading }] = useSafeMutation(QUESTION_RESPONSE_MUTATION)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      manualScore: questionResponse.manualScore || 100,
      manualScoreNote: questionResponse.manualScoreNote || "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          questionResponseId: questionResponse.id,
          ...values,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.questionResponseUpdate?.questionResponse) {
      toast.success("Manual Score Updated")
      setDialogOpen(false)
    } else {
      toast.error("Error updating manual score.")
    }
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild={true}>
        {children || <button className="text-primary">{text}</button>}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-gray-333 leading-normal tracking-normal">
            Manually Score an Answer
          </DialogTitle>
          <DialogDescription className="text-xl font-medium text-gray-999">
            How did the candidate do on this question?
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="flex flex-col gap-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <TextField
              control={form.control}
              name="manualScore"
              label="Manual Score"
              required
            />
            <TextareaField
              inputClassName="h-24"
              control={form.control}
              name="manualScoreNote"
              label="Score Notes"
              placeholder="Type your score notes here..."
            />
            <div className="bg-gray-E6E6E3 h-[1px]"></div>
            <div className="flex flex-row gap-2 items-center">
              <Button type="submit" disabled={loading}>
                Save Score
              </Button>
              <Button
                type="button"
                onClick={() => setDialogOpen(false)}
                variant="outline-light"
              >
                Close & Cancel
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
export const ManualScoreDrawer = ({
  questionResponse,
  show,
}: {
  questionResponse: PickedQuestionResponse
  show: boolean
}) => {
  const [testUpdate, { loading }] = useSafeMutation(QUESTION_RESPONSE_MUTATION)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      manualScore: questionResponse.manualScore || 100,
      manualScoreNote: questionResponse.manualScoreNote || "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          questionResponseId: questionResponse.id,
          ...values,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.questionResponseUpdate?.questionResponse) {
      toast.success("Manual Score Updated")
    } else {
      toast.error("Error updating manual score.")
    }
  }

  const showClasses = show ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"

  return (
    <Form {...form}>
      <form
        className={cn("transition-all ease-in-out duration-250", showClasses)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-3 bg-gray-F7F7F5 p-6 gap-4">
          <div className="flex flex-col justify-between gap-2">
            <TextField
              control={form.control}
              name="manualScore"
              label="Manual Score"
              required
            />
            <Button
              type="submit"
              disabled={loading}
              size="sm"
              className="w-full"
            >
              Update Score
            </Button>
          </div>
          <div className="col-span-2 flex flex-grow">
            <TextareaField
              containerClassName="flex flex-col flex-grow"
              inputClassName="flex-grow"
              control={form.control}
              name="manualScoreNote"
              label="Score Notes"
              placeholder="Type your score notes here..."
            />
          </div>
        </div>
      </form>
    </Form>
  )
}

const QUESTION_RESPONSE_MUTATION = gql(`
  mutation QuestionResponseUpdate($input: QuestionResponseUpdateInput!) {
    questionResponseUpdate(input: $input) {
      questionResponse {
        id
        manualScore
        manualScoreNote
      }
    }
  }
`)
