import { Link } from "react-router-dom"
import { gql } from "~/__generated__"
import { candidateTestsPath, organizationTestsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form } from "~/shadcn/ui/form"
import { TextField } from "~/ui/forms/TextField"
import { displayErrors } from "~/common/validations"
import invariant from "tiny-invariant"

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Required",
  }),
})

export const CreateOrganizationScreen = () => {
  const [organizationCreate, { loading }] = useSafeMutation(
    ORGANIZATION_CREATE_MUTATION
  )

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await organizationCreate({
      variables: {
        input: {
          ...values,
        },
      },
    })

    if (errors) {
      console.log(errors)
      displayErrors(errors, form.setError)
    } else {
      invariant(data?.organizationCreate?.organization?.id)

      // full redirect because we rely on current user data updating
      window.location.replace(
        organizationTestsPath({
          organizationId: data.organizationCreate.organization.id,
        })
      )
    }
  }

  return (
    <div className="flex-1 flex flex-col justify-center p-6">
      <div className="w-full sm:w-[360px] mx-auto">
        <div className="text-2xl text-gray-333 font-semibold">
          Create an organization.
        </div>
        <div className="text-2xl text-gray-999 font-semibold mb-8">
          Give your organization a name
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <TextField
              control={form.control}
              name="name"
              label="Name"
              placeholder="Acme Co"
            />

            <div className="h-[1px] my-4 bg-gray-E3E2E0" />

            <Button
              className="btn w-full mb-12"
              disabled={loading}
              type="submit"
            >
              Create Organization
            </Button>
          </form>
        </Form>

        <div className="text-gray-999 text-center">
          Not creating tests?{" "}
          <Link to={candidateTestsPath.pattern} className="underline">
            Skip
          </Link>
        </div>
      </div>
    </div>
  )
}

const ORGANIZATION_CREATE_MUTATION = gql(`
  mutation OrganizationCreate($input: OrganizationCreateInput!) {
    organizationCreate(input: $input) {
      organization {
        id
        name
      }
    }
  }
`)
