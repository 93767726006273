import { useEffect, useRef } from "react"
import { QuestionTypeEnum } from "~/__generated__/graphql"

export const useQuestionTypeChanged = (
  questionType: QuestionTypeEnum,
  onChange: (questionType: QuestionTypeEnum) => void
) => {
  const questionTypeWasRef = useRef<string | null>(null)

  useEffect(() => {
    if (questionTypeWasRef.current === null) {
      questionTypeWasRef.current = questionType
      return
    }

    if (questionType === questionTypeWasRef.current) {
      return
    }

    console.log("question type changed", questionType)
    onChange(questionType)
    questionTypeWasRef.current = questionType
  }, [questionType, onChange])
}
