export const TermsOfServiceScreen = () => {
  return <TermsOfServiceText fullPage />
}

export const TermsOfServiceText = ({
  fullPage = false,
}: {
  fullPage?: boolean
}) => {
  let headerClassName = "text-lg font-bold"
  let updatedAtClassName = "text-sm text-gray-999 pt-0.5 font-normal"
  let containerClassName = "flex flex-col gap-4"

  if (fullPage) {
    headerClassName = "text-2xl font-bold"
    updatedAtClassName = "text-base text-gray-999 pt-1 font-normal"
    containerClassName = "max-w-screen-md mx-auto py-16 flex flex-col gap-4"
  }

  return (
    <div className={containerClassName}>
      <h1 className={headerClassName}>
        Terms of Service
        <div className={updatedAtClassName}>
          Last Updated: May 22, 2024 - 8:50AM
        </div>
      </h1>
      <h2 className="font-bold">Introduction</h2>
      <p>
        Welcome to Standard Aptitude ("Company", "we", "our", "us"). These Terms
        of Service ("Terms") govern your use of our online testing services (the
        "Services"). By accessing or using the Services, you agree to be bound
        by these Terms. If you do not agree to these Terms, you must not use our
        Services.
      </p>
      <h2 className="font-bold">Eligibility</h2>
      <ol className="list-decimal list-outside ml-10">
        <li>
          <strong>Age Requirement</strong>: You must be at least 18 years old to
          use our Services.
        </li>
        <li>
          <strong>Geographical Restriction</strong>: Users located in the
          European Union (EU) are strictly prohibited from using our Services.
          By using the Services, you represent and warrant that you are not
          located in the EU.
        </li>
      </ol>
      <h2 className="font-bold">Use of Services</h2>
      <ol className="list-decimal list-outside ml-10">
        <li>
          <strong>Account Registration</strong>: You must register for an
          account to use the Services. You agree to provide accurate and
          complete information during the registration process and to update
          such information as necessary.
        </li>
        <li>
          <strong>Account Security</strong>: You are responsible for maintaining
          the confidentiality of your account credentials and for all activities
          that occur under your account. You agree to notify us immediately of
          any unauthorized use of your account.
        </li>
      </ol>
      <h2 className="font-bold">Recording of Video and Audio</h2>
      <p>
        By using our Services, you consent to the recording of your video and
        audio during the online testing process. These recordings are used for
        identity verification, monitoring, and other purposes as determined by
        the Company.
      </p>
      <h2 className="font-bold">Intellectual Property</h2>
      <p>
        All content, materials, and intellectual property associated with the
        Services are owned by the Company or its licensors. You are granted a
        limited, non-exclusive, non-transferable, and revocable license to
        access and use the Services for their intended purpose.
      </p>
      <h2 className="font-bold">General Prohibited Conduct</h2>
      <p>You agree not to:</p>
      <ol className="list-decimal list-outside ml-10">
        <li>Use the Services for any unlawful purpose</li>
        <li>Engage in any conduct that could harm the Company or its users.</li>
        <li>Attempt to interfere with the operation of the Services.</li>
      </ol>
      <h2 className="font-bold">Prohibited Testing &amp; Test Taking</h2>
      <p>
        AS A TEST CREATOR : If you create a test through Standard Aptitude, you
        agree not to use our service to administer any test which is illegal to
        administer for whatever reason, or in any discriminatory purposes
        whatsoever. &nbsp;
      </p>
      <p>
        Every jurisdiction and every company is unique. &nbsp;It is explicitly
        your responsibility to make sure that any question you ask on any test
        you create, and the manner in which you administer any test, through
        Standard Aptitude is legal for you to conduct. &nbsp;ALL TEST QUESTIONS
        AND TEST LEGALITY ARE YOUR RESPONSIBILITY.
      </p>
      <p>
        AS A TEST TAKER : You agree to hold Standard Aptitude harmless for the
        legality of any test you take via the platform. &nbsp;If you are invited
        by anyone to take a test which is illegal in our jurisdiction, do not
        take the test and consider taking up the issue with the test creator.
        &nbsp;
      </p>
      <h2 className="font-bold">Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to the Services
        at any time, without notice, for any reason, including but not limited
        to your violation of these Terms.
      </p>
      <h2 className="font-bold">Disclaimers and Limitation of Liability</h2>
      <ol className="list-decimal list-outside ml-10">
        <li>
          <strong>Disclaimers</strong>: The Services are provided "as is" and
          "as available" without warranties of any kind, either express or
          implied. The Company disclaims all warranties, including but not
          limited to the implied warranties of merchantability, fitness for a
          particular purpose, and non-infringement.
        </li>
        <li>
          <strong>Limitation of Liability</strong>: In no event shall the
          Company be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses, resulting from (a)
          your use of or inability to use the Services; (b) any unauthorized
          access to or use of our servers and/or any personal information stored
          therein; (c) any interruption or cessation of transmission to or from
          the Services; (d) any bugs, viruses, trojan horses, or the like that
          may be transmitted to or through the Services by any third party; (e)
          any errors or omissions in any content or for any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available through the Services; and/or
          (f) the defamatory, offensive, or illegal conduct of any third party.
        </li>
      </ol>
      <h2 className="font-bold">Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless the Company, its
        affiliates, and their respective officers, directors, employees, agents,
        licensors, and suppliers from and against all claims, losses, expenses,
        damages, and costs, including reasonable attorneys' fees, arising out of
        or relating to your use of the Services, your violation of these Terms,
        or your violation of any rights of another.
      </p>
      <h2 className="font-bold">Governing Law</h2>
      <p>
        These Terms and any disputes related thereto shall be governed by and
        construed in accordance with the laws of [Your Country/State], without
        regard to its conflict of laws principles.
      </p>
      <h2 className="font-bold">Changes to These Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. Any changes will
        be effective immediately upon posting on the Site. Your continued use of
        the Services after any such changes constitutes your acceptance of the
        new Terms.
      </p>
      <h2 className="font-bold">Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>
        Standard Aptitude
        <br />
        1006 Kearny Street San Francisco CA 94110
        <br />
        terms@lessintech.co
      </p>
    </div>
  )
}
