import { Button } from "~/shadcn/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "~/shadcn/ui/dialog"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form } from "~/shadcn/ui/form"
import { TextField } from "~/ui/forms/TextField"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import invariant from "tiny-invariant"
import { USER_UPDATE_MUTATION } from "~/screens/User/UserProfileScreen"
import { PhoneNumberField } from "~/ui/forms/PhoneNumberField"

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Required",
  }),
  phone: z.string().min(1, {
    message: "Required",
  }),
})

export const UserDetailsDialog = () => {
  const { currentUser } = useCurrentUserMaybe()

  const detailsRequired = currentUser
    ? !currentUser.phone || !currentUser?.name
    : false

  const [userUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      name: currentUser?.name || "",
      phone: currentUser?.phone || "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    invariant(currentUser)

    const { data, errors } = await userUpdate({
      variables: {
        input: {
          userId: currentUser.id,
          name: values.name,
          phone: values.phone,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.userUpdate?.user) {
      console.log(data?.userUpdate.user)
      toast.success("Profile Updated")
    } else {
      toast.error("Error updating profile.")
    }
  }

  return (
    <Dialog open={detailsRequired}>
      <DialogContent showClose={false}>
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-gray-333 leading-normal tracking-normal">
            Welcome to Standard Aptitude
          </DialogTitle>
          <DialogDescription className="text-md font-medium text-gray-999">
            Complete your profile to continue.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <TextField
              control={form.control}
              name="name"
              label="Name"
              required
            />
            <PhoneNumberField
              control={form.control}
              name="phone"
              label="Phone"
              required
            />
            <Button disabled={loading} type="submit">
              Save & Update
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
