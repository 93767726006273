import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { signInPath, signInTokenPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button, LinkButton } from "~/shadcn/ui/button"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form } from "~/shadcn/ui/form"
import { TextField } from "~/ui/forms/TextField"
import { PhoneNumberField } from "~/ui/forms/PhoneNumberField"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { useRedirectAfterLogin } from "~/common/useRedirectAfterLogin"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import { maybeGetMetaVar } from "~/common/getMetaVar"
import { useRef, useState } from "react"

const TURNSTILE_SITE_KEY = maybeGetMetaVar("turnstile-site-key")

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Required",
  }),
  phone: z.string().min(1, {
    message: "Required",
  }),
  email: z
    .string()
    .min(1, {
      message: "Required",
    })
    .regex(/^[^@\s]+@[^@\s]+$/, {
      message: "Enter a valid email",
    }),
})

export const CreateAccountScreen = () => {
  const [userCreate, { loading }] = useSafeMutation(USER_CREATE_MUTATION)
  const navigate = useNavigate()
  useRedirectAfterLogin()
  const captchaRef = useRef<TurnstileInstance | undefined>()
  const [captchaError, setCaptchaError] = useState<string | null>(null)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      phone: "",
      email: "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const captcha = captchaRef.current?.getResponse() || ""

    const { data, errors } = await userCreate({
      variables: {
        input: {
          ...values,
          captcha,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.userCreate.sessionId) {
      toast.success(
        "Account created.  Click the link in the email or enter the token here."
      )
      navigate(signInTokenPath({ sessionId: data.userCreate.sessionId }))
    } else {
      toast.error("Error creating account.")
    }
  }

  return (
    <div className="flex-1 flex flex-col justify-center p-6">
      <div className="w-full sm:w-[360px] mx-auto">
        <div className="text-2xl text-gray-333 font-semibold">
          Create an account.
        </div>
        <div className="text-2xl text-gray-999 font-semibold mb-8">
          Enter your details.
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <TextField
              control={form.control}
              name="name"
              label="Name"
              placeholder="John Smith"
            />
            <TextField
              control={form.control}
              name="email"
              label="Email"
              placeholder="john@test.com"
            />
            <PhoneNumberField
              control={form.control}
              name="phone"
              label="Phone"
            />

            {TURNSTILE_SITE_KEY && (
              <Turnstile
                className="hidden"
                siteKey={TURNSTILE_SITE_KEY}
                options={{
                  theme: "dark",
                  size: "normal",
                }}
                onError={() =>
                  setCaptchaError(
                    "Unable to process login at this time. Please reload and try again."
                  )
                }
                onExpire={() =>
                  setCaptchaError(
                    "Session expired, please reload the page and try again."
                  )
                }
                onSuccess={() => setCaptchaError(null)}
                ref={captchaRef}
              />
            )}

            {captchaError && (
              <div className="text-communist my-4">{captchaError}</div>
            )}

            <div className="h-[1px] my-4 bg-gray-E3E2E0" />

            <Button
              className="btn w-full"
              disabled={loading || !!captchaError}
              type="submit"
            >
              Create Account
            </Button>
            <LinkButton
              className="btn w-full mb-12"
              variant="secondary"
              type="button"
              to={signInPath.pattern}
            >
              Want to sign in instead?
            </LinkButton>
          </form>
        </Form>

        <div className="text-gray-999 text-center">
          By clicking "Create Account" you agree with our
          <br />
          <a href={signInPath.pattern} className="underline">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={signInPath.pattern} className="underline">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  )
}

const USER_CREATE_MUTATION = gql(`
  mutation UserCreate($input: UserCreateInput!) {
    userCreate(input: $input) {
      sessionId
    }
  }
`)
