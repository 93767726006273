import { Button } from "~/shadcn/ui/button"
import { Divider } from "~/ui/Divider"
import { ChevronLeft } from "lucide-react"
import { Link } from "react-router-dom"
import { candidateTestsPath } from "~/common/paths"
import { useRecording } from "~/av/RecordingProvider"
import Toggle from "react-toggle"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { useState } from "react"
import { VideoPreviewModule } from "~/av/VideoPreviewModule"
import { useTestFlow } from "./TestFlowProvider"

export const TestAudioVideoSetup = () => {
  const { avController, audioEnabled, videoEnabled } = useRecording()
  const [avEnableConfirmed, setAvEnableConfirmed] = useState(false)
  const { nextQuestion, candidateTest } = useTestFlow()

  const videoRequired = candidateTest?.test.requireVideo
  const audioRequired = candidateTest?.test.requireAudio

  return (
    <div className="container mx-auto pb-20">
      <div className="py-3 text-gray-999 flex justify-start">
        <Link to={candidateTestsPath.pattern} className="flex items-center">
          <ChevronLeft className="mr-2 mt-0.5" size={16} />
          Tests
        </Link>
      </div>
      <Divider className="mb-16" />

      <div className="mb-10">
        <div className="w-2/3">
          <div className="text-2xl text-gray-333 font-semibold">
            Audio & Video Setup
          </div>
          <div className="text-2xl text-gray-999 font-semibold mb-6">
            Allow audio and video settings below
          </div>
          <div>
            In order for your test to be validated, you need to have your camera
            and mic enabled and turned on, showing your face and with clear
            audio background.
          </div>
        </div>
      </div>

      <div className="flex items-start">
        <div className="w-2/3">
          <div className="border border-gray-E6E6E3 rounded mb-6">
            {videoRequired && (
              <div
                className="border-b border-gray-E6E6E3 p-6 flex items-center gap-4 cursor-pointer"
                onClick={
                  videoEnabled
                    ? () => {
                        avController.disableVideo()
                        setAvEnableConfirmed(false)
                      }
                    : avController.enableVideo
                }
              >
                ENABLE CAMERA
                <Toggle
                  onChange={(e) => {}}
                  checked={videoEnabled}
                  className="toggle-primary ml-auto pointer-events-none"
                />
              </div>
            )}
            {audioRequired && (
              <div
                className="border-b border-gray-E6E6E3 p-6 flex items-center gap-4 cursor-pointer"
                onClick={
                  audioEnabled
                    ? () => {
                        avController.disableAudio()
                        setAvEnableConfirmed(false)
                      }
                    : avController.enableAudio
                }
              >
                ENABLE MICROPHONE
                <Toggle
                  onChange={() => {}}
                  checked={audioEnabled}
                  className="toggle-primary ml-auto pointer-events-none"
                />
              </div>
            )}
            <div
              className="p-6 flex items-center gap-4 cursor-pointer"
              onClick={() => {
                if (
                  (audioRequired && !audioEnabled) ||
                  (videoRequired && !videoEnabled)
                )
                  return
                setAvEnableConfirmed((v) => !v)
              }}
            >
              <Checkbox checked={avEnableConfirmed} />
              {audioRequired && videoRequired
                ? "CONFIRM VIDEO & AUDIO ARE ENABLED"
                : audioRequired
                ? "CONFIRM AUDIO IS ENABLED"
                : "CONFIRM VIDEO IS ENABLED"}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Button
              className="w-1/2"
              disabled={!avEnableConfirmed}
              onClick={nextQuestion}
            >
              Start Test
            </Button>
            <Button variant="ghost">Close & Cancel</Button>
          </div>
        </div>

        <VideoPreviewModule setup />
      </div>
    </div>
  )
}
