import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { createAccountPath, signInPath, signInTokenPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button, LinkButton } from "~/shadcn/ui/button"
import LinkIcon from "~/images/icons/link.svg?react"
import { useRedirectAfterLogin } from "~/common/useRedirectAfterLogin"

const SEND_MAGIC_LINK_MUTATION = gql(`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input) {
      sessionId
    }
  }
`)

export const SignInScreen = () => {
  const [sendMagicLink, { loading }] = useSafeMutation(SEND_MAGIC_LINK_MUTATION)
  const [email, setEmail] = useState("")
  const navigate = useNavigate()
  useRedirectAfterLogin()

  const onSubmit = async () => {
    const { data } = await sendMagicLink({
      variables: {
        input: {
          email,
        },
      },
    })

    if (data?.sendMagicLink.sessionId) {
      toast.success(
        "Magic link sent.  Click the link in the email or enter the token here."
      )
      navigate(signInTokenPath({ sessionId: data.sendMagicLink.sessionId }))
    } else if (data?.sendMagicLink) {
      toast.error("Account not found")
    } else {
      toast.error("Error sending magic link")
    }
  }

  return (
    <div className="flex-1 flex flex-col justify-center p-6">
      <div className="w-full sm:w-[360px] mx-auto">
        <div className="text-2xl text-gray-333 font-semibold">
          Standard Aptitude Measurement
        </div>
        <div className="text-2xl text-gray-999 font-semibold mb-8">
          Log in to your account
        </div>
        <form>
          <label className="block">
            <div className="mb-2 text-gray-999">Email</div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border-1 border-gray-E3E2E0 rounded mb-2"
              placeholder="Enter your email"
            />
          </label>
          <div className="text-gray-999">
            Use your organization email to access your account
          </div>

          <div className="h-[1px] my-8 bg-gray-E3E2E0" />

          <Button
            className="btn w-full mb-2"
            onClick={onSubmit}
            disabled={email.length === 0 || loading}
            type="submit"
          >
            Email Magic Link
            <LinkIcon className="ml-2" />
          </Button>
          <LinkButton
            className="btn w-full mb-12"
            variant="secondary"
            type="button"
            to={createAccountPath.pattern}
          >
            Not a member? Create Account
          </LinkButton>
        </form>

        <div className="text-gray-999 text-center">
          By clicking "Email Magic Link" you agree with our
          <br />
          <a href={signInPath.pattern} className="underline">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={signInPath.pattern} className="underline">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  )
}
