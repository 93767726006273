import { useState, KeyboardEventHandler } from "react"
import CreatableSelect from "react-select/creatable"
import { Tag } from "~/__generated__/graphql"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"

interface TagOption {
  readonly label: string
  readonly value: string
}

const createTagOption = (label: string) => ({
  label,
  value: label,
})

export const createInitialTags = (tags: Tag[]) => {
  return (
    tags.map((tag) => {
      return createTagOption(tag.name)
    }) || []
  )
}

export const TagsSelect = ({
  selectedTags,
  onSelectedTagsChange,
  containerClassName,
}: {
  selectedTags: string[]
  onSelectedTagsChange: (t: string[]) => void
  containerClassName?: string
}) => {
  const organizationId = useOrganizationId()
  const currentTags = selectedTags?.map((tag) => createTagOption(tag))

  const tagsResult = useQuery(ORGANIZATION_TAGS_QUERY, {
    variables: { organizationId },
  })
  const existingTags = tagsResult.data?.tags.nodes || []

  const [tagsInputValue, setTagsInputValue] = useState("")

  const tagOptions = existingTags.map((tag) => createTagOption(tag.name))

  const handleOnChange = (newValue: readonly TagOption[]) => {
    onSelectedTagsChange(newValue.map((tag) => tag.label))
  }

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!tagsInputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        handleOnChange([...currentTags, createTagOption(tagsInputValue)])
        setTagsInputValue("")
        event.preventDefault()
    }
  }
  return (
    <div className={containerClassName}>
      <label className="space-y-2">
        <span className="peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-gray-999 font-normal text-base">
          Tags
        </span>
        <CreatableSelect
          inputValue={tagsInputValue}
          isClearable
          isMulti
          onChange={(newValue) => handleOnChange(newValue)}
          onInputChange={setTagsInputValue}
          onKeyDown={handleKeyDown}
          value={currentTags}
          options={tagOptions}
        />
      </label>
    </div>
  )
}

const ORGANIZATION_TAGS_QUERY = gql(`
  query Tags($organizationId: ID!) {
    tags(organizationId: $organizationId) {
      nodes {
        id
        name
      }
    }
  }
`)
