import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { UserWithImage } from "~/ui/UserWithImage"
import { CandidateTests } from "~/tests/CandidateTests"
import { adminCandidateTestPath } from "~/common/paths"

export const AdminCandidateScreen = () => {
  const { userId } = useParams()
  invariant(userId)

  const { loading, data, error } = useQuery(
    TEST_CANDIDATE_TESTS_QUERY_DOCUMENT,
    {
      variables: {
        userId,
      },
    }
  )

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading user." />

  return (
    <div>
      <div className="my-10">
        <UserWithImage
          name={data.user.name}
          email={data.user.email}
          phone={data.user.phone}
        />
      </div>

      <CandidateTests
        userId={userId}
        columns={[
          "testName",
          "startedAt",
          "completedAt",
          "timeTakenSeconds",
          "verified",
          "manualScore",
          "aiScore",
          "actions",
        ]}
        viewPath={(candidateTest) =>
          adminCandidateTestPath({ candidateTestId: candidateTest.id })
        }
        filter={{ archived: true }}
      />
    </div>
  )
}

const TEST_CANDIDATE_TESTS_QUERY_DOCUMENT = gql(`
  query AdminCandidate($userId: ID!) {
    user(userId: $userId) {
      id
      name
      email
      phone
    }
  }
`)
