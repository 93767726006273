import { format, parseISO } from "date-fns"

export const formatDateAndTime = (isoDate: string) => {
  return format(parseISO(isoDate), "MMM d, yyyy - h:mm aa")
}

export const formatTime = (isoDate: string) => {
  return format(parseISO(isoDate), "h:mm aa")
}

export const formatDate = (isoDate: string) => {
  return format(parseISO(isoDate), "MMM d, yyyy")
}

export const dateTimePlusSeconds = (isoDate: string, seconds: number) => {
  const start = parseISO(isoDate)
  const time = start.getTime() + seconds * 1000
  start.setTime(time)
  return start.toISOString()
}

export const formatTimeWithSeconds = (isoDate: string | null) => {
  if (isoDate === null) {
    return null
  }
  return format(parseISO(isoDate), "h:mm:ss aa")
}

export const humanizeSeconds = (value?: number | null) => {
  if (value === null || value === undefined) return null

  if (value <= 90) {
    return `${value} Seconds`
  }

  const minutes = Math.floor(value / 60)
  const seconds = Math.floor(value % 60)

  if (seconds === 0) {
    return `${minutes} Minutes`
  }
  return `${minutes.toString()}:${seconds.toString().padStart(2, "0")}`
}

export const humanizeSecondsToMinutes = (value?: number | null) => {
  if (value === null || value === undefined) return null

  if (value <= 90) {
    return `${value} Seconds`
  }

  const minutes = Math.round(value / 60)

  return `${minutes} Minutes`
}
