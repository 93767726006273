import { usePagination } from "~/common/usePagination"
import { usePromotionalTests } from "~/common/usePromotionalTests"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { PromotionalTestTable } from "~/components/PromotionalTestTable"
import { Pagination } from "~/ui/Pagination"
import { Footer } from "~/ui/Footer"
import { ScrollRestoration } from "react-router-dom"
import {
  Section,
  SectionHeaderText,
  QuarterWidth,
  AboutSection,
  BuildATestSection,
  KeyFeaturesSection,
} from "~/ui/Marketing"
import { HeaderNavigation } from "~/ui/HeaderNavigation"

const PER_PAGE = 10

export const PromotionalTestsScreen = () => {
  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { promotionalTests, loading, pageCount } = usePromotionalTests({
    first: PER_PAGE,
    after,
  })
  const { currentUser } = useCurrentUserMaybe()

  if (loading) return null

  return (
    <div id="home" className="flex-1 flex flex-col">
      <HeaderNavigation currentUser={currentUser} />
      <main>
        <Section id="promotional-tests">
          <QuarterWidth quarters={4}>
            <SectionHeaderText className="pb-8">
              Community Tests
            </SectionHeaderText>

            <PromotionalTestTable tests={promotionalTests} header={true} />
            {pageCount && pageCount > 1 ? (
              <div className="mt-4">
                <Pagination
                  page={page}
                  pageCount={pageCount}
                  paginate={paginate}
                />
              </div>
            ) : null}
          </QuarterWidth>
        </Section>

        <AboutSection />
        <BuildATestSection />
        <KeyFeaturesSection />
      </main>

      <div className="mx-auto max-w-[1024px] w-full">
        <Footer />
      </div>
      <ScrollRestoration />
    </div>
  )
}
