import { useEffect, useState } from "react"
import { Button } from "~/shadcn/ui/button"
import { Divider } from "~/ui/Divider"
import { useTestFlow } from "./TestFlowProvider"
import { ContinueOrSaveBar } from "./ContinueOrSaveBar"
import { useRecording } from "~/av/RecordingProvider"

export const VideoResponseForm = (props: { timeExpired: boolean }) => {
  const { answerQuestion } = useTestFlow()
  const { avController } = useRecording()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [startedTime] = useState(Date.now())

  const onClick = async () => {
    setLoading(true)
    if (Date.now() - startedTime < 1000 * 10) {
      setError(true)
    } else {
      setError(false)
      await answerQuestion()
    }
    setLoading(false)
  }

  useEffect(() => {
    avController.enableWaveform()
    return () => avController.disableWaveform()
  }, [avController])

  useEffect(() => {
    setTimeout(() => {
      setError(false)
    }, 1000 * 10)
  }, [])

  return (
    <div>
      <div className="mb-4">
        This is a video and audio response. Please speak your answer and then
        click continue.
      </div>

      <div id={avController.WAVEFORM_DISPLAY_ELEMENT_ID} className="mr-8" />
      <audio id={avController.WAVEFORM_AUDIO_ELEMENT_ID} />

      {error && (
        <div className="text-red-500">
          Minimum of 10 seconds to answer this question, keep speaking a bit
          more and then submit
        </div>
      )}

      <Divider className="my-10" />

      <ContinueOrSaveBar>
        <Button isLoading={loading} disabled={loading} onClick={onClick}>
          Continue
        </Button>
      </ContinueOrSaveBar>
    </div>
  )
}
