import humps from "humps"
import { DirectUpload } from "@rails/activestorage"
import { getMetaVar } from "./getMetaVar"

export const directImageUpload = async (
  image: FileList[number]
): Promise<any> => {
  const data = await uploadFile(image)
  return humps.camelizeKeys(data)
}

const directUploadsUrl = getMetaVar("direct-uploads-url")

const uploadFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, directUploadsUrl)

    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob)
      }
    })
  })
}

// const resizeImage = (file: Blob) => {
//   return new Promise((resolve, reject) => {
//     const responseUriFunc: (
//       value: string | File | Blob | ProgressEvent<FileReader>
//     ) => void = (response) => {
//       // @ts-ignore
//       if (response && response.stack && response.message) {
//         reject(response)
//       } else {
//         resolve(response)
//       }
//     }
//     Resizer.imageFileResizer(
//       file, //is the file of the new image that can now be uploaded...
//       2000, // is the maxWidth of the  new image
//       2000, // is the maxHeight of the  new image
//       "JPEG", // is the compressFormat of the  new image
//       80, // is the quality of the  new image
//       0, // is the rotation of the  new image
//       responseUriFunc, // is the callBack function of the new image URI
//       "blob" // is the output type of the new image
//     )
//   })
// }
