import { cn } from "~/common/shadcn-utils"

export const StandaloneRadioButton = ({
  value,
  onToggle,
  className,
}: {
  value: boolean
  onToggle?: (newValue: boolean) => void
  className?: string
}) => (
  <div
    className={cn(
      "bg-white border border-gray-E6E6E3 h-[24px] w-[24px] rounded-full flex items-center",
      "justify-center cursor-pointer",
      className
    )}
    onClick={onToggle ? () => onToggle(!value) : undefined}
  >
    {value && <div className="bg-primary w-[16px] h-[16px] rounded-full" />}
  </div>
)
