import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { InputHTMLAttributes } from "react"
import { cn } from "~/common/shadcn-utils"

export const PercentageField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  inputClassName,
  containerClassName,
  ...inputProps
}: {
  label: string | null
  labelClassName?: string
  inputClassName?: string
  containerClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={containerClassName}>
          {label && <FormLabel className={labelClassName}>{label}</FormLabel>}
          <FormControl>
            <div className="flex items-center h-[30px] items-stretch">
              <input
                className={cn(
                  "w-full border-1 border-gray-E3E2E0 rounded-l w-[50px] text-center",
                  { "border-red-500": !!fieldState.error },
                  inputClassName
                )}
                {...field}
                {...inputProps}
                onChange={(e) => {
                  if (e.target.value === "") field.onChange(0)

                  const parsed = parseInt(e.target.value)

                  if (!isNaN(parsed)) {
                    field.onChange(parsed)
                  }
                }}
                maxLength={3}
              />
              <div
                className={cn(
                  "px-2 border border-l-0 rounded-r border-gray-E3E2E0 flex items-center",
                  { "border-red-500": !!fieldState.error }
                )}
              >
                %
              </div>
            </div>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
