import { cn } from "~/common/shadcn-utils"

export const AccessPanel = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        "flex flex-col border border-gray-E6E6E3 rounded-lg w-full",
        className
      )}
    >
      {children}
    </div>
  )
}
