import { Button } from "~/shadcn/ui/button"
import { useFieldArray, useFormContext } from "react-hook-form"
import { z } from "zod"
import { TextField } from "~/ui/forms/TextField"
import { FormField } from "~/shadcn/ui/form"

import { QuestionTypeEnum } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { XCircle } from "lucide-react"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { StandaloneRadioButton } from "~/ui/StandaloneRadioButton"
import { questionGroupFormSchema } from "./QuestionGroupForm"

export const ChoicesSubsection = ({
  questionIndex,
  questionType,
}: {
  questionIndex: number
  questionType: QuestionTypeEnum
}) => {
  const { control, setValue } =
    useFormContext<z.infer<typeof questionGroupFormSchema>>()
  // const questionValues = getValues(`questions.${questionIndex}`)

  const { fields, append, remove } = useFieldArray({
    name: `questions.${questionIndex}.questionChoices` as "questions.0.questionChoices",
  })

  const resetAllValues = ({ except }: { except: number }) => {
    fields.forEach((_, index) => {
      if (except === index) return
      setValue(
        `questions.${questionIndex}.questionChoices.${index}.isCorrect`,
        false
      )
    })
  }

  return (
    <div className="flex flex-col gap-6">
      {fields.map((field, index) => (
        <div key={field.id} className="flex items-start">
          <div className="mr-5 flex flex-col items-center">
            <div className={cn("text-gray-999 mb-2", { invisible: index > 0 })}>
              Answer
            </div>
            <FormField
              control={control}
              name={`questions.${questionIndex}.questionChoices.${index}.isCorrect`}
              render={({ field }) =>
                questionType === QuestionTypeEnum.MultipleChoice ? (
                  <Checkbox
                    className="bg-white mt-[7px]"
                    checked={field.value}
                    onCheckedChange={(value) => {
                      field.onChange(value)
                    }}
                  />
                ) : (
                  <StandaloneRadioButton
                    className="mt-[7px]"
                    value={field.value}
                    onToggle={(value) => {
                      resetAllValues({ except: index })
                      field.onChange(value)
                    }}
                  />
                )
              }
            />
          </div>
          <TextField
            control={control}
            name={`questions.${questionIndex}.questionChoices.${index}.choiceCopy`}
            label={`Choice ${index + 1}`}
            placeholder="Enter an answer choice"
            containerClassName="flex-1"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault()
                if (index === fields.length - 1) {
                  append({ choiceCopy: "", isCorrect: false })
                }
              }
            }}
          />
          <div className="ml-4 mt-[37px]">
            <XCircle
              size={18}
              className={cn("text-gray-999 cursor-pointer", {
                invisible: fields.length === 1,
              })}
              onClick={() => remove(index)}
            />
          </div>
        </div>
      ))}
      <div>
        <Button
          type="button"
          onClick={() => {
            append({ choiceCopy: "", isCorrect: false })
          }}
          size="sm"
        >
          Add Another Possible Response
        </Button>
      </div>
    </div>
  )
}
